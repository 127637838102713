
export default function Content() {
    return (
        <div className="formarea mx-auto max-w-7xl">
            <div className="formcontainer">
                All employees who are absent from work for any reason must complete the
                on-line leave request prior to the date of their anticipated
                absence.&nbsp; In the event of an emergency or illness, the leave request
                should be submitted within the week the absence occurred or no later than
                the first day back to work.
                <br />
                <br />
                <div align="center"><h1>EMPLOYEE BENEFITS</h1>
                </div>
                <br />
                <div >
                    <h4>
                        All
                        employees who are absent from work for any reason must complete the
                        on-line leave request prior to the date of their anticipated
                        absence.&nbsp; In the event of an emergency or illness, the leave request
                        should be submitted within the week the absence occurred or no later than
                        the first day back to work</h4>
                    <br />
                    <h2>Sick Leave</h2>
                    <p>One day of sick leave per month of contracted employment will be granted to each
                        teacher and regular full-time employee each school year.&nbsp; Sick leave may
                        accumulate from year to year, if unused.&nbsp; A maximum of 60 days of
                        accumulated sick leave may be used in any one contract year, except that for
                        the contract year immediately preceding retirement (with age plus years of
                        creditable service equaling a minimum of 80) all accumulated sick leave may be
                        used.&nbsp; The Board of Education may require certification of illness by a
                        medical doctor before honoring sick leave.</p>
                    <br />
                    <p>Sick leave will be vested on the first day of employment of all full time
                        employees.</p>
                    <br />
                    <p>Sick leave can be used for accidental injury, illness, or death in the
                        employee's immediate family.&nbsp; Immediate family is defined as spouse,
                        child, parent, sibling, grandparent, grandchild and corresponding relationship
                        by marriage.&nbsp;</p>
                    <p align="right"><a title="To top of page" href="#">Back to Top</a></p>
                    <h2><a name="bereavement"></a>Leave for Death in Immediate Family (Bereavement):</h2>
                    <p>Each employee of the school district is granted a leave for death in the
                        immediate family. This leave will be applied as follows:</p>

                    <ol className='ml-12 mt-2 mb-2'>
                        <li type="a">
                            Within the county&nbsp;&nbsp;&nbsp;3 days</li>
                        <li type="a">
                            Within the state&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4 days</li>
                        <li type="a">
                            Outside the state&nbsp;&nbsp;&nbsp;5 days</li>
                    </ol>
                    <p>Immediate family is defined as father, mother, brother, sister, husband, wife,
                        child, grandparents, grandchild, mother-in-law, father-in-law, aunts, uncles,
                        nieces, nephews, or the husbands or wives of members of the immediate family.
                        Annual Leave or Personal Leave must be taken for all other funerals.</p>
                    <p align="right"><a title="To top of page" href="#">Back to Top</a></p>
                    <h2><a name="emergency"></a>Emergency:</h2>
                    <p>Full-time personnel will be allowed two (2) days emergency leave per school
                        year. Emergency leave is interpreted as a sudden, generally unexpected occurrence
                        or unavoidable set of circumstances demanding immediate action which takes the
                        employee from his/her duties. Emergency leave is non-cumulative.</p>
                    <p align="right"><a title="To top of page" href="#">Back to Top</a></p>
                    <h2><a name="vacation"></a>Vacation:</h2>
                    <p>

                        <p>Employees eligible for vacation leave shall take their vacation at a time approved by their direct supervisor. All full-time, twelve-month employees will be entitled to fifteen (15) working days (120 hours) of paid vacation leave per fiscal year (July 1 - June 30). An eligible, full-time, twelve-month employee contracted for half-time or more shall be entitled to paid vacation leave on a prorated basis according to the date of employment. Vacation leave is accrued at a rate of 1.25 days (10 hours) per month.&nbsp;&nbsp;&nbsp; </p>

                        <br />
                        <p>An eligible employee, upon separation of service with KTC, shall be paid at their current rate of pay for unused vacation leave accrued during the current fiscal year up to a maximum of fifteen (15) days (120 hours). If an employee has used more vacation eave than the number of hours/days available/accrued, the employee will be responsible for reimbursing KTC for this amount.&nbsp;&nbsp;&nbsp; </p>

                        <br />

                        <p>A total of twenty-two and one-half (22.5) days (180 hours) of paid vacation leave may be carried over from the current fiscal year to the subsequent fiscal year.&nbsp; </p>

                    </p>
                    <p align="right"><a title="To top of page" href="#">Back to Top</a></p>
                    <h2><a name="personal"></a>Personal Leave:</h2>
                    <p>Personal business leave is hereby provided upon request by the teacher and
                        approval of the superintendent for all 10 month teachers up to a maximum of 3
                        days per year non-cumulative.&nbsp; Salary deductions for such leave shall be
                        the salary level for substitute teachers.&nbsp; Three (3) days of personal
                        business leave shall be available to Kiamichi Technology Center&nbsp; support
                        employees on the conditions that salary deductions will be made for such leave
                        not to exceed the employee's salary.&nbsp;
                    </p>
                    <p align="right"><a title="To top of page" href="#">Back to Top</a></p>
                </div>
            </div>
        </div>
    )
}

