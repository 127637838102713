import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'
import Breadcrumbs from '../components/breadcrumbs';

export default function ContractTypes() {

    const { response, loading, error } = useAxios({
        method: 'get',
        url: '/api/leave/contract-types',
        headers: JSON.stringify({ accept: 'application/json' }),
    });

    const [data, setData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (response !== null) {
            setData(response);
        }
    }, [response]);

    const viewRequest = (id) => {
        navigate(`/config/contract-type/${id}/edit`)
    }

    const accrualMethod = (params) => {
        var p = params.filter((pa) => {
            return pa.param_name === "ACCRUAL_METHOD"
        });
        return p[0].param_value;
    }

    return (
        <div className="min-h-full">

           
        <main>
            <div className="mx-auto pt-4 max-w-7xl px-4 sm:px-6 lg:px-8">
            {/* <Breadcrumbs pages={[
                    { name: 'Setup', href: '#', current: false },
                    { name: 'Contract Types', href: '#', current: true }
                    ]} /> */}

                <div className='flex border-b border-gray-200'>
                        <div className="flex-grow">
                        <Tabs tabs={[
                                { name: 'Leave Types', href: '/config/leave-types', current: false },
                                { name: 'Contract Types', href: '/config/contract-types', current: true },
                            ]} />
                        </div>
                        <div className='m-2'>
                        <button onClick={() => navigate("/config/contract-type/create")}
                                type="button"
                                className="rounded bg-blue-400 px-6 py-1 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Add Contract Type
                            </button>
                        </div>
                    </div>
                 

                <div className="-mx-4 mt-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg bg-white">


                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th className="pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6">Contract Name</th>
                            <th className="hidden px-3  text-left text-sm font-semibold text-gray-100 lg:table-cell">Length Months</th>
                            <th className="hidden px-3  text-left text-sm font-semibold text-gray-100 lg:table-cell">Required Days</th>
                            <th className="hidden px-3  text-left text-sm font-semibold text-gray-100 lg:table-cell">Time Capture</th>
                            <th className="hidden px-3  text-left text-sm font-semibold text-gray-100 lg:table-cell">Time Input</th>
                            <th className="hidden px-3  text-left text-sm font-semibold text-gray-100 lg:table-cell"></th>
                            
                            {/* <th className="relative  pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((lt) => (
                            <tr className='hover:bg-sky-100' onClick={() => viewRequest(lt.id)}>
                                <td className="relative py-2 pl-4 pr-3 text-sm sm:pl-6">
                                    <Link to="#">{lt.contract_name}</Link>
                                </td>
                                <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{lt.contract_length_months}</td>
                                <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{lt.required_days}</td>
                                <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{(lt.uses_timecapture ? "Yes" : "No")}</td>
                                <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{(lt.uses_timeinput ? "Yes" : "No")}</td>
                                {/* <td className="relative py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <button type="button" className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white">Select<span className="sr-only">, Hobby</span></button>
                            </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </main>
        </div>
    );
}