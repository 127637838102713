
const useHelpers = () => {

    const formatStatusEmployee = (status) => {
        
        switch(status) {
            case 1 :
                return "Active";
                
            case 2 :
                return "Inactive";

                // sql = "SELECT admin_fname + ' ' + admin_lname FROM acct_administrator WHERE id_admin = " + forwarded_to_acctid;
                // forwarded_to = conn.Execute(sql);
                // if(!rs.EOF && !rs.BOF) {
                //     forwarded_to = forwarded_to.Fields(0).Value;
                //     forwarded_to = '<span class="notes">This Leave Request has been forwarded to ' + forwarded_to + ' by ' + processed_by + ' for processing.</span>'
                // } else {
                //     forwarded_to = "";
                // }
        
                break;
            case 3 :
                return "Non-Employee";
        }
            
    
    }
   
    const formatStatus = (processed_code) => {

        
        switch(processed_code) {
            case "1" : //pending
                return "Pending";
                
            case "2" : // forwarded
                return "Forwarded";

                // sql = "SELECT admin_fname + ' ' + admin_lname FROM acct_administrator WHERE id_admin = " + forwarded_to_acctid;
                // forwarded_to = conn.Execute(sql);
                // if(!rs.EOF && !rs.BOF) {
                //     forwarded_to = forwarded_to.Fields(0).Value;
                //     forwarded_to = '<span class="notes">This Leave Request has been forwarded to ' + forwarded_to + ' by ' + processed_by + ' for processing.</span>'
                // } else {
                //     forwarded_to = "";
                // }
        
                break;
            case "3" :
                return "Approved";
            case "0" :
                return "Denied";
        }
            
    }

    const formatStatusShort = (processed_code) => {

        
        switch(processed_code) {
            case 1 : //pending
                return "[p]";
                
            case 2 : // forwarded ??
                return "[a]";

                // sql = "SELECT admin_fname + ' ' + admin_lname FROM acct_administrator WHERE id_admin = " + forwarded_to_acctid;
                // forwarded_to = conn.Execute(sql);
                // if(!rs.EOF && !rs.BOF) {
                //     forwarded_to = forwarded_to.Fields(0).Value;
                //     forwarded_to = '<span class="notes">This Leave Request has been forwarded to ' + forwarded_to + ' by ' + processed_by + ' for processing.</span>'
                // } else {
                //     forwarded_to = "";
                // }
        
                break;
            case 3 :
                return "[m]";
            case 0 :
                return "[d]";
        }
            
    }

    const formatPriority = (p) => {
        if(p == 0) {
            return "Normal"
        }
        if(p == 1) {
            return "Medium"
        }
        if(p == 2) {
            return "High"
        }

        return "Normal"
    }

    function convertMinutesToTime(minutes) {
		minutes = (minutes === undefined || isNaN(minutes) ? 0 : minutes-0);
		var neg = (minutes < 0 ? true : false);
		minutes = Math.floor(Math.abs(minutes));
		var hours = new String(Math.floor(minutes / 60));
		//hours = (hours.length == 1 ? "0" + hours : hours);
		minutes = new String(Math.abs(minutes % 60));
		minutes = (minutes.length == 1 ? "0" + minutes : minutes);
		return (neg ? "-" : "") + hours + ":" + minutes;
	};

    function convertMinutesToWorkday(minutes, workdayMinutes) {
        var isneg = minutes >= 0 ? false : true;
        minutes = Math.abs(minutes);
        var workdays = Math.floor(minutes / workdayMinutes);
        minutes = minutes % workdayMinutes;
        return (!isneg ? '' : '-') + workdays + ':' + convertMinutesToTime(minutes);
    };
    // ------------------------------------------------------------------------------
    function convertDaysToTime(days) {
        var d = new String(days);
        if (!days) {
            return false;
        } else {
            var v = d.split(".");
            var d = (!(v[0]) ? "0" : v[0]);
            var h = (!(v[1]) ? "00.0" : ("." + v[1]) * 24) + "";
    
            var m = h.split(".");
                m = (!(m[1]) ? "00" : ("." + m[1]) * 60) + "";
            h = Math.floor(h) +"";
            m = Math.floor(m) +"";
            h = "0".repeat(2 - h.length) + h;
            m = "0".repeat(2 - m.length) + m;
            return d + ":" + h + ":" + m;
            
    
        }
    };


    function formatTimeFromMilitary(value) {
        var arTime = value.split(":");

            var hours = parseInt(arTime[0]);
            var minutes = parseInt(arTime[1]);

            //arTime[1] = ((value.minutes()+"").length < 2 ? "0" : "") + value.minutes();

        var ampm = "";
        //if(!isMilitary) {
            
        ampm = (hours < 12 ? " AM" : " PM");
        
        if(hours > 12) {
                hours -= 12;
            } else if(arTime[0] == 0) {
                hours = 12;
            }

            
        //}
        return hours + ":" + addZero(minutes) + ampm; // arTime.join(":") + ampm;
    };

    function addZero(i) {
        if (i < 10) {i = "0" + i}
        return i;
      }
    // function getLeaveTotalUsed(rows) {

    //     // assume date start and en are known via the incoming rows

	// 	minutes = (minutes === undefined || isNaN(minutes) ? 0 : minutes-0);
	// 	var neg = (minutes < 0 ? true : false);
	// 	minutes = Math.floor(Math.abs(minutes));
	// 	var hours = new String(Math.floor(minutes / 60));
	// 	//hours = (hours.length == 1 ? "0" + hours : hours);
	// 	minutes = new String(Math.abs(minutes % 60));
	// 	minutes = (minutes.length == 1 ? "0" + minutes : minutes);
	// 	return (neg ? "-" : "") + hours + ":" + minutes;
	// };

    // function getLeaveTotalAccrued(rows) {

    //     // assume date start and en are known via the incoming rows
		
    //     minutes = (minutes === undefined || isNaN(minutes) ? 0 : minutes-0);
	// 	var neg = (minutes < 0 ? true : false);
	// 	minutes = Math.floor(Math.abs(minutes));
	// 	var hours = new String(Math.floor(minutes / 60));
	// 	//hours = (hours.length == 1 ? "0" + hours : hours);
	// 	minutes = new String(Math.abs(minutes % 60));
	// 	minutes = (minutes.length == 1 ? "0" + minutes : minutes);
	// 	return (neg ? "-" : "") + hours + ":" + minutes;
	// };

    // function calculateCustomBenefitNumber(acctid, dateTo) {
    //     dateTo = new Date(dateTo);
    //     sql = "SELECT emp_dob, emp_dateHired, emp_years_service FROM acct_employee WHERE id_employee = '" + acctid + "'";
    //     var theDates = conn.Execute(sql);
    //     var dob = dhired = false;
    //     var years_service = 0;
    //     if(!theDates.EOF && !theDates.BOF) {
    //         dob = ( theDates(0).Value == null || theDates(0).Value == "" ? false : new Date(theDates(0).Value) );
    //         dhired = ( theDates(1).Value == null || theDates(1).Value == "" ? false : new Date(theDates(1).Value) );
    //         years_service = theDates(2).Value;
    //     }
    //     delete theDates;
    //     if((!dob || !dhired) && years_service == 0) {
    //         return "NA";
    //     } else if(!dob || !dhired) {
    //         return years_service;
    //     } else {
    //         dob = dateTo.getFullYear() - dob.getFullYear(); //how old in years at calculation date
    //         var daysSinceHire = dateDiff( dhired, dateTo, "d", true );
    //         var daysInFirstYear = daysSinceHire % 365; //120 or more days counted as a year
    //         return Math.floor(daysSinceHire / 365) + (daysInFirstYear >= 120 ? 1 : 0) + years_service + dob;
    //     }
    // };

    
    return { convertMinutesToTime, formatPriority, formatStatus, formatStatusShort, formatStatusEmployee, convertMinutesToWorkday, formatTimeFromMilitary }
}

export default useHelpers;
