import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button,Select, TextField } from '@mui/material' 
import Logo from '../images/header-art.png'
import { SlimLayout } from '../layouts/silm'

export const metadata = {
  title: 'Sign Up',
}

export default function Register() {
  return (
    <SlimLayout>
      
      <h2 className="mt-6 text-lg font-semibold text-gray-900">
        Get started for free
      </h2>
    
      <form
        action="#"
        className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2"
      >
        <TextField
          label="First name"
          name="first_name"
          type="text"
          autoComplete="given-name"
          required
          size="small"
        />
        <TextField
          label="Last name"
          name="last_name"
          type="text"
          autoComplete="family-name"
          required
          size="small"
        />
        <TextField
          className="col-span-full"
          label="Email address"
          name="email"
          type="email"
          autoComplete="email"
          required
          size="small"
        />
        <TextField
          className="col-span-full"
          label="Password"
          name="password"
          type="password"
          autoComplete="new-password"
          required
          size="small"
        />
        {/* <Select
          className="col-span-full"
          label="How did you hear about us?"
          name="referral_source"
        >
          <option>AltaVista search</option>
          <option>Super Bowl commercial</option>
          <option>Our route 34 city bus ad</option>
          <option>The “Never Use This” podcast</option>
        </Select> */}
        <div className="col-span-full">
          <Button type="submit" variant="contained"  className="w-full">
            <span>
              Sign up <span aria-hidden="true">&rarr;</span>
            </span>
          </Button>
        <br />  <p className="mt-2 text-sm text-gray-700">
        <br /> 
        Already registered?{' '}
        <Link
          to="/"
          className="font-medium text-blue-600 hover:underline"
        >
          Sign in
        </Link>{' '}
        to your account.
      </p>
        </div>
      </form>
    </SlimLayout>
  )
}
