
import Nav from '../components/nav'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import LeaveTypes from '../app_config/leave-types';
import LeaveTypeEdit from '../app_config/leave-type-edit';
import CompanyInfo from '../app_config/company-info';
import ContractTypes from '../app_config/contract-types';
import ContractTypesEdit from '../app_config/contract-type-edit';

export default function Accounts() {

    return (

        <div className="mx-auto max-w-7xl bg-white">    
                     <Nav />
            {/* <header className="shadow-sm">
                <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg font-semibold leading-6 text-gray-900">Setup</h1>
                </div>
            </header> */}
            {/* <Tabs tabs={[{ name: 'List Employee', href: '#', current: true }]} /> */}
            <Routes>
                {/* <Route path="calendar" element={<Calendar />} /> */}
                <Route path="leave-types" element={<LeaveTypes />} />
                <Route path="leave-type/:id/edit" element={<LeaveTypeEdit />} />
                <Route path="contract-types" element={<ContractTypes />}></Route>
                <Route path="contract-type/:id/edit" element={<ContractTypesEdit />}></Route>
                <Route path="contract-type/create" element={<ContractTypesEdit />}></Route>
                <Route path="company-info" element={<CompanyInfo />}></Route>
            </Routes>
            {/* <Route exact path="/equipment/:id/view" component={View} />
            <Route exact path="/equipment/:id/readonly" component={ReadOnly} />
            <Route exact path="/equipment/:id/images" component={Images} />
            <Route exact path="/equipment/qrscan" component={QrScan} />
            <Route exact path="/equipment/:id/qrcode" component={QrCode} /> */}
        </div>
    )
}