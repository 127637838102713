import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'
import historyIcon from '../images/icons/icon_history.gif'
import { get } from '../services/base-http';
import Moment from 'react-moment';
import useHelpers from '../util'
import useRoles from '../hooks/useRoles'


export default function Requests({data, summaryData, allowClick }) {
    const [pageParam, setPageParam] = useState(1);
    const [sortParam, setSortParam] = useState('lastname');
    const [nameParam, setNameParam] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    //const [data, setData] = useState([]);
    const [dataFiltered, setDataFiltered] = useState([]);
    const [pageNumber, setPageNumber] = useState(pageParam);

    const pageSize = 20;
    const [totalPages, setTotalPages] = useState(1);
    const [leaveTypeFilter, setLeaveTypeFilter] = useState(0);

    const [leaveTypes, setLeaveTypes] = useState([])
    const { convertMinutesToTime, formatStatus } = useHelpers();


    const { isAdmin } = useRoles();

    const { id } = useParams();

    useEffect(() => {

        get("/api/leave/types", {}).then((data) => {
            setLeaveTypes(data);
        })

        setDataFiltered(data)
     
        var ia = isAdmin().then((result) => {

            //setIsAdmin(result)

            if (result) {
                if(id){
                    
                    get(`/api/employee/${id}/leave/history`, null).then((response) => {
                        if (response !== null) {

                            data = response;

                            //setData(response);
                            setDataFiltered(response);
                            loadData(pageNumber);
                        }
                    });
                }
            }
        });

        // get(`/api/employee/${id}/leave/balances/all`, null).then((response) => {
        //     if (response !== null) {
        //         setSummaryData(response)
        //     }
        // });

    }, []);

    useEffect(() => {
        loadData(pageNumber)
    }, [pageNumber])

    useEffect(() => {

        if(summaryData) {
            get("/api/leave/types", {}).then((data) => {
                
                data.map((t) => {
                    var sd = summaryData.filter((s) => {
                        return s.leaveAbbr == t.leave_name_abbr
                    });

                    if(sd.length > 0){
                        t.summary = sd[0];
                    }
                })
                
                setLeaveTypes(data);



            })
        }

    }, [summaryData]);

    const handleLeaveTypeChange = (e) => {
        setLeaveTypeFilter(e.target.value)

        if (e.target.value == 0) {
            setDataFiltered(data);
        }
        else {
            var r = data.filter((r) => {
                return r.leaveTypeId == e.target.value;
            });

            setDataFiltered(r)
        }

    }

    const getAccrualType =(l)=> {

        var lt = l.leaveTypeParams.filter((ltp) => {
            return ltp.param_name == "ACCRUAL_METHOD"
        });

        if(lt.length > 0){
            return lt[0].param_value;
        }
        else{
            return "";
        }
    }

    const paging = (e) => {
        setPageNumber(e.target.value)

        loadData(e.target.value);
    }

    const loadData = (pageNumber) => {
alert('ads')
        var tp = Math.round((data.length / pageSize));

        var tpv = (tp > 0 ? tp : 1);

        console.log(tpv);

        setTotalPages(tpv);

        var df = data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

        setDataFiltered(Object.assign([], df));
    }

    const returnPages = () => {
        var dayray = [];

        for(let i = 1; i <= totalPages; i++){
            dayray.push(i)
        }
        return dayray;
    }


    return (
        <div className="min-h-full">
            <main>
            <div className="mx-auto mt-2 max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-end">
                            <div className="align-right">
                            <select onChange={handleLeaveTypeChange} className="inline rounded-md border-0 ml-1 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-xs sm:leading-6">
                                <option value={0}>All Leave Types</option>
                                {leaveTypes.map((e) => (
                                    <option value={e.id}>{e.leave_name}
                                        {/* 8:00 am */}
                                    </option>)
                                )}
                            </select>
                            </div>
                    </div>
                    <div className="-mx-4 mt-2 ring-1 ring-gray-300 sm:mx-0 rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300 striped">
                            <thead>
                                <tr>
                                    <th scope="col" className="rounded-tl pl-4 pr-3 text-left text-xs font-semibold sm:pl-6">Transaction Date</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold lg:table-cell">Date Added</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold lg:table-cell">Leave Type</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold lg:table-cell">Input</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold ">Notes</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold rounded-tr">Hours</th>
                                    {/* <th scope="col" className="relative py-1.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {dataFiltered && dataFiltered.map((l) => (
                                    <tr className='hover:bg-sky-100' >
                                        <td className="relative py-2 pl-4 pr-3 text-xs sm:pl-6">
                                            {allowClick ? (
                                                <Link to="#"><Moment format="MM/DD/YYYY HH:mm a" >{l.transactionDate}</Moment></Link>
                                            ) :
                                            (
                                                <Moment format="MM/DD/YYYY" >{l.transactionDate}</Moment>
                                            )}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell">{l.dateAdded}</td>
                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell">{l.leaveType}</td>

                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell">{l.input}</td>
                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell">{l.notes}</td>
                                        {/* <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell">{l.hours}</td> */}
                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell">{convertMinutesToTime(-1 * l.totalMinutes)}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <br />
                        <p className='text-xs text-gray-600 p-3'>No of records: {data.length}  |  Page  
                             <select name="status" value={pageNumber} 
                             onChange={paging}
                             className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                {returnPages().map((d) => {
                                    return (<option selected={pageNumber==d} value={d}>{d}</option>)
                                })}
                            </select>{pageNumber} of {totalPages}</p>
                    </div>
                    <br />


                    <div className="mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg grid inline-grid w-2/3 ">
                        <table className="min-w-full divide-y divide-gray-300 striped">
                            <thead><tr>
                                <th className='rounded-tl  text-left pl-3' >Legend</th>
                                <th className='text-left pl-3' >Accrual Type</th>
                                <th className='text-left pl-3' >Current Balances</th>
                                <th className=' rounded-tr  text-left pl-3' >Total Balances</th>
                            </tr>
                            </thead>
                            <tbody>
                                {leaveTypes && leaveTypes.map((l) => {
                                    return (
                                    <tr>
                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell"><div ><b>{l.leave_name_abbr} </b> = {l.leave_name}</div></td>
                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell"><div >{getAccrualType(l)}</div></td>
                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell"><div >{convertMinutesToTime((l.summary ? l.summary.currentBalance : 0))}</div></td>
                                        <td className="px-3 py-2 text-xs text-gray-900 lg:table-cell"><div >{convertMinutesToTime((l.summary ? l.summary.totalBalance : 0))}</div></td>
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </div>
    );
}