import { Link, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'
import NavVertical from '../components/nav-vertical'
import ReportSummary from '../app_leave/report_summary'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form"
import { HookSelect, useHookForm } from 'mui-react-hook-form-plus';
import { get, post } from '../services/base-http';

import { Button,Select, TextField, MenuItem, Checkbox, ListItemText } from '@mui/material' 

export default function Reports() {


    const [data, setData] = useState([]);
    
    const [campuses, setCampuses] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeesFiltered, setEmployeesFiltered] = useState([]);
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [showReport, setShowReport] = useState(false);

    const navigate = useNavigate();

    
    const {
        leave_type,
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm()

    
    
    useEffect(() => {


        get("/api/admin/campuses", {}).then((data) => {
            setCampuses(data);
        })

        get("/api/employee/list", {}).then((data) => {
            setEmployees(data);

            // var eeF = data.map((e) => {
            //     return {
            //         label: e.employeeName,
            //         value: e.employeeId, 
            //         isSelected: false
            //     }
            // }); 
            
            
            setEmployeesFiltered(data)
        })

        get("/api/leave/types", {}).then((data) => {
            setLeaveTypes(data);
        })

    }, []);



    
    const runReport = (data) => {

        console.log(data)

        var eeIds = employeesFiltered.map((e) => {
            if(e.isSelected) {
                return e.employeeId + ""
            }
        });

        data.employee_list = eeIds;

        // data.dates = dates;
        // data.totalMinutes = totalRequestedTime;

        setData(data);

        setShowReport(true);
    }

    const viewRequest = (id) => {
        navigate(`/leave/${id}/view`)
    }

    const returnDays = () => {
        var dayray = [];

        for(let i = 1; i <= 31; i++){
            dayray.push(i)
        }
        return dayray;
    }

    
    const selectAllCategories = () => {
        leaveTypes.map((e) => { e.isSelected = true});

        setLeaveTypes(Object.assign([], leaveTypes));
    }
    const selectAllEmployees = () => {
        employeesFiltered.map((e) => { e.isSelected = true});
        //employees.map((e) => { e.isSelected = true});
        
        //setEmployees(Object.assign([], employees));
        setEmployeesFiltered(Object.assign([], employeesFiltered));
        //setEmployeesFiltered(employeesFiltered);

    }

    const filterEmployees = (e) => {
        console.log(e.target.value)

        var sl
        //     var optlist = f.options_list;
        //     var emplist = f.employee_list;
        
        // emplist.options.length = 0;
        
        // var n = employee_json.length;
        var n = employees.length;
        // var json;
        // var selectedOption = (optlist.selectedIndex == -1 ? "" : optlist[optlist.selectedIndex].value);
        var selectedOption = e.target.value;

        switch(selectedOption) {
            case "all" :
                
                setEmployeesFiltered(Object.assign([], employees));

                break;

            case "AD" : case "EH" : case "IL" : 
            case "MP" : case "QT" : case "UZ" :

                // var letters = optlist[optlist.selectedIndex].value;
                // console.log(letters)

                var letters = selectedOption.substr(0,1) + "-" + selectedOption.substr(1,1);


                console.log(letters)

                var regexp = new RegExp("^[" + letters + "]", "i");
                    
                for(var i = 0; i < n; i++) {


                    var eesFiltered = employees.filter((ee) => {
                        if(ee.emp_lname.search(regexp) > -1 && ee.emp_status  == 1) {

                            return ee;
                            //emplist.options[emplist.options.length] = new Option(json.emp_lname + ', ' + json.emp_fname, json.id_employee);
                        }

                    })

                    setEmployeesFiltered(Object.assign([], eesFiltered))
                }
                
                break;

            case "inactive" :

                var eesFiltered = employees.filter((ee) => {
            		return ee.emp_status  == 2;
            	});

                setEmployeesFiltered(Object.assign([], eesFiltered))

                break;

            case "terminated" :
            
                
                var eesFiltered = employees.filter((ee) => {
                    return ee.emp_status == 3;
                });

                setEmployeesFiltered(Object.assign([], eesFiltered))

            	break;

            default :

                break;
            }
                
                // if(emplist.options.length == 0) {
                //     emplist.options[0] = new Option('No employees selected...', 0);
                //     emplist.options[0].style.color = "#999";
                //     emplist.disabled = true;
                // } else {
                //     emplist.disabled = false;
                // }
            


    }

    const filterEmployeesByCampus = (e) => {

        if(e.target.value == "all") {
            setEmployeesFiltered(employees)
        }
        else{
            //console.log(e.target.value)
            var ees = employees.filter((ee => {
                return ee.companyInfoId == e.target.value
            }))

            setEmployeesFiltered(Object.assign([], ees));
        }
    }

    return (
        <div className="min-h-full">
            {showReport && (
                <ReportSummary options={data} />
            )}
            {!showReport && (
                <main>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="-mx-4 mt-10 sm:mx-0 sm:rounded-lg">
                            <div className="flex flex-column">

                                <div className='flex-grow-1'>
                                    <h1 className='text-4xl'>Select Report</h1>
                                    <br />
                                    <NavVertical navigation={[
                                        { name: 'Leave Report - Summary', href: '/reports/leave-summary', current: true },
                                        { name: 'Leave Report - Detailed', href: '/reports/leave-detailed', current: false },
                                        // { name: 'Scheduler Report', href: '/reports/scheduler', current: false },
                                        // { name: 'Timesheet Report', href: '/reports/timesheet', current: false },
                                    ]
                                    } >
                                    </NavVertical>
                                </div>

                                <div className='flex-grow-2 ml-32'>
                                    <h1 className='text-4xl'>Select Report Criteria</h1>
                                    <div className='flex-grow-2 mt-8 p-8 bg-gray-200'>


                                        <form name="form1" method="post" onSubmit={handleSubmit(runReport)}>
                                            <input type="hidden" name="selected_report" value="" />

                                            <div class="divider">

                                                <table>
                                                    <tbody>
                                                        {campuses && campuses.length > 1 && (
                                                            <tr>
                                                                <td colSpan={2}>   Select a Campus
                                                                    <select id="campus_list" onChange={filterEmployeesByCampus} name="campus_list" className="mt-2 inline rounded-md border-0 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                        <option selected={true} value="all">All Campuses</option>
                                                                        {campuses && campuses.map((c) => {
                                                                            return (<option value={c.id + ""}>{c.company_name}</option>)
                                                                        })}
                                                                    </select></td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>

                                                                <label style={{ width: 165 }} id="lbl1">Filter Employees</label>&nbsp;<br />
                                                              

                                                                <select id="options_list" name="options_list" onChange={filterEmployees} size="9" class="preselect">
                                                                    <option selected={true} value="all">All Active</option>
                                                                    <option value="AD">A - D</option>
                                                                    <option value="EH">E - H</option>
                                                                    <option value="IL">I - L</option>
                                                                    <option value="MP">M - P</option>
                                                                    <option value="QT">Q - T</option>
                                                                    <option value="UZ">U - Z</option>
                                                                    <option value="inactive">Inactive</option>
                                                                    <option value="terminated">Terminated</option>
                                                                </select>
                                                            </td>
                                                            <td>

                                                                <label id="lbl2">Select Employees</label> <a className="text-xs inline text-blue-500" onClick={selectAllEmployees}>select all</a><br clear="all" />

                                                                {/* <HookTextField {...registerState('name')} /> */}
                                                                {employeesFiltered.length > 0 && (

                                                             
                                                                        
                                                                <select id="employee_list" {...register("employee_list")} name="employee_list" size="9" multiple="true">
                                                                    {employeesFiltered && employeesFiltered.map((e) => {
                                                                        return (<option selected={e.isSelected} value={e.employeeId + ""} >{e.employeeLastNameFirst}</option>)
                                                                    })}

                                                                </select>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>





                                            </div>


                                            <div class="divider">
                                                <label id="lbl3">Start Date: </label> 
                                                <select name="from_yr" {...register("from_yr")} size="1">
                                                    <option value="2020">2020</option>
                                                    <option value="2021">2021</option>
                                                    <option value="2022">2022</option>
                                                    <option selected={true} value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                </select>, <select name="from_mo" {...register("from_mo")}  size="1">
                                                    <option value="0">Jan</option>
                                                    <option value="1">Feb</option>
                                                    <option value="2">Mar</option>
                                                    <option value="3">Apr</option>
                                                    <option value="4">May</option>
                                                    <option value="5">Jun</option>
                                                    <option selected={true} value="6">Jul</option>
                                                    <option value="7">Aug</option>
                                                    <option value="8">Sep</option>
                                                    <option value="9">Oct</option>
                                                    <option value="10">Nov</option>
                                                    <option value="11">Dec</option>
                                                </select> <select name="from_day"  {...register("from_day")}  size="1">
                                                        {returnDays().map((d) => {
                                                            return (<option value={d}>{d}</option>)
                                                        })}
                                                    </select> &nbsp;
                                                <label id="lbl4">End Date: </label> 
                                                
                                                <select name="to_yr"  {...register("to_yr")} size="1">    <option value="2020">2020</option>
                                                    <option value="2021">2021</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option selected={true} value="2024">2024</option></select>, <select {...register("to_mo")} name="to_mo" size="1">
                                                    <option value="0">Jan</option>
                                                    <option value="1">Feb</option>
                                                    <option value="2">Mar</option>
                                                    <option value="3">Apr</option>
                                                    <option value="4">May</option>
                                                    <option value="5">Jun</option>
                                                    <option selected={true} value="6">Jul</option>
                                                    <option value="7">Aug</option>
                                                    <option value="8">Sep</option>
                                                    <option value="9">Oct</option>
                                                    <option value="10">Nov</option>
                                                    <option value="11">Dec</option>
                                                </select> <select name="to_day"  {...register("to_day")} size="1">    {returnDays().map((d) => {
                                                            return (<option selected={d == 28} value={d}>{d}</option>)
                                                        })}</select>
                                            </div>

                                            <div class="divider">
                                                <label id="lbl5">Select Categories</label>&nbsp;<a onClick={selectAllCategories} className="text-xs inline">select all</a>
                                                <br clear="all" />
                                                <select name="categories_list" {...register("categories_list")} size="5" multiple="true"  style={{ width: 465 }}>
                                                    {leaveTypes && leaveTypes.map((lt) => {
                                                        return (
                                                            <option selected={lt.isSelected}  value={lt.id + ""}>{lt.leave_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <div class="notes">Note: selecting &quot;none&quot; is the same as selecting &quot;all&quot;.</div>
                                            </div>
                                            <div class="divider hidden" id="misc"></div>
                                            <div class="divider" align="center">
                                                <input type="submit"  name="btnSubmit" value="Launch Report" className="ml-6 inline-flex items-center gap-x-1.5 rounded-md bg-blue-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </div>
    );
}