
import axios from 'axios';
// axios.defaults.baseURL = 'https://localhost:7110';

const instance = axios.create({
    baseURL: 'https://leavescheduler.azurewebsites.net' 
  });


  async function request(method, url, paramsData) {

    

    try {

      const { data } = await instance.request({
        method,
        url,
        data: paramsData?.body,
        params: paramsData?.queryParams,
        headers: {
          'content-type': paramsData?.body instanceof FormData ? 'multipart/form-data' : 'application/json',
          'Authorization' : 'Bearer ' + window.localStorage.getItem('access-token')
        }
      });
  
      return data;
    } catch (error) {
      if(error.response.status == 401){
        window.location = "/"
      }
      throw error; //ErrorResponse.mapFromApi(error);
    }
  }


  function get(url, paramsData) {
    return request("GET", url, paramsData);
  }
  
  function post(url, paramsData){
    return request("POST", url, paramsData);
  }
  
  function patch(url, paramsData) {
    return request("PATCH", url, paramsData);
  }
  
//   function remove<T = void>(url: string, paramsData?: HttpParamsType): Promise<T> {
//     return request<T>(HttpMethod.Delete, url, paramsData);
//   }

export {get, post}