import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'

export default function LeaveTypeEdit() {

    const { id } = useParams();

    const { response, loading, error } = useAxios({
        method: 'get',
        url: `/api/leave/type/${id}`,
        headers: JSON.stringify({ accept: 'application/json' }),
    });

    const [data, setData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (response !== null) {
            setData(response);
        }
    }, [response]);

    const save = () => {
        navigate(`/config/leave-types`)
    }

    // const accrualMethod = (params) => {
    //     var p = params.filter((pa) => {
    //         return pa.param_name === "ACCRUAL_METHOD"
    //     });
    //     return p[0].param_value;
    // }

    return (
        <div className="min-h-full">


            <main>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

                    <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">

                        <table width="100%" border="0" cellSpacing="1" cellPadding="10" className="formtable">
                            <tbody><tr align="left" valign="top">

                                <td >
                                    <form name="form1" action="edit_leaveType.asp" method="post">
                                        <input type="hidden" name="form_submitted" value="true" />
                                        <input type="hidden" name="id_leaveType" value="36" />

                                        <table width="100%" border="0" cellSpacing="0" cellPadding="5" className="border_all">
                                            <tbody>
                                                <tr>
                                                    <td style={{ backgroundColor: '#ebebeb' }}>
                                                        <div className="bodycopy">

                                                            <div className="form_item_area">
                                                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                    <tbody>
                                                                        <tr align="left" valign="top">
                                                                            <td>
                                                                                <label>Leave Type Name:</label><br />
                                                                                <input className="textbox" type="text" name="leave_name" size="36" maxLength="100" />
                                                                                <br />
                                                                                <br />

                                                                                <label>Leave Type Abbreviation:</label> <input className="textbox" type="text" name="leave_name_abbr" size="4" maxLength="6" />
                                                                                <br />
                                                                                <br />

                                                                                <label>Fiscal Start Month:</label> <select name="fiscalStart_month" size="1">
                                                                                    <option value="1">January</option>
                                                                                    <option value="2">February</option>
                                                                                    <option value="3">March</option>
                                                                                    <option value="4">April</option>
                                                                                    <option value="5">May</option>
                                                                                    <option value="6">June</option>
                                                                                    <option value="7">July</option>
                                                                                    <option value="8">August</option>
                                                                                    <option value="9">Septmember</option>
                                                                                    <option value="10">October</option>
                                                                                    <option value="11">November</option>
                                                                                    <option value="12">December</option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <label>Leave Type Short Description:</label><br />
                                                                                <textarea name="leave_description" rows="6" cols="50"></textarea>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <br />

                                                            <div className="form_item_area">
                                                                <label>Set the Time Increments by which this Leave may be Requested:</label><br />
                                                                <div className="form_item_indented">
                                                                    <input type="radio" name="REQUEST_TIME_INCREMENT" value="15" /> 15 Minute Increments<br />
                                                                    <input type="radio" name="REQUEST_TIME_INCREMENT" value="30" checked="" /> Half Hour Increments<br />
                                                                    <input type="radio" name="REQUEST_TIME_INCREMENT" value="60" /> One Hour Increments<br />
                                                                    <input type="radio" name="REQUEST_TIME_INCREMENT" value=".5" /> Half Day Increments (based upon workday)<br />
                                                                    <input type="radio" name="REQUEST_TIME_INCREMENT" value="1" /> One Day Increments (based upon workday)
                                                                </div>
                                                            </div>
                                                            <br />

                                                            <div className="form_item_area">
                                                                <label>Can an Employee Request this Leave on the Day Preceeding a Holiday?</label> <input type="radio" name="FLAG_PRE_HOLIDAY" value="0" checked="" />YES <input type="radio" name="FLAG_PRE_HOLIDAY" value="1" />NO<br />
                                                                <label>Can an Employee Request this Leave on the Day Following a Holiday?</label> <input type="radio" name="FLAG_POST_HOLIDAY" value="0" checked="" />YES <input type="radio" name="FLAG_POST_HOLIDAY" value="1" />NO<br />
                                                                <div className="form_item_indented">
                                                                    <span className="note">NOTE: if you replied NO to either question, the Employee will still be able to Request the leave, however, it will be Flagged and the supervisor has the option to approve or deny at that time.</span>
                                                                    <br />
                                                                </div>
                                                            </div>
                                                            <br />

                                                            <div className="form_item_area" id="ACCRUAL_METHOD_0">
                                                                <label>When Does This Leave Type Accrue?</label><br />
                                                                <div className="form_item_indented">
                                                                    <input type="radio" name="ACCRUAL_METHOD" value="NONE" checked="" /> Does not Accrue / Unpaid Leave <span className="note">(skip the following steps)</span><br />
                                                                    <input type="radio" name="ACCRUAL_METHOD" value="UPFRONT" /> Accrues Upfront at Start of Fiscal Year<br />
                                                                    <input type="radio" name="ACCRUAL_METHOD" value="MONTHLY" /> **Accrues Monthly
                                                                </div>
                                                            </div>
                                                            <br />

                                                            <div className="form_item_area" id="USE_CUSTOM_CALC" >
                                                                <label>Does this Leave Type rely on a Custom Calculation?</label> <input type="radio" name="use_custom_calc" value="0" checked="" />NO &nbsp;<input type="radio" name="use_custom_calc" value="1" />YES (skip the following)
                                                            </div>
                                                            <br />

                                                            <div className="form_item_area"  id="MONTH_ACCRUES_START_OR_END_0">
                                                                <label>Does this Leave Type accrue at the Beginning or End of Month?</label><br />
                                                                <div className="form_item_indented">
                                                                    <input type="radio" name="MONTH_ACCRUES_START_OR_END" value="START" /> Beginning (12:00 AM on first day of month)<br />
                                                                    <input type="radio" name="MONTH_ACCRUES_START_OR_END" value="END" /> End (11:59 PM on last day of month)<br />
                                                                    <span className="note">Note: For yearly accruals, choosing End will date the accrual at 11:59 PM on the last day of the month preceding the Fiscal Start Month. Resets and caps will always be dated on the last day of the month preceding the Fiscal Start Month.</span>
                                                                </div>
                                                            </div>
                                                            <br />

                                                            <div className="form_item_area" id="ACCRUAL_CALC_METHOD_0" />
                                                            <label>How Does This Leave Type Accrue?</label><br />
                                                            <div className="form_item_indented">

                                                                <div id="ACCRUAL_CALC_METHOD_1" >
                                                                    <input type="radio" name="ACCRUAL_CALC_METHOD" value="UPFRONT_FLAT_HOURS" /> Flat Number of Upfront Hours: Enter Total Hours <input className="textbox_number" type="text" name="UPFRONT_FLAT_HOURS" size="3" maxLength="4" />:<input className="textbox_number" type="text" name="UPFRONT_FLAT_HOURS" size="2" maxLength="2" /> (hh:mm)<br />
                                                                    <input type="radio" name="ACCRUAL_CALC_METHOD" value="UPFRONT_WORKDAYS" /> *Number of Upfront Hours based upon Employee Workday: Enter Total Days <input className="textbox_number" type="text" name="UPFRONT_WORKDAYS" size="3" maxLength="5" /><br />
                                                                    <input type="radio" name="ACCRUAL_CALC_METHOD" value="UPFRONT_FLAT_HOURS_PER_MONTH" /> Flat Number of Hours times Months Contracted: Enter Hours Per Month <input className="textbox_number" type="text" name="UPFRONT_FLAT_HOURS_PER_MONTH" size="3" maxLength="4" />:<input className="textbox_number" type="text" name="UPFRONT_FLAT_HOURS_PER_MONTH" size="2" maxLength="2" /> (hh:mm)<br />
                                                                    <input type="radio" name="ACCRUAL_CALC_METHOD" value="UPFRONT_WORKDAYS_PER_MONTH" /> *Number of Hours times Months Contracted based upon Employee Workday: Enter Days Per Month <input className="textbox_number" type="text" name="UPFRONT_WORKDAYS_PER_MONTH" size="3" maxLength="5" /><br />
                                                                </div>

                                                                <div id="ACCRUAL_CALC_METHOD_2" className="hidden">
                                                                    <input type="radio" name="ACCRUAL_CALC_METHOD" value="FLAT_HOURS_PER_MONTH" /> Flat Number of Hours Per Month <input className="textbox_number" type="text" name="FLAT_HOURS_PER_MONTH" size="3" maxLength="3" />:<input className="textbox_number" type="text" name="FLAT_HOURS_PER_MONTH" size="2" maxLength="2" /> (hh:mm)<br />
                                                                    <input type="radio" name="ACCRUAL_CALC_METHOD" value="WORKDAYS_PER_MONTH" /> *Number of Hours per Month based upon Employee Workday: Enter Days <input className="textbox_number" type="text" name="WORKDAYS_PER_MONTH" size="3" maxLength="5" /><br />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <br />

                                                        <div className="form_item_area" id="ACCUMULATION_METHOD_0" >
                                                            <label>Please Choose the End of Year Action:</label><br />
                                                            <div className="form_item_indented">
                                                                <input type="radio" name="ACCUMULATION_METHOD" value="RESET" /> Resets at Fiscal End Date<br />
                                                                <input type="radio" name="ACCUMULATION_METHOD" value="CARRYOVER_NO_LIMIT" /> Carries Over - No Limit<br />
                                                                <input type="radio" name="ACCUMULATION_METHOD" value="CARRYOVER_CAPPED" /> Caps and Carries Over<br />
                                                                <div className="form_item_indented">
                                                                    <div className="form_item_area" id="ACCUMULATION_CARRYOVER_ACTION_0">
                                                                        <input type="radio" name="ACCUMULATION_CALC_METHOD" value="HOURS" />Maximum Accumulated Hours: <input className="textbox_number" type="text" name="ACCUMULATION_CALC_METHOD_HOURS" size="3" maxLength="3" />:<input className="textbox_number" type="text" name="ACCUMULATION_CALC_METHOD_HOURS" size="2" maxLength="2" /> (hh:mm)<br />
                                                                        <input type="radio" name="ACCUMULATION_CALC_METHOD" value="WORKDAYS" />*Maximum Accumulated Days based upon Employee Workday: <input className="textbox_number" type="text" name="ACCUMULATION_CALC_METHOD_WORKDAYS" size="3" maxLength="5" /><br />
                                                                        <br />

                                                                        <label>Choose the order of accrual actions:</label><br />
                                                                        <div className="form_item_indented">
                                                                            <input type="radio" name="ACCUMULATION_CARRYOVER_ACCRUAL_ORDER" value="CAP_BEFORE_ACCRUALS" /> Time is capped before new Accruals (total can exceed cap)<br />
                                                                            <input type="radio" name="ACCUMULATION_CARRYOVER_ACCRUAL_ORDER" value="CAP_AFTER_ACCRUALS" /> Time is capped after new Accruals (total never exceeds cap)<br />
                                                                            <br />
                                                                        </div>

                                                                        <label>What happens to the Remaining Time at the end of the year?</label><br />
                                                                        <div className="form_item_indented">
                                                                            <input type="radio" name="ACCUMULATION_CARRYOVER_ACTION" value="LOSE_REMAINING_TIME" /> Time is lost permanently.<br />
                                                                            <input type="radio" name="ACCUMULATION_CARRYOVER_ACTION" value="TRANSFER" /> †Transfer Leave to Another Leave Type: Choose Leave Type <select name="ACCUMULATION_TRANSFER_LEAVETYPE"><option value="33">CNCD Vacation Leave</option>
                                                                                <option value="31">Emergency Leave</option>
                                                                                <option value="5">Jury Duty</option>
                                                                                <option value="35">Maternity Leave</option>
                                                                                <option value="27">Personal Leave</option>
                                                                                <option value="29">Professional Leave</option>
                                                                                <option value="1">Sick Leave</option>
                                                                                <option value="32">Sick Leave Bank</option>
                                                                                <option value="28">Unpaid Leave</option>
                                                                                <option value="2">Vacation Leave</option>
                                                                            </select>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" style={{backgroundColor:'#ebebeb'}}>
                                                        <input className="button" type="reset" /> 
                                                        <input className="button" type="button" name="btnCancel" value="Cancel"  /> 
                                                        <input className="button_submit" type="submit" name="btnSave" value="Save Leave Type" />
                                                        {/* <button onClick={search}
                                                            type="button"
                                                            className="ml-2 rounded bg-blue-400 px-6 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            Search
                                                        </button> */}
                                                    
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="left"><span className="note">*Calculations based upon an employee’s workday are as follows: Number of Hours divided by Employee Workday equals Calculated Days (i.e.  if an employee accrues 3 Days of Personal Leave upfront, based upon an 8 Hour Workday, the employee would accrue 24 hours, but based upon a 7.5 Hour Workday, the employee would only accrue 22.5 Hours).<br />
                                                        <br />
                                                        **For employees contracted at less then 12 months, you may choose which months not to accrue time when setting up their account.<br />
                                                        <br />†To transfer all leave at the end of the year, enter a 0 (zero) in the Hours or Days field. </span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </main >
        </div >
    );
}