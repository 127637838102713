import { Link, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Moment from 'react-moment';
import moment from 'moment';
import redFlag from '../images/icons/flag_red.gif'
import greenFlag from '../images/icons/flag_green.gif'
import blueFlag from '../images/icons/flag_blue.gif'
import orangeFlag from '../images/icons/flag_orange.gif'
import pencilIcon from '../images/icons/page_edit.gif'

import useHelpers from '../util'

export default function Dates({ id, handleEdit }) {

    const [dates, setDates] = useState([]);

    const { convertMinutesToTime } = useHelpers();

    const flags = [];

    flags["orange"] = orangeFlag;
    flags["red"] = redFlag;
    flags["green"] = greenFlag;
    flags["blue"] = blueFlag;

    const { response, loading2, error2 } = useAxios({
        method: 'get',
        url: `/api/leave/request/${id}/dates`,
        headers: JSON.stringify({ accept: 'application/json' }),
        // body: JSON.stringify({
        //     userId: 1,
        //     id: 19392,
        //     title: 'title',
        //     body: 'Sample text',
        // }),
    });


    useEffect(() => {
        if (response !== null) {
            console.log(response)
            setDates(response);
        }
    }, [response]);



    const formatFlag = (ars) => {
        switch (ars.status) {
            case 0:
                //status = ' class="disabled"';
                return flags["red"];
                break;

            default:
                //total_minutes += minutes;

                if (ars.date_modified) {
                    return flags["blue"]; //modified
                } else {
                    return flags["green"]; //ok
                }
        }
    }

    const _handleEdit = (item) => {
        handleEdit(item)
    }

    const totalHoursRequested = () => {
        var th = dates.map((r) => {return (-1 * (r.minutes - r.minutes_pause)) })

        return th.reduce((partialSum, a) => partialSum + a, 0);
    }

    return (
        <>
        
            <table className="min-w-full divide-y divide-gray-300 rounded-md border border-gray-200" >
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="whitespace-nowrap py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                        >
                        </th>
                        <th
                            scope="col"
                            className="whitespace-nowrap py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                        >
                            Date
                        </th>
                        <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-1.5 text-left text-sm font-semibold text-white"
                        >
                            From
                        </th>
                        <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-1.5 text-left text-sm font-semibold text-white"
                        >
                            To
                        </th>
                        <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-1.5 text-left text-sm font-semibold text-white"
                        >
                            Total
                        </th>
                        <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-1.5 text-left text-sm font-semibold text-white"
                        >
                        </th>
                   
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {dates && dates.map((dol) => {
                        return (
                            <tr key={dol.id}>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 ">
                                    <img src={pencilIcon} onClick={() => _handleEdit(dol)} alt="" height="16" width="16" border="0" />
                                </td>
                                <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:pl-0">{moment(dol.date_start).format('dddd MM/DD/YYYY')}</td>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"><Moment format='LT'>{dol.date_start}</Moment></td>
                                {/* <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-white">{dol.minutes} {dol.minutes_pause}</td> */}
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500"><Moment format='LT'>{moment(dol.date_start).add((-1*(dol.minutes - dol.minutes_pause)), 'minutes')}</Moment></td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{convertMinutesToTime(dol.minutes - dol.minutes_pause)}</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500"><img src={formatFlag(dol)} alt="" height="16" width="16" border="0" /></td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
            <h5 className="h5 p-2" >Total hours requested: {convertMinutesToTime(totalHoursRequested())}</h5>
            {/* convertMinutesToTime(data.totalMinutes)} */}
        </>
    )

}