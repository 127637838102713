
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { ArrowRightIcon, ArrowLeftIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, ArrowDownRightIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import useRoles from '../hooks/useRoles'
import { useEffect, useState } from 'react'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import Nav from '../components/nav'
import Dates from '../app_leave/dates'
import Calendar from '../app_calendars/calendar'
import Balance from '../app_leave/balance'
import actionBack from "../images/icons/action_back.gif"
import { useForm } from "react-hook-form"
import Moment from 'react-moment';
import moment from 'moment';
import { toast } from "react-toastify";
import useHelpers from '../util'

import { get, post } from '../services/base-http';

export default function Create() {

    const [dates, setDates] = useState([]);

    const [startTimeOptions, setStartTimeOptions] = useState([]);
    const [endTimeOptions, setEndTimeOptions] = useState([]);

    const { convertMinutesToTime, formatTimeFromMilitary } = useHelpers();

    const [currentStep, setCurrentStep] = useState(1);

    const [employeeList, setEmployeeList] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    const [showEmployeeSelection, setShowEmployeeSelection] = useState(false);

    const [totalTimeAvailable, setTotalTimeAvailable] = useState(0);
    const [totalRequestedTime, setTotalRequestedTime] = useState(0);

    const [isAdminRole, setIsAdmin] = useState(false);

    const { id } = useParams();

    const { isAdmin } = useRoles();

    const [leaveTypes, setLeaveTypes] = useState([])

    const [data, setData] = useState({ id: 0, employee: null, name: 'New Request', leaveTypeId: 0, dates: [] });

    const navigate = useNavigate();

    const {
        leave_type,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()


    useEffect(() => {

        var ia = isAdmin().then((result) => {

            setIsAdmin(result)

            if (result) {
                
                get("/api/employee/list", {}).then((data) => {
                    setEmployeeList(data);
                    setShowEmployeeSelection(true);
                })
            }
            else{

                
                get("/api/my/supervisors", {}).then((data) => {
                    setSupervisors(data)
                });

                get("api/my/profile", {}).then((profile) => {

                    data.accountEmployee = profile;
                    //data.accountEmployeeName = profile.full_name;

                    setData(Object.assign({}, data))
                }); 
            }
           
        });

        get("/api/leave/types", {}).then((data) => {
            setLeaveTypes(data);
        })

    }, []);

    const selectEmployee = (e) => {

        var emp = employeeList.filter((ee) => {
            return ee.id == e.target.value;
        })

        data.accountEmployee = emp[0];
        //console.log(Object.assign({}, data))
        setData(Object.assign({}, data))
    }

    const save = () => {

        if(!data.notes) {
            alert(
                'You must provide a reason for the request.'
            )
            return;
        }
        data.dates = dates;
        data.totalMinutes = totalRequestedTime;
        
        console.log(data);


        post("/api/leave/request", { body: data }).then((data) => {

            if(isAdminRole) {
                navigate('/leave')
            }
            else {
                navigate('/my/leave/request/history')
            }

        })
    }

    // const totalTimeRequested =() => {
    //     var value = data.dates.reduce((partialSum, a) => partialSum + a, 0);
    //     return value;
    // }

    const nextStep = () => {

        if (currentStep == 1) {

            if (employeeList.length > 0 && (!data.accountEmployeeId || data.accountEmployeeId == 0)) {
                alert('Please select an employee to continue')
            }
            else if (data.leaveTypeId === 0) {
                alert('Please select a leave type.')
            }
            else if (dates.length === 0) {
                alert('Please select dates for leave.')
            }
            else {
                // get holiday list to see if this date is on a holiday
                // sql = "SELECT t1.id_calendar, t1.event_name FROM event_calendar t1, event_calendar_dates t2 WHERE t1.id_calendar = t2.event_id ";
                // sql += "AND t2.event_date = '" + newD + "' AND t1.is_holiday = 1";


                // var d1 = new Date((rf("start")));
                // var d2 = new Date((rf("end")));

                var newD; //, weekDay, dLabel, chkExclude, wkndStyle;	
                var isHoliday, iTime, tDate, j;
                var endTime = moment();
                var minuteIncrement = time_increment - 0;
                var strOptions = "";
                var strJavascript = "";

                var time_increment = 30;

                // sql = "SELECT param_value FROM leave_type_param WHERE " +
				// "param_name = 'REQUEST_TIME_INCREMENT' AND " +
				// "leaveType_id = '" + Session("id_leaveType") + "'";
                // var time_increment = conn.Execute(sql);
                //     if(time_increment.EOF && time_increment.BOF) {
                //         time_increment = "30";
                //     } else {
                //         time_increment = time_increment(0).Value;
                //     }
                    


                // get(`/api/employee/${data.accountEmployee.id}/schedule`, {}).then((data) => {
    
                    var emp_workday_start = data.accountEmployee.emp_workday_start;
                    var emp_workday = data.accountEmployee.emp_workday;
                    var emp_lunch_start = data.accountEmployee.emp_lunch_start; 
                    var emp_lunch = data.accountEmployee.emp_lunch; 


                    var startTimeSplit = data.accountEmployee.emp_workday_start.split(":");
                    var startDateTime = moment().startOf('day').add(startTimeSplit[0], 'hours').add(startTimeSplit[1], 'minutes');

                    const etv = moment(startDateTime).add(time_increment, 'minutes');
                    dates.map((d) => {
                        d.startTime = startDateTime.format("HH:mm");
                        d.endTime = etv.format("HH:mm");
                        d.dayType = "Partial";
                        d.minutes_pause = emp_lunch;
                        d.totalMinutes = etv - moment(startDateTime).format("HH:mm");
                    });

                    setDates(Object.assign([], dates));


                    var eeLunchTimeSplit = data.accountEmployee.emp_lunch_start.split(":");
                    var eeLunchDateTime = moment().startOf('day').hours(eeLunchTimeSplit[0]).minutes(eeLunchTimeSplit[1]);

                    var endDateTime = moment(startDateTime).minutes(emp_lunch).minutes(emp_workday)

                    var stos = [];
                    while(startDateTime <= eeLunchDateTime) {
                        stos.push({
                            militaryDateTime: startDateTime.format("HH:mm"),
                            time: startDateTime.format('LT') 
                        });

                        startDateTime.add(time_increment, 'minutes');
                    }
                    setStartTimeOptions(stos);

                    //var postLunchShiftStartTime = moment(stos[stos.length - 1]).minutes(emp_lunch);
                    //var emp_daay_end = 
                     
                    var etos = [];
                    startDateTime = moment().startOf('day').hours(startTimeSplit[0]).minutes(startTimeSplit[1]).add(time_increment, 'minutes');
                    //while(startDateTime.format("HH:mm") != endDateTime.format("HH:mm")) {
                    while(startDateTime <= endDateTime) {
                        etos.push({
                            militaryDateTime: startDateTime.format("HH:mm"),
                            time: startDateTime.format('LT') 
                        });

                        startDateTime.add(time_increment, 'minutes');
                     }
                    setEndTimeOptions(etos);

                    
                
                    if(time_increment != "1" && time_increment != ".5") { 

                   
                    }
                    else if(time_increment == "1") {

                    }
                    else { 

                    }
    // %>
// <label>From:</label>&nbsp;<select name="from_<%= newD %>" size="1"><%= strOptions %></select>&nbsp;&nbsp;
// <label>To:</label>&nbsp;<select name="to_<%= newD %>" size="1"><%= strOptions %></select>
// &nbsp;&nbsp;<label>or All Day</label><input type="checkbox" name="period_<%= newD %>" value="1" />
// <% } else if(time_increment == "1") { %>
// &nbsp;&nbsp;<label>All Day</label>
// <input type="hidden" name="period_<%= newD %>" value="1" />
// <input type="hidden" name="from_<%= newD %>" value="<%= newD %>" />
// <input type="hidden" name="to_<%= newD %>" value="<%= newD %>" />
// <% } else { %>
// <input type="radio" name="period_<%= newD %>" value="1" id="radio_allday_<%= newD %>" checked /><label htmlFor="radio_allday_<%= newD %>">All Day</label>&nbsp;
// <input type="radio" name="period_<%= newD %>" value=".5" id="radio_halfday_<%= newD %>" /><label htmlFor="radio_halfday_<%= newD %>">or Half Day:</label>&nbsp;<select name="period_hd_<%= newD %>">
// <option value="AM">AM</option>
// <option value="PM">PM</option>
// </select>
// <input type="hidden" name="from_<%= newD %>" value="<%= newD %>" />
// <input type="hidden" name="to_<%= newD %>" value="<%= newD %>" />


                    
    
                    setCurrentStep(2);
                // })
            }
        }
        if (currentStep == 2) {

            console.log(dates)

            var totalRequestedTime = 0;

            dates.map((d) => {

                var startSplit = d.startTime.split(":");
                var start = moment().startOf('day').hours(startSplit[0]).minutes(startSplit[1]);
                var endSplit = d.endTime.split(":");
                var end = moment().startOf('day').hours(endSplit[0]).minutes(endSplit[1]);

                var durationM = moment.duration(end.diff(start)).minutes();
                var durationH = moment.duration(end.diff(start)).hours();
                
                var minutes = (durationH * 60) + durationM; //minutes instead of asMinutes

                totalRequestedTime = (totalRequestedTime + minutes);
                //d.minutes_pause = 
                d.totalMinutes = minutes;
                d.totalTime = convertMinutesToTime(minutes); // (end - start).format("HH:mm");
            });

            setTotalRequestedTime(totalRequestedTime)

            setDates(Object.assign([], dates));
            console.log(dates)

            get(`/api/employee/${data.accountEmployee.id}/leave/balance/${data.leaveTypeId}`, {}).then((data) => {
                
                console.log(data);

                setTotalTimeAvailable(data.currentBalance);

                setCurrentStep(3);
            })

      
        }
    }

    const selectDate = (date) => {

        var alreadySelected = dates.filter((d) => {
            return d == date;
        });

        console.log(alreadySelected)

        if(alreadySelected.length == 0) {
            dates.push(date)

            setDates(Object.assign([], dates));
        }
        else{
            dates.push(date)

            //var newarray = dates.splice(dates.indexOf(date), 1);
            var without = dates.filter((d) => {
                return d !== date;
            });


            setDates(Object.assign([], without));
        }
        
        
    }

    const goBack = () => {

        setCurrentStep((currentStep - 1));
    }

    const selectLeaveType = (e) => {

        data.leaveTypeId = e.id;
        data.leave_name = e.leave_name;
        data.leave_description = e.leave_description;

        setData(Object.assign({}, data))
    }

    const handleStartTimeChange = (date, event) =>{

        var date = dates.filter((d => {
            return d.date === date
        }));

        date.startTime = event.target.value;

        console.log(date)
        console.log(event.target.value)

        
        // d.totalMinutes = minutes;
        // d.totalTime = convertMinutesToTime(minutes); // (end - start).format("HH:mm");

        setDates(Object.assign([], dates));

    }

    const handleEndTimeChange = (date, event) =>{

        dates.map((d => {
            console.log(d)
            if(d.date === date) {
                console.log('found()' + event.target.value)
                d.endTime = event.target.value;
            }
        }));

        
        // d.totalMinutes = minutes;
        // d.totalTime = convertMinutesToTime(minutes); // (end - start).format("HH:mm");

        setDates(Object.assign([], dates));
        
    }

    const handlePriorityChange = (e) => {
        data.priority = e.target.value;
        setData(data);
    }
    const handleStatusChange = (e) => {
        data.status = e.target.value;
        setData(data);
    }
    const handleSendConfirmationEmailChange = (e) => {
        data.sendConfirmationEmail = e.target.value;
        setData(data);
    }
    const handleIsEmergencyChange = (e) => {
        data.isEmergency = e.target.value;
        setData(data);
    }
    const handleExtra1Change = (e) => {
        data.extra1 = e.target.value;
        setData(data);
    }
    const handleCoordinatorChange = (e) => {
        data.coordinatorId = e.target.value;
        setData(data);
    }
    const requestReasonChange = (e) => {
        data.notes = e.target.value;
        setData(data);
    }

    

    const reviseDates = () => {
        setCurrentStep(1)
    }

    const reviseTimes = () => {
        setCurrentStep(2)
    }

    const cancel = () => {
        navigate(-1)
    }


    return (
        <div className="min-h-full">

            {data && (
                <>
                    {/* 
            <header className="bg-white shadow-sm">
                <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg font-semibold leading-6 text-gray-900">{data.leaveType} request for {data.accountEmployee.full_name}</h1>
                </div>
            </header> */}
                    <main>
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <div className="-mx-4 p-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg bg-white">

                                {currentStep == 1 && (
                                    <div className='flex'>
                                        <div className="w-72">
                                            {employeeList.legth > 0 && (
                                                <>
                                                    <h1 className="text-base font-semibold leading-4 text-gray-900">Select an Employee:</h1>
                                                    <select
                                                        id="location"
                                                        name="location"
                                                        onChange={selectEmployee}
                                                        value={data.accountEmployeeId ? data.accountEmployeeId : 0}
                                                        className="mt-2 inline rounded-md border-0 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value={0}>-- Select Employee --</option>
                                                        {employeeList.map((e) => (
                                                            <option value={e.id}>{e.full_name}</option>
                                                        ))}


                                                    </select>
                                                    <br />
                                            <br />
                                                </>
                                            )}

                                            
                                            <h1 className="text-base font-semibold leading-4 text-gray-900">Select Leave Type:</h1>
                                            {/* <fieldset>
                                <legend className="text-sm font-semibold leading-6 text-gray-900">Notifications</legend> */}
                                            {/* <p className="mt-1 text-sm leading-6 text-gray-600">How do you prefer to receive notifications?</p> */}
                                            <div className="mt-4 pl-4 space-y-1">
                                                {leaveTypes.map((e) => (
                                                    <div key={e.id} className="flex items-center">
                                                        <input
                                                            //defaultChecked={notificationMethod.id === 'email'}
                                                            id={e.id}
                                                            name="leave_type"
                                                            type="radio"
                                                            onClick={() => selectLeaveType(e)}
                                                            className=" w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            {...register("leave_type")}
                                                        />
                                                        <label htmlFor={e.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                            {e.leave_name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            {!isAdminRole && data.leave_description && (
                                                <div className="w-64 text-sm mt-8 text-blue-600">
                                                    <p><b>{data.leave_name}</b> {data.leave_description}</p>
                                                </div>
                                            )}
                                            
                                            
                                            {/* </fieldset>
                        */}
                                        </div>
                                        <div className="flex-grow">
                                            <Calendar events={dates} selectDate={selectDate} />
                                        </div>
                                    </div>
                                )}

                                {currentStep == 2 && (

                                    <div>
                                             <h4 className="h4 mb-1" >Select Leave Request Times</h4>
                                                    <b>{data.accountEmployee.full_name}, {data.leave_name}</b>

                                        {dates && dates.map((date) => {
                                            return (
                                                <div >
                                               
                                                  
                                                            <fieldset className="p-4 mt-6 mb-6">
                                                            <div className="flex" >
                                                                    <div className="h4 flex-grow p-2">
                                                                        <span>
                                                                            <input type="checkbox" name="inc_1721797200000" value="1" checked="checked" title="Include this date" /></span>
                                                                        <span className="p-4">{date.date}</span>
                                                                    </div>
                                                                    <div>
                                                                    <label>
                                                                        <span className="h4">From</span>
                                                                        <select value={date.startTime} onChange={(e) => handleStartTimeChange(date, e) } 
                                                                        className="inline rounded-md border-0  ml-1 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                            {startTimeOptions && startTimeOptions.map((sto) => {
                                                                                return ( <option value={sto.militaryDateTime}>{sto.time}
                                                                                {/* 8:00 am */}
                                                                                </option>);
                                                                            })}
                                                                            {/* <option value="8:00">8:00 am</option>
                                                                            <option value="9:00">9:00 am</option>
                                                                            <option value="10:00">10:00 am</option>
                                                                            <option value="11:00">11:00 am</option>
                                                                            <option value="12:00">12:00 pm</option>
                                                                            <option value="13:00">1:00 pm</option>
                                                                            <option value="14:00">2:00 pm</option>
                                                                            <option value="15:00">3:00 pm</option>
                                                                            <option value="16:00">4:00 pm</option> */}
                                                                        </select>
                                                                    </label>
    
    
                                                                    <label>
                                                                        <span className="h4">To</span>
    
                                                                        <select name="to_1721797200000" value={date.endTime}  onChange={(e) => handleEndTimeChange(date.date, e) }  className="inline rounded-md border-0 ml-1 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                            {endTimeOptions && endTimeOptions.map((sto) => {
                                                                                return ( <option value={sto.militaryDateTime}>{sto.time}
                                                                                {/* 8:00 am */}
                                                                                </option>);
                                                                            })}
{/* 
                                                                            <option value="9:00">9:00 am</option>
                                                                            <option value="10:00">10:00 am</option> */}
                                                                          
                                                                        </select>
                                                                    </label>
                                                                </div>
                                                                <div >
                                                                    <input id="default-checkbox" type="checkbox" value="" 
                                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor="default-checkbox" className="h4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">All Day</label>
                                                                </div>
    
                                                            </div>
                                                        </fieldset>
                                                        
                                               
                                                </div>
                                            )
                                        })}
                                        <p>* Select Holidays only if you are scheduled to work on that date.</p>
                                    </div>
                                )}

                                {currentStep == 3 && (

                                    <form name="form1" >
                                 

                                        <div className="h2">Step 3: Finalize Your Leave Request</div>
                                        <b>{data.accountEmployee  && data.accountEmployee.full_name}, {data.leave_name}</b>
                                        <br />
                                        <br />
                                        <table className="w-full border border-gray-400">
                                            <tbody>
                                                <tr>
                                                    <th>Requested Dates</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Period</th>
                                                    <th>Time</th>
                                                </tr>

                                                {dates && dates.map((d) => {
                                                    return (
                                                        <tr>
                                                              <td className="text-center text-sm"><Moment format='ddd, LL'>{d.date}</Moment></td>
                                                                {/* &nbsp;&nbsp;Wed, Jul 24, 2024 */}
                                                                <td className="text-center text-sm">{formatTimeFromMilitary(d.startTime)}</td>
                                                                <td className="text-center text-sm">{formatTimeFromMilitary(d.endTime)}</td>
                                                                <td className="text-center text-sm">{d.dayType}</td>
                                                                {/* Partial Day */}
                                                                <td className="text-center text-sm">{d.totalTime}</td>
                                                                {/* 1:00 */}

                                                        </tr>
                                                    )
                                                })}

                                            </tbody></table>

                                            

                                        <div align="right">
                                            
                                            <div className="notes float-left"><br />Note: Lunches are automatically calculated.<br />
                                                <br />
                                                <div id="flags" >
                                                    <b className="flagpre" style={{ color: 'red', backgroundColor: 'yellow' }}>&nbsp;!&nbsp;</b> denotes the day before a Holiday.<br />
                                                    <b className="flagpost" style={{ color: 'yellow', backgroundColor: 'red' }}>&nbsp;!&nbsp;</b> denotes the day after a Holiday<br />
                                                    This is not allowed for this leave type and could be denied.</div>
                                            </div>

                                            <div className="border_all" style={{ width: '250px', padding: '10px', borderTop: 'solid 1px #DDD' }}>
                                                <span className="bodycopy"><b>Total Requested Time: {convertMinutesToTime(totalRequestedTime)}</b></span>
                                                    {/* 2:00 */}
                                                    <br />
                                                    

                                                {totalTimeAvailable < totalRequestedTime ? 
                                                <>
                                                        <div style={{color:'red', fontSize:12}}>Time Available: {convertMinutesToTime(totalTimeAvailable)} </div>
                                                        <div style={{color:'red', fontSize:12, marginTop:10}}>You do not have enough available time to cover this request.</div>
                                                        </>
                                                        :
                                                        <span>Hours Available:  {convertMinutesToTime(totalTimeAvailable)}</span>
                                                    }

<br />
                                                {/* if(leave_accrual_method != "NONE") {
                                                    var available_minutes = getLeaveTotal(empid, id_leaveType);
                                                    
                                                    
                                                }
                                                 */}
                                                 <div className="flex notes justify-end" >
                                                    <a onClick={reviseDates} className='mr-4 cursor-pointer'>
                                                        <img src={actionBack} alt="" height="16" width="16" className="inline"  /> Revise Dates</a> &nbsp;
                                                    <a onClick={reviseTimes} className="cursor-pointer">
                                                        <img src={actionBack} alt="" height="16" width="16" className="inline" /> Revise Times</a>
                                                </div>
                                            </div>

                                        </div>
                                        <br />
                                        <br />
                                        <fieldset>
                                            <legend>Please Provide The Following Information:</legend>

                                            <div className="flex" >
                                                

                                            <div className='flex-grow'>
                                                <label>Reason For Request:</label><br />
                                                <textarea name="request_reason" onChange={requestReasonChange} style={{width:400, height:90}}></textarea><br />
                                                {/* onkeydown="calcChar();" */}
                                                <div  className="note">Max 200 characters: <span id="maxchar">0</span></div>
                                            </div>


                                        {employeeList.legth > 0 ? (
                                                <div className="p-6 flex-grow">
                                                    <div className="mb-3">
                                                    <label>Priority: 
                                                        <select name="priority" 
                                                        onChange={handlePriorityChange}
                                                        className="inline rounded-md border-0  ml-1 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                        <option value="0">Normal</option>
                                                        <option value="1">High</option>
                                                        <option value="2">Emergency</option>
                                                        <option value="-1">Already Taken</option>
                                                    </select></label>
                                                    </div>
                                                    <div className="mb-3">

                                                    <label>Status: 
                                                        <select name="status" 
                                                    onChange={handleStatusChange}
                                                    className="inline rounded-md border-0  ml-1 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                        <option value="3">Accepted</option>
                                                        <option value="1">Pending</option>
                                                    </select></label><br />
                                                    </div>
                                                    <div className="mb-3">
                                                    
                                                        
                                                        <label>    Send confirmation E-mail</label><input type="checkbox" 
                                                        onChange={handleSendConfirmationEmailChange }
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-3"  /><br />
                                                    </div>
                                                </div>
                                            ): (
                                                <div className="flex-grow">
                                                    <div className="mb-3">
                                                <div>
                                                    
                                                    <input type="checkbox" className="inline mr-2" onChange={handleIsEmergencyChange} value={data.isEmergency} />
                                                    <label className="inline">Is This An Emergency</label>
                                                    <br />
                                                    <br />
                                                    <label>Individual to assume duties during applicant's leave:</label>
                                                    <br />
                                                    <input type="text" className="inline" value={data.extra1} onChange={handleExtra1Change} />
                                                    <br />
                                                    <br />
                                                    <label>Select Your Coordinator (if applicable): </label>
                                                    <br />
                                                    <select name="coordinator" 
                                                    onChange={handleCoordinatorChange}
                                                    className="inline rounded-md border-0  ml-1 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                        <option value=""></option>
                                                        {supervisors && supervisors.map((c) => (
                                                            <option value={c.id}>{c.full_name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                                </div>
                                                </div>
                                            )}
                                            <br />

                                            </div>
                                          
                                        </fieldset>
                                    </form>
                                )}

                                <br />
                                <br />

                                <div className="flex justify-center" >
                                    {currentStep == 2 && (
                                        <button
                                            onClick={goBack}
                                            type="button"
                                            className="ml-6 inline-flex items-center gap-x-1.5 rounded-md bg-blue-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >

                                            <ArrowLeftIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                            Update

                                        </button>
                                    )}
                                    {currentStep == 3 && (
                                        <>
                                        <button
                                            onClick={cancel}
                                            type="button"
                                            className="ml-6 inline-flex items-center gap-x-1.5 rounded-md bg-gray-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >

                                            Cancel

                                        </button>
                                        <button
                                            onClick={save}
                                            type="button"
                                            className="ml-6 inline-flex items-center gap-x-1.5 rounded-md bg-blue-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >

                                        Save
                                        </button>

                                        </>
                                    )}

                                    {currentStep < 3 && (
                                        <button
                                            onClick={nextStep}
                                            type="button"
                                            className="ml-6 inline-flex items-center gap-x-1.5 rounded-md bg-blue-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >

                                            Next Step
                                            <ArrowRightIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )}

        </div>
    );
}