import { Link, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import useHelpers from '../util'
import Moment from 'react-moment'
import iconPageFind from '../images/icons/page_find.gif'
import { get, post } from '../services/base-http';

export default function Requests() {

    const { convertMinutesToTime, formatPriority, formatStatus } = useHelpers();

    const [pageParam, setPageParam] = useState(1);
    const [data, setData] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [dataFiltered, setDataFiltered] = useState();
    const [pageNumber, setPageNumber] = useState(pageParam || 1);

    const pageSize = 15;
    const [totalPages, setTotalPages] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {

        get("/api/leave/pending", {}).then((data) => {
            setData(data);
            //setDataFiltered(data)
            //setPageNumber(1)
            loadData(1, data)
        })

    }, []);

    // useEffect(() => {
    //     loadData(pageNumber)
    // }, [])


    const paging = (e) => {
        setPageNumber(e.target.value)

        loadData(e.target.value, data);
    }

    const loadData = (thePageNumber, theData) => {

        var tp = Math.round((theData.length / pageSize));

        var tpv = (tp > 0 ? tp : 1);

        console.log(tpv);

        setTotalPages(tpv);

        var df = theData.slice((thePageNumber - 1) * pageSize, thePageNumber * pageSize);
        
        setDataFiltered(Object.assign([], df));
    }

    const returnPages = () => {
        var dayray = [];

        for(let i = 1; i <= totalPages; i++){
            dayray.push(i)
        }
        return dayray;
    }


    const viewRequest = (id) => {
        navigate(`/leave/request/${id}/view`)
    }

    return (
        <div className="min-h-full">

           
        <main>
            <div className="mx-auto mt-6 max-w-7xl px-4 sm:px-6 lg:px-8">
            {/* <Tabs tabs={[
                { name: 'List Employee', href: '/accounts/employees', current: true },
                { name: 'List Admin', href: '/accounts/admins', current: false },
            ]} /> */}
              <h2 className="h4">Pending Leave Requests</h2>
             
            <div className="-mx-4 mt-2 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">

          
                <table className="min-w-full divide-y divide-gray-300 striped">
                    <thead>
                        <tr>
                            <th  className="hidden px-3  text-left text-sm font-semibold text-white lg:table-cell">Date of Request</th>
                            <th  className="hidden px-3  text-left text-sm font-semibold text-white lg:table-cell">Leave Type</th>
                            <th  className="pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">Employee</th>
                            <th  className="hidden px-3  text-left text-sm font-semibold text-white lg:table-cell">Supervisor</th>
                            <th  className="px-3  text-left text-sm font-semibold text-white">Dates of Leave</th>
                            <th  className="px-3  text-left text-sm font-semibold text-white">Hours</th>
                            {/* <th  className="px-3  text-left text-sm font-semibold text-white">Priority</th> */}
                            <th  className="px-3  text-left text-sm font-semibold text-white">Status</th>
                            {/* <th  className="relative  pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {dataFiltered && dataFiltered.map((lr, i) => (
                            <tr key={i}>
                                {/* onClick={() => viewRequest(lr.id)} */}
                                <td className="relative py-2 pl-4 pr-3 text-sm sm:pl-6">
                                    <img src={iconPageFind} className='inline pr-1' onClick={() => viewRequest(lr.id)} />
                                    <Link to={`/leave/request/${lr.id}/view`}>{lr.dateOfRequest}</Link>
                                </td>
                                <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{lr.leaveType}</td>
                                <td className="relative py-2 pl-4 pr-3 text-sm sm:pl-6">
                                    <Link to={`/accounts/employee/${lr.employeeId}/view`}>{lr.employeeName}</Link>
                                </td>
                                <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{lr.supervisorName}</td>
                                <td className="px-3 py-2 text-sm text-gray-500">
                                    {lr.dates.map((d,i) => {
                                        return (
                                            <div key={i}><Moment format='MM/DD/YYYY'>{d.date}</Moment> {d.startTime} - {d.endTime}</div>
                                        )
                                    })}
                                </td>
                                <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{convertMinutesToTime(lr.totalMinutes)}</td>
                                {/* <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{lr.priority}</td> */}
                                <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{formatStatus(lr.status)}</td>
                                {/* <td className="relative py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <button type="button" className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white">Select<span className="sr-only">, Hobby</span></button>
                            </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <br />
                <p className='text-xs text-gray-600 p-3'>No of records: {data.length}  |  Page  
                        <select name="status" value={pageNumber} 
                        onChange={paging}
                        className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        {returnPages().map((d, i) => {
                            return (<option key={i} value={d}>{d}</option>)
                        })}
                    </select>{pageNumber} of {totalPages}</p>
            </div>
            <br />
            <p className='text-xs text-gray-500'>Note: pending records marked with a icon are Emergency Requests.</p>
        </div>
        </main>
        </div>
    );
}