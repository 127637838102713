
// <!--
//   This example requires updating your template:

//   ```
//   <html className="h-full bg-gray-100">
//   <body className="h-full">
//   ```
// -->
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

import { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import Moment from 'react-moment';
import moment from 'moment';
import useAxios from '../hooks/useAxios'

import { get, post } from '../services/base-http';

import { useForm, SubmitHandler } from "react-hook-form"

import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'


export default function Calendar({events, selectDate}) {

    const [open, setOpen] = useState(false)

    const [years, setYears] = useState([]);

    const [currentDate, setCurrentDate] = useState(moment().format('DD/MM/YYYY'));
    const [selectedDate, setSelectedDate] = useState(moment());
    const [weeks, setWeeks] = useState([]);

    const [days, setDays] = useState([]);
    const [currentYear, setCurrentYear] = useState(moment().year());
    const [currentMonth, setCurrentMonth] = useState(moment().month());

    const [previousMonth, setPreviousMonth] = useState();
    const [previousYear, setPreviousYear] = useState();

    const selectedDay = days.find((day) => day.isSelected)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [data, setData] = useState([]);

    useEffect(() => {

        console.log(currentYear)
        console.log(currentMonth)
        get(`/api/event/list/${currentYear}/${currentMonth}`, {}).then((data) => {
            setData(data);
        });

        buildCalendar();

    }, [currentYear, currentYear]);

    useEffect(() => {
        var years = [];
        for (var i = moment().year(); i <= moment().year() + 3; i++) {
            years.push(i);
        }
        setYears(years);
    }, []);

    const buildCalendar = () => {

        // get start of current month

        const firstOfMonth = moment(selectedDate).startOf('month').day();
        const lastOfMonth = moment(selectedDate).endOf('month').day();
        const firstDayOfGrid = moment(selectedDate).startOf('month').subtract(firstOfMonth, 'days');
        const lastDayOfGrid = moment(selectedDate).endOf('month').subtract(lastOfMonth, 'days').add(6, 'days');

        // console.log(firstOfMonth)
        // console.log(lastOfMonth)
        // console.log(firstDayOfGrid)
        // console.log(lastDayOfGrid)

        var dateIndex = firstDayOfGrid;
        var cDates = [];
        while (dateIndex <= lastDayOfGrid) {

            var eventsForDate = [];
            if(events) {
                eventsForDate = events.filter((d) => {
                    //console.log(d.dateStart)
                    //console.log(dateIndex.format("MM/DD/YYYY"))
                    if (d.dateStart == dateIndex.format("MM/DD/YYYY")) {
                        return d;
                    }
                });
            }

            cDates.push(
                {
                    date: dateIndex.format('YYYY-MM-DD'),
                    isCurrentMonth: isSelectedMonth(dateIndex),
                    isSelected: false,
                    events: eventsForDate
                });

            dateIndex = dateIndex.add(1, 'days');
        }

        setDays(cDates)
    }

    const isSelectedMonth = (date) => {
        const today = moment();
        return moment(date).isSame(today, 'month');// && moment(date).isSameOrBefore(today);
    }

    const updateCalendarMonth = (date) => {
        console.log(selectedDate)

        setSelectedDate(moment(Object.assign({}, selectedDate.month(date.target.value))));

        setCurrentMonth(date.target.value);
        console.log(selectedDate)

    }
    const updateCalendarYear = (date) => {
        console.log(date)

        setSelectedDate(moment(Object.assign({}, selectedDate.year(date.target.value))));


        setCurrentYear(date.target.value);
        //buildCalendar();
    }

    const _selectDate = (date) => {
        //alert('asd')
        if(selectDate) {
            selectDate(date);
            //events.push(date)

            //var dayFound = days.filter((r) => r == date)

            //console.log(days)
            console.log(date)

            var df = days.find((day) => day == date)

            console.log(df)

            if(!df.isSelected){
                Object.assign({}, df.isSelected = true);
            }
            else{
                df.isSelected = false;
            }

            setDays(Object.assign([], days));
            //alert(df)
        }
    }

    return (

        <div className="lg:flex lg:h-full lg:flex-col">

            <div className='flex flex-row flex-grow-1 justify-center'>
                <div className='flex p-4'>
                    <a
                        href="#"
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
                    </a>
                </div>
                <header className="flex-grow px-6 py-4 text-center">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        <time dateTime="2022-01">{selectedDate.format("MMMM")} {currentYear}</time>
                    </h1>

                </header>
                <div className='flex  p-4'>
                    <a
                        href="#"
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
                    </a>
                </div>
            </div>

            <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col" style={{height:500}}>
                <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    <div className="bg-white py-2">
                        M<span className="sr-only sm:not-sr-only">on</span>
                    </div>
                    <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">ue</span>
                    </div>
                    <div className="bg-white py-2">
                        W<span className="sr-only sm:not-sr-only">ed</span>
                    </div>
                    <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">hu</span>
                    </div>
                    <div className="bg-white py-2">
                        F<span className="sr-only sm:not-sr-only">ri</span>
                    </div>
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">at</span>
                    </div>
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">un</span>
                    </div>
                </div>
                <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                    <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                        {days.map((day) => (
                            <div
                                key={day.date}
                                onClick={() => _selectDate(day)}
                                className={classNames(
                                    day.isSelected ? 'bg-blue-200' : day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                    'relative px-3 py-2',
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={
                                        day.isToday
                                            ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                                            : undefined
                                    }
                                >
                                    {day.date.split('-').pop().replace(/^0/, '')}
                                </time>
                                {day.events.length > 0 && (
                                    <ol className="mt-2">
                                        {/* {day.events.slice(0, 2).map((event) => ( */}
                                        {day.events.map((event) => (
                                            <li key={event.leaveRequestId}>
                                                <a className="group flex cursor-pointer">
                                                    {/* href={event.href} */}
                                                    <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                                        {event.employeeName} {event.leaveType}
                                                    </p>
                                                    {/* <time
                                                        dateTime={event.datetime}
                                                        className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                                    >
                                                        {event.time}
                                                    </time> */}
                                                </a>
                                            </li>
                                        ))}
                                        {/* {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>} */}
                                    </ol>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                        {days.map((day) => (
                            <button
                                key={day.date}
                                type="button"
                                className={classNames(
                                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                    (day.isSelected || day.isToday) && 'font-semibold',
                                    day.isSelected && 'text-white',
                                    !day.isSelected && day.isToday && 'text-indigo-600',
                                    !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                    !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                    'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10',
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={classNames(
                                        day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                        day.isSelected && day.isToday && 'bg-indigo-600',
                                        day.isSelected && !day.isToday && 'bg-gray-900',
                                        'ml-auto',
                                    )}
                                >
                                    {day.date.split('-').pop().replace(/^0/, '')}
                                </time>
                                <span className="sr-only">{day.events.length} events</span>
                                {day.events.length > 0 && (
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        {day.events.map((event) => (
                                            <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                                        ))}
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            {selectedDay?.events.length > 0 && (
                <div className="px-4 py-10 sm:px-6 lg:hidden">
                    <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                        {selectedDay.events.map((event) => (
                            <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50" >
                                <div className="flex-auto">
                                    <p className="font-semibold text-gray-900">{event.name}</p>
                                    <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                                        <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        {event.time}
                                    </time>
                                </div>
                                <a
                                    href={event.href}
                                    className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                                >
                                    Edit<span className="sr-only">, {event.name}</span>
                                </a>
                            </li>
                        ))}
                    </ol>
                </div>
            )}

            <div className="flex">
                <div>
                    <span>Jump to: </span>
                    <select
                        id="location"
                        name="location"
                        onChange={updateCalendarMonth}
                        defaultValue={currentMonth}
                        value={currentMonth}
                        className="mt-2 inline rounded-md border-0 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        <option value="0">January</option>
                        <option value="1">February</option>
                        <option value="2">March</option>
                        <option value="3">April</option>
                        <option value="4">May</option>
                        <option value="5">June</option>
                        <option value="6">July</option>
                        <option value="7">August</option>
                        <option value="8">Septmember</option>
                        <option value="9">October</option>
                        <option value="10">November</option>
                        <option value="11">December</option>
                    </select>
                    <select
                        id="location"
                        name="location"
                        onChange={updateCalendarYear}
                        defaultValue={currentYear}
                        value={currentYear}
                        className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        {years.map((t, i) => (
                            <option  key={i} value={t}>{t}</option>
                        ))}
                    </select>
                </div>

            </div>

        </div>
    )
}

