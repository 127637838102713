import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'
import historyIcon from '../images/icons/icon_history.gif'
import { get } from '../services/base-http';
import Moment from 'react-moment';
import useHelpers from '../util'

export default function RequestHistory({ data, allowClick }) {
    
    const [pageParam, setPageParam] = useState(1);
    const [sortParam, setSortParam] = useState('lastname');
    const [nameParam, setNameParam] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [dataFiltered, setDataFiltered] = useState([]);
    const [leaveTypeFilter, setLeaveTypeFilter] = useState(0);

    const [pageNumber, setPageNumber] = useState(pageParam);
    const [leaveTypes, setLeaveTypes] = useState([])
    const { convertMinutesToTime, formatStatus, formatStatusShort } = useHelpers();


    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {

        setDataFiltered(data)

        // get(`/api/employee/${id}/leave-request/history`, null).then((response) => {
        //     if (response !== null) {
        //         setData(response);
        //         setDataFiltered(response)
        //     }
        // });


    }, []);

    const handleLeaveTypeChange = (e) => {
        setLeaveTypeFilter(e.target.value)

        if (e.target.value == 0) {
            setDataFiltered(data);
        }
        else {
            var r = data.filter((r) => {
                return r.leaveTypeId == e.target.value;
            });

            setDataFiltered(r)
        }

    }

    return (
        <div className="min-h-full">
            <main>
                <div className="mx-auto max-w-7xl">
                    <div className="flex justify-end">
                        <div>
                            <select onChange={handleLeaveTypeChange} className="inline rounded-md border-0 ml-1 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-xs sm:leading-6">
                                <option value={0}>All Leave Types</option>
                                {leaveTypes.map((e) => (
                                    <option value={e.id}>{e.leave_name}
                                        {/* 8:00 am */}
                                    </option>)
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="-mx-4 mt-2 ring-1 ring-gray-300 sm:mx-0 rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300 striped">
                            <thead>
                                <tr>
                                    <th scope="col" className="rounded-tl pl-4 pr-3 text-left text-xs font-semibold sm:pl-6">Requested</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold lg:table-cell">Processed</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold lg:table-cell">Leave Type</th>
                                    <th scope="col" className=" px-3 py-1.5 text-left text-xs font-semibold lg:table-cell">Dates of Leave</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold ">Hours</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold">Status</th>
                                    <th scope="col" className="px-3 py-1.5 text-left text-xs font-semibold rounded-tr">Notes</th>
                                    {/* <th scope="col" className="relative py-1.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {dataFiltered && dataFiltered.map((l) => (
                                    <tr className='hover:bg-sky-100' >
                                        <td className="relative py-2 pl-4 pr-3 text-xs sm:pl-6">
                                            {allowClick ? (
                                                <Link to="#"><Moment format="MM/DD/YYYY" >{l.dateAdded}</Moment></Link>
                                            ) :
                                            (
                                                <Moment format="MM/DD/YYYY HH:mm a" >{l.dateAdded}</Moment>
                                            )}
                                        </td>
                                        <td className="hidden px-3 py-2 text-xs text-gray-900 lg:table-cell">{(l.dateProcessed ? <>{l.dateProcessed} <br /> by {l.processedBy}</> : "")}</td>
                                        <td className="hidden px-3 py-2 text-xs text-gray-900 lg:table-cell">{l.leaveType}</td>
                                        <td className="hidden px-3 py-2 text-xs text-gray-900 lg:table-cell">
                                            {l.dates && l.dates.map((d) => {
                                                    return (
                                                        <p>

                                                        <Moment format="MM/DD/YYYY">{d.date}</Moment> &nbsp;
                                                        {d.startTime} - {d.endTime} {formatStatusShort(d.status)}
                                                        </p>
                                                    )
                                                })}
                                        </td>
                                        <td className="hidden px-3 py-2 text-xs text-gray-900 lg:table-cell">{convertMinutesToTime(l.totalMinutes)}</td>
                                        <td className="hidden px-3 py-2 text-xs text-gray-900 lg:table-cell">{formatStatus(l.status)}</td>
                                        <td className="hidden px-3 py-2 text-xs text-gray-900 lg:table-cell">{l.notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <br />
                        <div>
                        <div className='p-2 text-xs text-gray'>
                        [p] = pending<br />[a] = accepted<br />[d] = denied<br />[m] = modified<br />
                        Italics denotes notes attached. Mouseover to view.
                        </div>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}