
import backgroundImage from '../images/background-auth.jpg'
import { Link } from 'react-router-dom';
import Logo from '../images/header-art.png'
import Kmi from '../images/client/kiamichi-login-banner.png'

export function SlimLayout({ children }) {
  return (

      <div className="relative flex min-h-full shrink-0 justify-center md:px-12 lg:px-0">
        
        <div className="mt-24 relative z-10 flex flex-1 flex-col bg-white p-2 shadow-2xl sm:justify-center md:flex-none ring-1 ring-gray-300 sm:mx-0 sm:rounded-2xl">
        

          {/* <img src="https://www.leavescheduler.com/demo/images/client/title_bar.gif" alt="Leave Scheduler Online"  height="60" border="0" className='sm:rounded-t-lg' />  */}
          <img src={Kmi} alt="Leave Scheduler Online"  height="60" border="0" className='sm:rounded-t-lg' /> 
          
          
          <main className="mx-auto w-full max-w-md sm:px-4  md:px-0">
          {/* md:w-96 md:max-w-sm */}
            {children}
          </main>
        </div>
        {/* <div className="hidden sm:contents ">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={backgroundImage}
            alt=""
            unoptimized
          />
        </div> */}
    </div>
  )
}
