

import Nav from '../components/nav'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import LeaveCalendar from '../app_calendars/leave_calendar'
import EventCalendar from '../app_calendars/event_calendar'
import SchedulerCalendar from '../app_calendars/scheduler_calendar'
import View from '../app_leave/view';

export default function Calendars() {

    return (

        <div className="mx-auto max-w-7xl bg-white">
            <Nav />
         {/* <Tabs tabs={[{ name: 'List Employee', href: '#', current: true }]} /> */}
        <Routes>
            <Route path="/" element={<LeaveCalendar />} />
            <Route exact path="/leave/" element={<LeaveCalendar />} />
            <Route exact path="/event/" element={<EventCalendar />} />
            <Route exact path="/scheduler/" element={<SchedulerCalendar />} />
            </Routes>
            {/* <Route exact path="/equipment/:id/view" component={View} />
            <Route exact path="/equipment/:id/readonly" component={ReadOnly} />
            <Route exact path="/equipment/:id/images" component={Images} />
            <Route exact path="/equipment/qrscan" component={QrScan} />
            <Route exact path="/equipment/:id/qrcode" component={QrCode} /> */}
        </div>
    )
}
