
  
import { useState, useEffect } from 'react';
import useAxios from './useAxios'

import {get, post} from '../services/base-http';



    //const [isCopied, setCopied] = React.useState(false);
  
    //const canDelete = usePermission('delete');

const useRoles = () => {

    const isAdmin = () => {

        var countFound = 0;
        return new Promise((resolve) => {
            get(`/api/role/mine/`, {}).then((response) => {

                var roleFound = response.filter((r) => {
                    return r === "ADMINISTRATOR"// || r == "DIRECTOR" || r == "SUPERVISOR"
                }); 

                resolve(roleFound.length > 0);
            
            }).catch((r) => {
                //alert('bad request')
                return null;
            });

        });
        

       
          
    
    }

    return {isAdmin}
}
    // function handleCopy(text) {
    //   if (typeof text === "string" || typeof text == "number") {
    //     // copy
    //   } else {
    //     // don't copy
    //     console.error(
    //       `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`
    //     );
    //   }
    // }



  export default useRoles;