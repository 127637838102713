import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/icons/logo.png'
import leaveCalendar from '../images/login_calendar.gif'
import dottedLogin from '../images/nav/rule_dotted_login.gif'
import { TextField, Button } from '@mui/material'
import {FormInputText} from '../components/formInput'
import { SlimLayout } from '../layouts/silm'
import { get, post } from '../services/base-http';
import CircularProgress from '@mui/material/CircularProgress';

import { useForm } from "react-hook-form"

export const metadata = {
  title: 'Sign In',
}

export default function Login() {

  const navigate = useNavigate();

  const [isAdministrator, setIsAdministrator] = useState(false);
  const [loggingIn, setIsLoggingIn] = useState(false);

  const {
    username, 
    password,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    console.log(data)

    // const register = post("register", {
    //   body: {
    //       //username: "dans3", //data.username,
    //       email: data.username,
    //       password: data.password
    //       // "twoFactorCode": "string",
    //       // "twoFactorRecoveryCode": "string"
    //   }
    // });

    setIsLoggingIn(true)
    post("login", {
      body: {
          //username: "dans", //data.username,
          email: data.username,
          password: data.password,
          //isAdministrator: isAdministrator,
          useCookies: true
          // "twoFactorCode": "string",
          // "twoFactorRecoveryCode": "string"
      }
    }).then((r) => {
      window.localStorage.setItem('access-token', r.accessToken)
      window.localStorage.setItem('refresh-token', r.refreshToken)

      get("api/role/mine", {}).then((roles) => {

        console.log(roles)

        setIsLoggingIn(false)
        
        if(roles[0] == 'EMPLOYEE') {

          navigate("/my/calendar")
        }
        else { //if(roles[0] == 'ADMINISTRATOR') {
          navigate("/leave/pending")
        }
      })

    }).catch((r) => {
      alert('Login failed')
    });



    //navigate(`/config/contract-types`)
  };


  return (
    <SlimLayout>
      <div className="flex">
        <div>
          {/* <h2 className="text-lg font-semibold text-gray-900">
            Sign in
            // to your account
          </h2> */}
          <p className="mt-2 text-xs text-gray-700">
          Leave Scheduler Account Login.
            Unauthorized access is being
            monitored and recorded.

           {/* Don’t have an account?{' '}
            <Link
              to="/register"
              className="font-medium text-blue-600 hover:underline"
            >
              Sign up
            </Link>{' '}
            here */}
          </p>
          <form action="#" className="grid grid-cols-1" onSubmit={handleSubmit(onSubmit)}>

          <div>
            <label htmlFor="username" className="block text-sm font-bold leading-6 text-gray-900">
              Username
            </label>
            <div className="mt-0">
              <input
                id="username"
                name="username"
                type="text"
                placeholder=""
                {...register("username", { required: true, maxLength: 30 })}
                className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="mt-1">
            <label htmlFor="password" className="block text-sm font-bold leading-6 text-gray-900">
              Password
            </label>
            <div className="">
              <input
                id="password"
                name="password"
                type="password"
                placeholder=""
                {...register("password", { required: true, maxLength: 30 })}
                className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-3 flex justify-end">
            {loggingIn && (
                 <CircularProgress />
            )}

            {!loggingIn && (
              <Button type="submit" variant="contained">
                <span>
                  Login <span aria-hidden="true">&rarr;</span>
                </span>
              </Button>
            )}
            </div>
          </form>
        </div>
        <div className="p-6 pt-12 text-sm">
          <img src={leaveCalendar} />
          <img src={dottedLogin} alt="" width="215" height="2" border="0" vspace="15"></img>
          {/* Don’t have an account?{' '}
            <Link
              to="/register"
              className="font-medium text-blue-600 hover:underline"
            >
              Sign up
            </Link>{' '}
            here */}
        </div>
      </div>


    </SlimLayout>
  )
}
