import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'
import historyIcon from '../images/icons/icon_history.gif'
import { get } from '../services/base-http';
import Moment from 'react-moment';
import useHelpers from '../util'
import LeaveHistory from '../app_leave/leave-history'

export default function Requests() {

    const { id } = useParams();

    const [data, setData] = useState();
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [summary, setSummary] = useState();

    useEffect(() => {

     
        get(`/api/my/leave/history`, null).then((response) => {
            if (response !== null) {
                setData(response);
                //setDataFiltered(response)
            }
        });


            get(`/api/my/leave/balances/all`, null).then((response2) => {
                if (response2 !== null) {
                    setSummary(response2)
                }
            });
            
    }, []);


    return (
        <div className="min-h-full">

            <br />
            <main>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
                    <div className="">
                        <Tabs tabs={[
                            { name: 'Leave History', href: `/my/leave/history`, current: true },
                            { name: 'Leave Request History', href: `/my/leave/request/history`, current: false },
                        ]} />
                    </div>
                    <div className="-mx-4">
                        {data && summary && (
                            <LeaveHistory data={data} summaryData={summary} />
                        )}
                    </div>

                </div>
            </main>
        </div>
    );
}