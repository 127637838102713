
import Nav from '../components/nav'
import { Route, Routes } from 'react-router-dom';
import Content from '../pages/content'
import Calendar from '../app_calendars/event_calendar'
import LeaveHistory from '../my/leave-history'
import LeaveRequestHistory from '../my/request-history'
import CreateLeave from '../app_leave/create'
import Profile from '../my/profile'

export default function Accounts() {

    return (
        <div className="mx-auto max-w-7xl bg-white">
            <Nav />
            {/* <Tabs tabs={[{ name: 'List Employee', href: '#', current: true }]} /> */}
            <Routes>
                <Route path="calendar" element={<Calendar />} />
                <Route path="benefits" element={<Content />} />
                <Route path="profile" element={<Profile />} />
                <Route path="leave/history" element={<LeaveHistory />} />
                <Route path="leave/request/history" element={<LeaveRequestHistory />} />
                <Route path="leave/create" element={<CreateLeave />} />
            </Routes>
        </div>
    )
}

