
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import Nav from '../components/nav'
import Dates from '../app_leave/dates'
import Balance from '../app_leave/balance'

import Moment from 'react-moment';
import moment from 'moment';

import redFlag from '../images/icons/flag_red.gif'
import greenFlag from '../images/icons/flag_green.gif'
import blueFlag from '../images/icons/flag_blue.gif'
import orangeFlag from '../images/icons/flag_orange.gif'
import pencilIcon from '../images/icons/page_edit.gif'
import navWithArrow from '../images/nav/withArrow.gif'
import { useForm, SubmitHandler } from "react-hook-form"

import { get, post } from '../services/base-http';
import useHelpers from '../util'

export default function View() {


    const flags = [];

    flags["orange"] = orangeFlag;
    flags["red"] = redFlag;
    flags["green"] = greenFlag;
    flags["blue"] = blueFlag;


    const [dates, setDates] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [ccEmails, setCcEmails] = useState([]);
    const [emailCcString, setEmailCcString] = useState("");
    const [forwardTo, setForwardTo] = useState();
    const [action, setAction] = useState();

    const [editItem, setEditItem] = useState();

    const { id } = useParams();

    const { convertMinutesToTime, formatPriority, formatStatus } = useHelpers();

 
    useEffect(() => {

        get( `/api/leave/request/${id}`, {}).then((data) => {
            setData(data);
        })

        get("api/admin/list", {}).then((data) => {
            setAdmins(data);
        })

    }, []);


    const [data, setData] = useState();

    const navigate = useNavigate();

    const [open, setOpen] = useState(false)

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();


    const onSubmit = (data) => {

        post("/api/leave/request/process", {
            body: {
                id: id,
                processCode: action,

            }
        }).then(() => {
             //        Session(CONST.KEYS.alert) = "The " + rf("leave_request_name") + " request for " + rs.Fields("emp_fname").Value + " " + rs.Fields("emp_lname").Value + " was approved and submitted.";
             
            navigate('/leave')
        })

    };


    // const {
    //     register,
    //     handleSubmit,
    //     formState: { errors },
    // } = useForm()



    const viewRequest = (id) => {
        navigate(`/leave/${id}/view`)
    }

    const selectAction = (action) => {
        setAction(action)
    }

    const handleForwardChange = (fc) => {
        setForwardTo(fc.target.value)
    }

    const addEmail = (event) => {
        var email = event.target.value;
        var prepend = (emailCcString.length == 0 ? "" : ", ");
        var newEmailString = emailCcString + prepend + email;
        ccEmails.push(email);
        setCcEmails(Object.assign([], ccEmails));
        setEmailCcString(newEmailString)
    }

    const formatAdminLevel = (l) => {
        switch (l) {
            case 0:
                return " (coordinator)"; break;
            case 1:
                return " (supervisor)"; break;
            case 2:
                return " (director)"; break;
            case 3:
                return " (administrator)"; break;
        }
    }

    const handleEdit = (item) => {
        console.log(item)
        setEditItem(item)
        setOpen(true)
    }

   

    return (
        <div className="min-h-full">

            {data && (
                <>

                    <header className="bg-white shadow-sm">
                        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
                            <h1 className="text-lg font-semibold leading-6 text-gray-900">View Leave Request</h1>
                        </div>
                    </header>
                    <main>
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">

                            <h2>{data.leaveType} request for {data.employeeName}</h2>

                            <div className="-mx-4 mt-2 bg-gray-200 p-1 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">

                                <table width="100%" border="0" cellSpacing="0" cellPadding="4" >
                                    <tbody><tr align="left" valign="top">
                                        <td width="20%">Date of Request:</td>
                                        <td bgcolor="#f5f5f5" width="30%"><span class="h5">{moment(data.dateOfRequest).format("llll")}</span></td>
                                        <td width="20%">Supervisor:</td>
                                        <td bgcolor="#f5f5f5" width="30%"><span class="h5">{data.supervisorName} </span></td>
                                    </tr>
                                        <tr align="left" valign="top">
                                            <td width="20%">Leave Status:</td>
                                            <td bgcolor="#f5f5f5" width="30%"><span class="h5">{formatStatus(data.status)}<br />
                                            </span></td>
                                            <td width="20%">Employee E-mail:</td>
                                            <td bgcolor="#f5f5f5" width="30%"><span class="h5"><a href={`mailto:${data.employeeEmail}`}>{data.employeeEmail}</a></span></td>
                                        </tr>
                                        <tr align="left" valign="top">
                                            <td width="20%">
                                                Priority:<br />
                                            </td>
                                            <td bgcolor="#f5f5f5" width="30%">
                                                <span class="h5">{formatPriority(data.priority)}</span>
                                            </td>
                                            <td width="20%">Location/Campus:</td>
                                            <td bgcolor="#f5f5f5" width="30%"><span class="h5">{data.campus}</span></td>
                                        </tr>
                                        <tr align="left" valign="top">
                                            <td width="20%" class="border_top">Request Notes:</td>
                                            <td colspan="3" bgcolor="#f5f5f5" class="border_top"><div class="bodycopy">{data.notes}</div>&nbsp;</td>
                                        </tr>
                                    </tbody></table>

                            </div>
                            <br />
                            <div className="-mx-4 mt-2 bg-gray-200 p-1 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">

                                <form name="form1" method="post" >
                                    <input type="hidden" name="id_leaveRequest" value="34405" />
                                    <input type="hidden" name="denial_reason" value="" />
                                    <input type="hidden" name="forwarding_reason" value="" />
                                    <input type="hidden" name="leave_type_name" value="Vacation" />
                                    <input type="hidden" name="mandatory_email_cc" value="" />
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="4" class="" >
                                        <tbody>
                                            <tr align="left" valign="top">
                                                <td width="20%" class="border_bot">
                                                    Dates and Times<br />of Request:
                                                    <div class="notes">You may individually edit or deny each date by clicking the icon to the left.</div>
                                                </td>
                                                <td colspan="2" class="border_bot">


                                                    <Dates id={id} handleEdit={handleEdit} />


                                                    <Balance id={data.employeeId} />
                                                    {/* <h5 className="h5 p-2" >Total hours requested: {convertMinutesToTime(data.totalMinutes)}</h5> */}
                                                </td>

                                                <td class="border_bot">
                                                    <div>
                                                        <div>
                                                            <img src={flags["green"]} className='inline' /> = OK  </div>
                                                        <div>
                                                            <img src={flags["blue"]} className='inline' /> = Modified</div>

                                                        <div>
                                                            <img src={flags["red"]} className='inline' /> = Denied</div>

                                                        <div>
                                                            <img src={flags["orange"]} className='inline' /> = Next to Holiday
                                                        </div>
                                                        {/* Mouseover flags<br />to see notes. */}

                                                    </div>
                                                    {/* <h2 className="font-2xl" >Total Hours Requested: {}</h2> */}

                                                </td>
                                            </tr>
                                            <tr align="left" valign="top">
                                                <td class="border_bot" width="20%">Choose an Option:</td>
                                                <td class="border_bot" colspan="3">
                                                    <input type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" name="process_type" value="1" onChange={() => selectAction(1)} /> <span class="h4">Approve Leave Request</span><br />
                                                    <input type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" name="process_type" value="2" onChange={() => selectAction(2)} /> <span class="h4">Deny Leave Request</span><br />
                                                    <input type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" name="process_type" value="4" /> <span class="h4">Delete this Leave Request</span><br />
                                                    <input type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" id="process_type3" name="process_type" value="3" />
                                                    <span class="h4">Send to </span>
                                                    <select name="director_id" onChange={handleForwardChange}
                                                        className="mt-2 inline rounded-md border-0 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                        {admins && admins.map((a) => (
                                                            <option value={a.id}>{a.full_name}</option>
                                                        ))}








                                                    </select>
                                                    <label htmlFor="process_type3"><span class="h4"> for Further Review</span><br />
                                                    </label>


                                                </td>
                                            </tr>
                                            <tr align="left" valign="top">
                                                <td width="20%">
                                                    Cc of E-mail To:
                                                    <div class="notes">To send copies of the E-mail, type or select E-mails from the drop-down list into the text area. Separate multiple E-mail addresses with a comma.</div>
                                                </td>
                                                <td colspan="3">
                                                    <div>
                                                        <textarea name="email_cc" cols="40" rows="5" value={emailCcString}></textarea>
                                                        <br />
                                                        <img src={navWithArrow} className='inline' />
                                                        <select name="email_list" onChange={addEmail} className="mt-2 inline rounded-md border-0 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="0">ADD AN E-MAIL ADDRESS:</option>
                                                            {admins && admins.map((a) => (
                                                                <option value={a.admin_email}>{a.full_name + formatAdminLevel(a.admin_level)}</option>
                                                            ))}

                                                            {/* <option value="bilderback@allencc.edu">Rebecca Bilderback (supervisor)</option>
                                                            <option value="dalbini@allencc.edu">Josiah D Albini (supervisor)</option>
                                                           */}

                                                        </select>


                                                    </div>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </form>

                            </div>


                        </div>

                        <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
                            <DialogBackdrop
                                transition
                                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                            />

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <DialogPanel
                                        transition
                                        className="relative transform overflow-hidden rounded-lg bg-gray-200 px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                                    >
                                        <div>
                                            {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                </div> */}
                                            <div >
                                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    Edit Entry
                                                </DialogTitle>
                                                <div >
                                                    <br />
                                                    {/* <p className="text-sm text-gray-500">
                                    
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                                    </p> */}

                                                {editItem && (
                                                    <form name="form1" onSubmit={handleSubmit(onSubmit)}>
                                                        {/* <input type="hidden" name="id" value="59699" />
                                                        <input type="hidden" name="empid" value="911" />
                                                        <input type="hidden" name="form_submitted" value="true" />
                                                         */}
                                                        <table border="0" cellSpacing="0" cellPadding="2">
                                                            <tbody><tr align="left">
                                                                <td><label>Date:</label></td>
                                                                <td><input type="text"  name="date_start" 
                                                                className="rounded-md border-0 py-1 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                                                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                 value={moment(editItem.date_start).format("MM/DD/yyyy")} {...register("date_start")} size="10" maxLength="10" />
                                                                    <span class="notes">(mm/dd/yyyy)</span></td>
                                                            </tr>
                                                                <tr align="left">
                                                                    <td><label>From:</label></td>
                                                                    <td><input type="text"  name="from_hour"
                                                                     className="rounded-md border-0 py-1 text-gray-900 p-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                    value={moment(editItem.date_start).format("HH")} {...register("fromHours")} size="2" maxLength="2"  />
                                                                        <label>:</label>
                                                                        <input type="text" className="rounded-md border-0 py-1 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                                                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        value={moment(editItem.date_start).format("mm")} name="from_minutes"  {...register("fromMinutes")}  size="2" maxLength="2" />&nbsp;
                                                                        <select name="from_ampm" 
                                                                        defaultValue="AM"
                                                                        {...register("fromAmPm")} className="mt-2 inline rounded-md border-0 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                            <option value="AM">AM</option>
                                                                            <option value="PM">PM</option>
                                                                        </select></td>
                                                                </tr>
                                                                <tr align="left">
                                                                    <td><label>Time:</label></td>
                                                                    <td><nobr>
                                                                        <select name="posneg" defaultValue="pos"
                                                                         {...register("timePosNeg")} 
                                                                         className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                        <option value="pos">+</option>
                                                                        <option value="neg">-</option>
                                                                    </select> <input  type="text" name="hours" value="00" size="4" maxLength="4" className="rounded-md border-0 py-1 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                                                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" {...register("timeHours")} /><label>:</label>
                                                                        <input  type="text" name="minutes"  value="00" size="2" maxLength="2" className="rounded-md border-0 py-1 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                                                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" {...register("timeMinutes")}  /></nobr></td>
                                                                </tr>
                                                                <tr align="left">
                                                                    <td><label>Lunch:</label></td>
                                                                    <td><nobr><input  type="text" name="lunch_hours" value={((editItem.totalMinutes / 60) || 0)} size="4" className="rounded-md border-0 py-1 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                                                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" maxLength="4" {...register("lunchHour")} /><label>:</label>
                                                                        <input  type="text" name="lunch_minutes" value={editItem.totalMinutes} size="2" className="rounded-md border-0 py-1 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                                                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" maxLength="2" {...register("lunchMinute")} /></nobr></td>
                                                                </tr>
                                                                <tr align="left">
                                                                    <td><label>Status:</label></td>
                                                                    <td>
                                                                        <select name="status" 
                                                                        defaultValue="1"
                                                                        value={editItem.status} {...register("status")} className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                            <option value="1" >Pending</option>
                                                                            <option value="0">Denied</option>
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr align="left">
                                                                    <td><label>Note:</label></td>
                                                                    <td><textarea name="note" cols="35" value={editItem.notes} rows="" {...register("note")} className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea></td>
                                                                </tr>
                                                                {/* <tr align="left">
                                                                    <td></td>
                                                                    <td>
                                                                        <input type="button" class="button" name="btnCancel" value="Cancel" />
                                                                     <input type="submit" class="button" name="btnSubmit" value="Save Changes" />
                                                                     </td>
                                                                </tr> */}
                                                            </tbody></table>
                                                        <br />
                                                    </form>
)}

                                                   
                                                        {/* errors will return when field validation fails  */}
                                                        {errors.exampleRequired && <span>This field is required</span>}

                                                        <button
                                                            type="submit"
                                                            onClick={() => setOpen(false)}
                                                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            Save
                                                        </button>
                                               





                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-6">
                                            <button
                                                type="button"
                                                onClick={() => setOpen(false)}
                                                className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </DialogPanel>
                                </div>
                            </div>
                        </Dialog>

                        <div className="flex justify-center" >

                            <Link onClick={() => navigate(-1)}>
                                <button
                                    type="button"
                                    className="ml-6 inline-flex items-center gap-x-1.5 rounded-md bg-gray-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <CheckCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                    Cancel
                                </button></Link>
                           
                                <Link onClick={() => window.location.reload()}>
                            <button
                                type="button"
                                className=" ml-6 inline-flex items-center gap-x-1.5 rounded-md bg-gray-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <CheckCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                Reset
                            </button>
                            </Link>

                            <button
                                type="button"
                                onClick={onSubmit}
                                className=" ml-6 inline-flex items-center gap-x-1.5 rounded-md bg-blue-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <CheckCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                Process Request
                            </button>
                        </div>
                    </main>
                </>
            )}

        </div>
    );
}