import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import moment from 'moment';
import useHelpers from '../util'
import CircularProgress from '@mui/material/CircularProgress';

import { get, post } from '../services/base-http';

export default function ReportSummary({ options }) {

    const [leaveTypes, setLeaveTypes] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        get("/api/leave/types", {}).then((data) => {
            setLeaveTypes(data);
        })

        doIt()

    }, [])

    const { convertMinutesToTime, convertMinutesToWorkday } = useHelpers();

    const [data, setData] = useState();


    const [balanceDateBegin, setBalanceDateBegin] = useState(moment('7/1/2023').format("MM/DD/YYYY"));
    const [balanceDateEnd, setBalanceDateEnd] = useState(moment().format("MM/DD/YYYY"));
    const [balances, setBalances] = useState();
    const [employees, setEmployees] = useState();

    const navigate = useNavigate();

    const doIt = () => {

        post(`/api/leave/report/beginning/balances`, {
            body: options
        }).then((data) => {
            setBalances(data);
        })

        post("/api/report/leave-summary", { body: options }).then((data) => {
            setData(data);

            setLoading(false)
        })
    }

    const viewRequest = (id) => {
        navigate(`/leave/${id}/view`)
    }

    const getEmployees = (reportData) => {

        //const ees = [...new Set(reportData.map(item => item.employeeId ))];

        // var ees = reportData.map((d) => {
        //     return d.employee;
        // });

        // ees.map((e) => {
        //     e.leaveRecords = reportData.filter((r) => {
        //         if(r.employeeId == e.employeeId) {
        //             return { leaveType: r.leaveType, date: r.date, minutes: r.minutes };
        //         }
        //     })
        // })


        console.log()
        //setEmployees(ees);
    }

    const getAccruedForType = (r, t) => {
        var found = r.leaveRecordsAccrued.filter((lr) => { return lr.leaveType === t });
        if (found.length > 0) {
            return found[0].minutes;
        }
        return 0;
    }
    const getUsedForType = (r, t) => {

        var found = r.leaveRecordsUsed.filter((lr) => { return lr.leaveType === t });
        if (found.length > 0) {
            return found[0].minutes;
        }

        return 0;
    }


    const associateBalanceStart = (eeId, leaveType) => {
        var found = balances.filter((b) => { return b.employeeId === eeId && b.leaveType == leaveType });
        if (found.length > 0) {
            return found[0].totalBalance;
        }
        return 0;
    }


    const sumBalanceStart = (leaveType) => {
        var found = balances.filter((b) => { return b.leaveType == leaveType });
        if (found.length > 0) {
            return found[0].totalBalance;
        }
        return 0;
    }


    const sumAccrued = (leaveType) => {

        var total = 0;
        var found = data.map((r) => {

            var thang = r.leaveRecordsAccrued.filter((b) => {
                if (b.leaveType == leaveType) {
                    return b.minutes
                }
            });


            return (thang.length > 0 ? thang[0].minutes : 0)
        });

        return found;
    }

    const sumUsed = (leaveType) => {

        var total = 0;
        var found = data.map((r) => {

            var thang = r.leaveRecordsUsed.filter((b) => {
                if (b.leaveType == leaveType) {
                    return b.minutes
                }
            });


            return (thang.length > 0 ? thang[0].minutes : 0)
        });

        return found;
    }

    const computeBalanceEnd = (start, accrued, used) => {

        return (start + accrued - (used * -1));

    }


    const buildRow = (r, t) => {

        var beginningBalance = associateBalanceStart(r.employeeId, t);

        var accrued = getAccruedForType(r, t);
        var used = getUsedForType(r, t);

        var remainingBalance = computeBalanceEnd(beginningBalance, accrued, used);

        var balanceInWorkdays = convertMinutesToTime(remainingBalance);

        var percentUsed = (used / (beginningBalance + accrued)) * 100;

        return (
            <tr className="flex">
                <td style={{ width: 120 }} className="w-36 text-left text-sm font-bold">{t}</td>
                <td style={{ width: 150 }} className="w-36 text-left text-sm">{convertMinutesToTime(beginningBalance)}</td>
                <td style={{ width: 100 }} className="w-36 text-left text-sm">{convertMinutesToTime(accrued)}</td>
                <td style={{ width: 100 }} className="w-36 text-left text-sm">{convertMinutesToTime(used * -1)}</td>
                <td style={{ width: 150 }} className="w-36 text-left text-sm">{convertMinutesToTime(remainingBalance)}</td>
                {/* <td style={{ width: 150 }} className="w-36 text-left text-sm">{convertMinutesToWorkday((remainingBalance * -1), r.workday)}</td> */}
                <td style={{ width: 150 }} className="w-36 text-left text-sm">{parseFloat(percentUsed * -1).toFixed(0) + "%"}</td>
            </tr>
        )


    }


    const buildSummaryRow = () => {



        // FOreach leave type
        var row = leaveTypes.map((lt) => {

            var start = parseInt(sumBalanceStart(lt.leave_name_abbr))
            var accrued = parseInt(sumAccrued(lt.leave_name_abbr))
            var used = parseInt(sumUsed(lt.leave_name_abbr))
            var balance = computeBalanceEnd(start, accrued, used);

            console.log(balance)

            return (
                <tr>
                    <td>{lt.leave_name_abbr}</td>
                    <td>{convertMinutesToTime(start)}</td>
                    <td>{convertMinutesToTime(accrued)}</td>
                    <td>{convertMinutesToTime(used * -1)}</td>
                    <td>{convertMinutesToTime(balance)}</td>
                </tr>
            )
        })

        return row;

        // var accrued = getAccruedForType(r, t);
        // var used = getUsedForType(r, t);
        // var remainingBalance = computeBalanceEnd(beginningBalance, accrued, used);
        // //var percentUsed = (used / (beginningBalance + accrued)) * 100;

        // return (
        //     <tr className="flex">
        //         <td style={{ width: 120 }} className="w-36 text-left text-sm font-bold">{t}</td>
        //         <td style={{ width: 150 }} className="w-36 text-left text-sm">{convertMinutesToTime(beginningBalance)}</td>
        //         <td style={{ width: 100 }} className="w-36 text-left text-sm">{convertMinutesToTime(accrued)}</td>
        //         <td style={{ width: 100 }} className="w-36 text-left text-sm">{convertMinutesToTime(used * -1)}</td>
        //         <td style={{ width: 150 }} className="w-36 text-left text-sm">{convertMinutesToTime(remainingBalance)}</td>
        //         {/* <td style={{ width: 150 }} className="w-36 text-left text-sm">{convertMinutesToWorkday((remainingBalance * -1), r.workday)}</td> */}
        //         {/* <td style={{ width: 150 }} className="w-36 text-left text-sm">{parseFloat(percentUsed * -1).toFixed(0) + "%"}</td> */}
        //     </tr>
        // )


    }

    const buildEmployeeDetails = (r) => {
        return (
            <div>
                <b>{r.employeeName}</b><br />
                <span>{r.employeePosition}</span>
                <br />
                <span>{r.campus}</span>
                <br />
                <span>
                    {r.supervisor}</span>
                <br />
                <span>
                    Workday: {convertMinutesToTime(r.workday)}</span>
                <br />
                <span>
                    Contract: {r.contract}</span>



                {/* <br />
                <span>{moment(r.hired).format("MM/DD/YYYY")}</span> */}
                {/* <span>{r.employeeSupervosor}</span> */}
            </div>
        )
    }

    const reprocess = () => {
        doIt();
    }

    return (
        <main>
            <div className="max-w-7xl px-4 py-6 ">
                <h1 className="h1">Leave Summary Report</h1>

                {loading && (
                    <div className="flex justify-center py-12" >
                        <CircularProgress />
                    </div>
                )}

                {/* <button onClick={reprocess}>Reprocess</button> */}
                {data && !loading && balances && (
                    <>
                        <div className="-mx-4  ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                            <>

                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 300 }}></th>
                                            <th>
                                                <table className="divide-y divide-gray-300">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 120 }} className="w-36 text-left text-sm font-semibold text-white">Leave Type</th>
                                                            <th style={{ width: 150 }} className="w-36 text-left text-sm font-semibold text-white">Bal {balanceDateBegin} </th>
                                                            <th style={{ width: 100 }} className="w-36 text-left text-sm font-semibold text-white">Accrued</th>
                                                            <th style={{ width: 100 }} className="w-36 text-left text-sm font-semibold text-white">Used</th>
                                                            <th style={{ width: 150 }} className="w-36 text-left text-sm font-semibold text-white">Bal {balanceDateEnd}</th>
                                                            {/* <th style={{ width: 150 }} className="w-36 text-left text-sm font-semibold text-white">Bal Workdays</th> */}
                                                            <th style={{ width: 150 }} className="w-36 text-left text-sm font-semibold text-white">% Used</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                        </tr>
                                    {/* <tr>
                                <th scope="col" className="pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                                <th scope="col" className="pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Leave Type</th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Bal {balanceDateBegin} </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Accrued</th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Used</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{balanceDateEnd}</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">% Used</th>
                            </tr> */}
                                    </thead>
                                    <tbody>
                                        {data.map((r) => (
                                            <tr style={{ borderBottom: 'solid 1px #DDDDDD' }}>
                                                <td style={{ verticalAlign: 'top', padding: 10, width: 300 }}>{buildEmployeeDetails(r)}    DOB
                                HIRED
                                BENEFIT ID</td>
                                                <td style={{ verticalAlign: 'top', paddingBottom: 10, paddingTop: 10 }}>
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        {/* <thead>
                                                <tr>
                                                    <th scope="col" className="pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                                                    <th scope="col" className="pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Leave Type</th>
                                                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Bal {balanceDateBegin} </th>
                                                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Accrued</th>
                                                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Used</th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{balanceDateEnd}</th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">% Used</th>
                                                </tr>
                                            </thead> */}
                                                        <tbody>
                                                            {r.leaveTypes.map((t) => {
                                                                return buildRow(r, t)

                                                            })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                {/* <td className="relative py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <button type="button" className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white">Select<span className="sr-only">, Hobby</span></button>
                                    </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>



                            </>


                        </div>

                        <br />

                        <div className="w-2/3">
                            <h2 className='h2'>Leave Summary Aggregate Totals </h2>
                            <h3 className="h3">Number of Employees in Summary: {data.length}</h3>
                            <table className="min-w-full divide-y divide-gray-300 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                                <thead>
                                    <tr>
                                    <th className="text-left">Leave Type</th>
                                    <th className="text-left">Bal {balanceDateBegin}</th>
                                    <th className="text-left">Accrued</th>
                                    <th className="text-left">Used</th>
                                    <th className="text-left">{balanceDateEnd}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {buildSummaryRow()}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </main>
    );
}