import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../images/icons/logo.png'
import { get, post } from '../services/base-http';

export default function Nav() {

    const [calendarShow, setCalendarShow] = useState(false)
    const [data, setData] = useState()

    const [isEmployee, setIsEmployee] = useState(true)
    const [profile, setProfile] = useState()

    const navigate = useNavigate();

    useEffect(() => {
        // get("api/role/nav", {}).then((nav) => {
        //     setData(nav)
        // })

        get("api/my/profile", {}).then((profile) => {
            setProfile(profile)
        }); 

        get("api/role/mine", {}).then((roles) => {

            console.log(roles)
    
            if(roles[0] != 'EMPLOYEE') {
                setIsEmployee(false)
            }
          })
    }, [])

    const logout = () => {
        window.localStorage.removeItem("access-token")
        navigate("/")
    }

    return (
        <>
            <div className="logobar">
                <div className="flex">
                    {/* flex-shrink-0 */}
                    <div className="flex-grow" >
                        <img src="https://www.leavescheduler.com/demo/images/client/title_bar.gif" alt="USIntegration Leave Scheduler Online" width="368" height="60" border="0" />
                    </div>
                    <div className="text-white flex p-4" >
                        <div>{profile && profile.full_name}&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                        <a onClick={logout} title="Click here to log off" className='inline p-1' >
                            <img src="https://www.leavescheduler.com/demo/images/icons/page_deny.gif" alt="" className="w-4 h-4" />
                        </a>
                        <a onClick={logout} title="Click here to log off" className='inline cursor-pointer' style={{ color: '#fff' }} >logout</a>
                    </div>

                </div>

                <nav id="main-nav">

                    {isEmployee && (
                        <ul>
                        <li><a href="/my/calendar">School Calendar</a>
                        </li>
                        <li><a href="/my/benefits">Benefits & Policies</a>
                        </li>
                            <li ><a href="/my/leave">Tools</a>
                                <ul>
                                <li><a href="/my/profile"><img src="https://www.leavescheduler.com/demo/images/icons/icon_user.gif" alt="" height="16" width="16" className="inline" /> Edit My Account</a></li>

                                <li className="divider"><img src="https://www.leavescheduler.com/demo/images/spacer.gif" border="0" alt="" width="1" height="1" /></li>
                             
                                    <li><img src="https://www.leavescheduler.com/westtech/images/icons/icon_history.gif" alt="" className="inline" />
                                    <a href="/my/leave/request/history">Leave Request History</a></li>

                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/icon_history.gif" alt="" className="inline" />
                                <a href="/my/leave/history">Leave History</a></li>
                                <li className="divider"><img src="https://www.leavescheduler.com/demo/images/spacer.gif" border="0" alt="" width="1" height="1" /></li>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/icon_clock_add.gif" alt="" className="inline" />
                                    <a href="/my/leave/create"> Request Leave</a></li>

                                </ul>
                        </li>

                    </ul>
                    )}

                    

                    {!isEmployee && (
                        <ul>
                        <li><a href="/calendars">Calendars</a>
                            {/* <ul>
                                <li><a href="#">Sub-Item 1</a></li>
                                <li><a href="#">Sub-Item 2</a></li>
                                <li><a href="#">Sub-Item 3</a></li>
                            </ul> */}
                        </li>
                        <li><a href="/accounts">Accounts</a>
                            <ul>
                                <li>
                                    <img src="https://www.leavescheduler.com/demo/images/icons/list_users.gif" alt="" height="16" width="16" className="inline" />
                                    <a href="/accounts/employees">Employees</a>
                                </li>
                                <li><img src="https://www.leavescheduler.com/demo/images/icons/list_users_1.gif" alt="" height="16" width="16" className="inline" /> 
                                    <a href="/accounts/admins">Administrators</a>
                                </li>
                                <li className="divider"><img src="https://www.leavescheduler.com/demo/images/spacer.gif" border="0" alt="" width="1" height="1" /></li>

                                <li>
                                    <a href="/accounts/employee/create">
                                    <img src="https://www.leavescheduler.com/demo/images/icons/icon_user_add_1.gif" alt="" height="16" width="16" className="inline" />
                                    Add Employee</a></li>
                                <li className="divider"><img src="https://www.leavescheduler.com/demo/images/spacer.gif" border="0" alt="" width="1" height="1" /></li>
                                <li><a href="/accounts/admin/create"><img src="https://www.leavescheduler.com/demo/images/icons/icon_user_add_1.gif" alt="" height="16" width="16" className="inline" /> Add Administrator</a></li>
                                <li className="divider"><img src="https://www.leavescheduler.com/demo/images/spacer.gif" border="0" alt="" width="1" height="1" /></li>
                                {/* <li><a href="/account/admin/create?type=1"><img src="https://www.leavescheduler.com/demo/images/icons/icon_user.gif" alt="" height="16" width="16" className="inline" /> Add Supervisor</a></li> */}
                                {/* <li className="divider"><img src="https://www.leavescheduler.com/demo/images/spacer.gif" border="0" alt="" width="1" height="1" /></li> */}
                                <li><a href="/account/profile"><img src="https://www.leavescheduler.com/demo/images/icons/icon_user.gif" alt="" height="16" width="16" className="inline" /> Edit My Account</a></li>
                            </ul>
                        </li>
                        <li ><a href="/config">System Setup</a>
                            <ul>
                            <li><a href="/calendars"><img src="https://www.leavescheduler.com/westtech/images/icons/calendar.gif" alt="" className="inline" /> Edit Event Calendar</a></li>
                            <li><a href="/config/company-info"><img src="https://www.leavescheduler.com/westtech/images/icons/icon_info.gif" alt="" className="inline" /> Company Information</a></li>
                            <li><a href="/config/employees/import"><img src="https://www.leavescheduler.com/westtech/images/icons/page_boy.gif" alt="" className="inline" /> Import Employees</a></li>
                            <li><a href="/config/balances/import"><img src="https://www.leavescheduler.com/westtech/images/icons/page_boy.gif" alt="" className="inline" /> Import Balances</a></li>
                            </ul>
                            </li>
                            <li ><a href="/leave">Leave Tools</a>
                                <ul>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/list_clock.gif" alt="" className="inline" />
                                    <a href="/leave/pending"> Pending Requests</a></li>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/icon_clock_add.gif" alt="" className="inline" />
                                    <a href="/leave/create"> Create Request</a></li>
                                <li className="divider"><img src="https://www.leavescheduler.com/westtech/images/spacer.gif" border="0" alt="" width="1" height="1" /></li>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/calendar.gif" alt="" className="inline" />
                                    <a href="/calendar/leave"> Leave Calendar</a></li>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/icon_history.gif" alt="" className="inline" />
                                <a href="/leave/history"> Employee History</a></li>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/action_print.gif" alt="" className="inline" />
                                <a href="/reports"> Leave Reports</a></li>
                                <li className="divider"><img src="https://www.leavescheduler.com/westtech/images/spacer.gif" border="0" alt="" width="1" height="1" /></li>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/page_edit.gif" alt="" className="inline" />
                                <a href="/config/leave-types"> Edit Leave Types</a></li>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/page_new.gif" alt="" className="inline" />
                                <a href="/config/leave-type/add"> Add New Leave Type</a></li>
                                <li><img src="https://www.leavescheduler.com/westtech/images/icons/page_package.gif" alt="" className="inline" />
                                <a href="/config/contract-type/add"> Edit Contract Types</a></li>
                                </ul>
                                </li>

                    </ul>
                    )}
                    
                </nav>
                {/* 

                <div className="dropdown">
                    <button className="dropbtn">Calendars</button>
                    <div className="dropdown-content">
                        <a href="#">Event Calendar</a>
                        <a href="#">Link 2</a>
                        <a href="#">Link 3</a>
                    </div>

                    <button className="dropbtn">Accounts</button>
                    <div className="dropdown-content">
                        <a href="#">Event Calendar</a>
                        <a href="#">Link 2</a>
                        <a href="#">Link 3</a>
                    </div>

                    <button className="dropbtn">Setup</button>
                    <div className="dropdown-content">
                        <a href="#">Event Calendar</a>
                        <a href="#">Link 2</a>
                        <a href="#">Link 3</a>
                    </div>

                    <button className="dropbtn">Leave Tools</button>
                    <div className="dropdown-content">
                        <a href="#">Event Calendar</a>
                        <a href="#">Link 2</a>
                        <a href="#">Link 3</a>
                    </div>
                    
                </div>
               */}

                <nav className="hidden bg-blue-400">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                {/* <div className="flex-shrink-0">
                                    <img className="h-10" src={logo} alt="Your Company" />
                                </div> */}
                                <div className="hidden md:block">
                                    <div className="ml-10 flex items-baseline space-x-4">
                                        {/* <Popover className="relative">
                                            <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" onClick={() => showCalendarPopover()}>
                                                Calendar
                                                <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                                            </PopoverButton>

                                            {calendarShow && (
                                                <PopoverPanel
                                                    transition
                                                    className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                                >
                                                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                                        YAaaa
                                                    </div>
                                                </PopoverPanel>
                                            )}
                                        </Popover> */}

                                        <a href="/calendars" className="rounded-md px-3 py-2 text-sm font-medium text-white hover:bg-blue-500 hover:bg-opacity-75">Calendars</a>
                                        {/* <a href="/config" className="rounded-md px-3 py-2 text-sm font-medium text-white hover:bg-blue-500 hover:bg-opacity-75">System Setup</a> */}
                                        <a href="/accounts" className="rounded-md px-3 py-2 text-sm font-medium text-white hover:bg-blue-500 hover:bg-opacity-75">Accounts</a>
                                        <a href="/leave" className="rounded-md px-3 py-2 text-sm font-medium text-white hover:bg-blue-500 hover:bg-opacity-75">Leave Scheduler</a>
                                        <a href="/reports" className="rounded-md px-3 py-2 text-sm font-medium text-white hover:bg-blue-500 hover:bg-opacity-75">Reports</a>
                                        <a href="/config/leave-types" className="rounded-md px-3 py-2 text-sm font-medium text-white hover:bg-blue-500 hover:bg-opacity-75">Setup</a>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-4 flex items-center md:ml-6">
                                    {/* <button type="button" className="relative rounded-full bg-blue-800 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                        <span className="absolute -inset-1.5"></span>
                                        <span className="sr-only">View notifications</span>
                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                        </svg>
                                    </button> */}

                                    <button onClick={() => navigate("/settings")} type="button" className="relative rounded-full bg-blue-800 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                        <span className="absolute -inset-1.5"></span>
                                        <span className="sr-only">Settings</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>

                                    </button>

                                    <div className="relative ml-3">
                                        <div>
                                            <button type="button" className="relative flex max-w-xs items-center rounded-full bg-blue-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                                <span className="absolute -inset-1.5"></span>
                                                <span className="sr-only">Open user menu</span>
                                                <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                                            </button>
                                        </div>
                                        {/* 
                                        <!--
                                        Dropdown menu, show/hide based on menu state.

                                        Entering: "transition ease-out duration-100"
                                        From: "transform opacity-0 scale-95"
                                        To: "transform opacity-100 scale-100"
                                        Leaving: "transition ease-in duration-75"
                                        From: "transform opacity-100 scale-100"
                                        To: "transform opacity-0 scale-95"
                --> */}

                                        {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                                        {/*     
                                        <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                        
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-0">Your Profile</a>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-1">Settings</a>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Sign out</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="-mr-2 flex md:hidden">
                                {/* <!-- Mobile menu button --> */}
                                <button type="button" className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600" aria-controls="mobile-menu" aria-expanded="false">
                                    <span className="absolute -inset-0.5"></span>
                                    <span className="sr-only">Open main menu</span>
                                    {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
                                    <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                    {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
                                    <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="md:hidden" id="mobile-menu">
                        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                            <a href="#" className="block rounded-md bg-indigo-700 px-3 py-2 text-base font-medium text-white" aria-current="page">Calendars</a>
                            <a href="#" className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">Requests</a>
                            <a href="#" className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">Employees</a>
                            <a href="#" className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">Admins</a>
                            <a href="#" className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">Leave</a>
                        </div>
                        <div className="border-t border-indigo-700 pb-3 pt-4">
                            <div className="flex items-center px-5">
                                <div className="flex-shrink-0">
                                    <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-white">Tom Cook</div>
                                    <div className="text-sm font-medium text-indigo-300">tom@example.com</div>
                                </div>
                                <button type="button" className="relative ml-auto flex-shrink-0 rounded-full bg-blue-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                    <span className="absolute -inset-1.5"></span>
                                    <span className="sr-only">View notifications</span>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                    </svg>
                                </button>
                            </div>
                            <div className="mt-3 space-y-1 px-2">
                                <a href="#" className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">Your Profile</a>
                                <a href="#" className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">Settings</a>
                                <a href="#" className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">Sign out</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}