
// <!--
//   This example requires updating your template:

//   ```
//   <html className="h-full bg-gray-100">
//   <body className="h-full">
//   ```
// -->
import Nav from '../components/nav'
import { useEffect, useState } from 'react';
import useAxios from '../hooks/useAxios'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import Breadcrumbs from '../components/breadcrumbs';
import Tabs from '../components/tabs'
import { useForm, SubmitHandler } from "react-hook-form"
import moment from 'moment'
import { get, post } from '../services/base-http';

export default function Employees() {

    const { id } = useParams();
    const [data, setData] = useState();
    const [supervisors, setSupervisors] = useState([]);
    const [contractTypes, setContractTypes] = useState([]);
    const [campuses, setCampuses] = useState([]);
    
    const navigate = useNavigate();

    useEffect(() => {

        

        get("/api/leave/contract-types", {}).then((data) => {
            setContractTypes(data)

            get("/api/admin/list", {}).then((data) => {
                setSupervisors(data)
    
                get("/api/admin/campuses", {}).then((data) => {
                    setCampuses(data)
        
                    //if(id) {
                        get(`/api/employee/${id || 0}`, {}).then((data) => {
                            setData(data)
                        })
                    //}
                    
                })
        
            })
    
        })

       

      
    }, []);

    useEffect(() => {

    }, [contractTypes, supervisors, campuses])

    const cancel = () => {
        navigate(`/accounts/employees`)
    }
    const reset = () => {
        window.location.reload();
    }


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {

        console.log(data);

        post("/api/employee/save", {
            body: data
        }).then((data) => {
            
            navigate(`/accounts/employees`)

        }).catch(e => {
            alert('Failed')
        })
    }

    const getLunchHours = (data) => {
        return Math.round(data / 60);
    }
    const getLunchMinutes = (data) => {
        return (data % 60)
    }

    return (
        <div className="min-h-full">
            <main>
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">

                    <Breadcrumbs pages={[
                        { name: 'Accounts', href: '#', current: false },
                        { name: 'Employees', href: '#', current: false },
                        { name: 'Add Employee', href: '#', current: true },
                    ]} />

                    {data && (

                        <div className="-mx-4 mt-6 sm:mx-0">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="">

                                    <h2 className="h4">Personal Information</h2>
                                    <div className="-mx-4 mt-2 bg-gray-200 p-1 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">


                                        {/* <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                                        <input {...register("id")} defaultValue={data.id} type="hidden" name="id" />

                                        <table className="dsR3" border="0" cellSpacing="0" cellPadding="4">
                                            <tbody>
                                                <tr align="left">
                                                    <td className="" >
                                                        <label id="l1">First Name, MI:</label>
                                                    </td>
                                                    <td className="" width="300">
                                                        <input {...register("emp_fname")} defaultValue={data.emp_fname} className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            type="text" name="emp_fname" size="20" maxLength="30" tabIndex="1" />&nbsp;&nbsp;
                                                        <input {...register("emp_mi")} defaultValue={data.emp_mi} className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text" name="emp_mi" size="2" maxLength="2" tabIndex="2" />
                                                    </td>
                                                    <td className="" ><label>Address 1:</label></td>
                                                    <td className=""><input {...register("emp_address1")} defaultValue={data.emp_address1} className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text" name="emp_address1" size="35" maxLength="50" tabIndex="11" /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" ><label id="l2">Last Name:</label></td>
                                                    <td width="300"><input {...register("emp_lname")} defaultValue={data.emp_lname} className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text" name="emp_lname" size="24" maxLength="35" tabIndex="3" /></td>
                                                    <td className="" ><label>Address 2:</label></td>
                                                    <td><input {...register("emp_address2")} defaultValue={data.emp_address2} className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text" name="emp_address2" size="35" maxLength="50" tabIndex="12" /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" ><label id="l4">Gender:</label></td>
                                                    <td className="" width="300">
                                                        <input type="radio" name="emp_gender" {...register("emp_gender")} checked={data.emp_gender == "M"} defaultValue={data.emp_gender} id="genderM" value="M" tabIndex="4" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                        <label htmlFor="genderM" className="ml-1 mr-7 inline text-sm font-medium leading-6 text-gray-900">Male</label>
                                                        <input type="radio" name="emp_gender" {...register("emp_gender")}  checked={data.emp_gender == "F"}  defaultValue={data.emp_gender} id="genderF" value="F" tabIndex="5" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                        <label htmlFor="genderF" className="ml-1 inline text-sm font-medium leading-6 text-gray-900">Female</label></td>
                                                    <td className="" ><label>City, State, Zip:</label></td>
                                                    <td className="">
                                                        <input className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            {...register("emp_city")} defaultValue={data.emp_city}
                                                            type="text" name="emp_city" size="9" maxLength="35" tabIndex="13" />

                                                        <select name="emp_state" size="1"
                                                            {...register("emp_state")} defaultValue={data.emp_state}
                                                            tabIndex="14" className="rounded-md border-0 px-1 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="AK">AK</option>
                                                            <option value="AL">AL</option>
                                                            <option value="AR">AR</option>
                                                            <option value="AZ">AZ</option>
                                                            <option value="CA">CA</option>
                                                            <option value="CO">CO</option>
                                                            <option value="CT">CT</option>
                                                            <option value="DC">DC</option>
                                                            <option value="DE">DE</option>
                                                            <option value="FL">FL</option>
                                                            <option value="GA">GA</option>
                                                            <option value="HI">HI</option>
                                                            <option value="IA">IA</option>
                                                            <option value="ID">ID</option>
                                                            <option value="IL">IL</option>
                                                            <option value="IN">IN</option>
                                                            <option value="KS">KS</option>
                                                            <option value="KY">KY</option>
                                                            <option value="LA">LA</option>
                                                            <option value="MA">MA</option>
                                                            <option value="MD">MD</option>
                                                            <option value="ME">ME</option>
                                                            <option value="MI">MI</option>
                                                            <option value="MN">MN</option>
                                                            <option value="MO">MO</option>
                                                            <option value="MS">MS</option>
                                                            <option value="MT">MT</option>
                                                            <option value="NC">NC</option>
                                                            <option value="ND">ND</option>
                                                            <option value="NE">NE</option>
                                                            <option value="NH">NH</option>
                                                            <option value="NJ">NJ</option>
                                                            <option value="NM">NM</option>
                                                            <option value="NV">NV</option>
                                                            <option value="NY">NY</option>
                                                            <option value="OH">OH</option>
                                                            <option value="OK">OK</option>
                                                            <option value="OR">OR</option>
                                                            <option value="PA">PA</option>
                                                            <option value="RI">RI</option>
                                                            <option value="SC">SC</option>
                                                            <option value="SD">SD</option>
                                                            <option value="TN">TN</option>
                                                            <option value="TX">TX</option>
                                                            <option value="UT">UT</option>
                                                            <option value="VA">VA</option>
                                                            <option value="VT">VT</option>
                                                            <option value="WA">WA</option>
                                                            <option value="WI">WI</option>
                                                            <option value="WV">WV</option>
                                                            <option value="WY">WY</option>
                                                        </select>
                                                        <input className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            {...register("emp_zip")} defaultValue={data.emp_zip}
                                                            type="text" name="emp_zip" size="6" maxLength="10" tabIndex="15" /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" ><label id="l4">E-mail:</label></td>
                                                    <td width="300">
                                                        <input className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text"
                                                            {...register("emp_email1")} defaultValue={data.emp_email1}
                                                            name="emp_email1" size="24" maxLength="80" tabIndex="6" /></td>
                                                    <td className="" ><label>Certificate 1:</label></td>
                                                    <td><input type="text"
                                                        className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        {...register("emp_certificate1")}
                                                        defaultValue={data.emp_certificate1}
                                                        name="emp_certificate1" size="18" maxLength="50" tabIndex="16" />
                                                        <label>Expires:</label>
                                                        <input type="text"
                                                            defaultValue={data.emp_certExpires1}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            {...register("emp_certExpires1")}
                                                            name="emp_certExpires1" size="10" maxLength="10" tabIndex="17" /><br /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" ><label id="l3">Home Phone:</label></td>
                                                    <td className="" width="300">
                                                        <input {...register("emp_phone_hm")}
                                                            defaultValue={data.emp_phone_hm}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            type="text" name="emp_phone_hm" size="24" maxLength="20" tabIndex="7" /></td>
                                                    <td className="" ><label>Certificate 2:</label></td>
                                                    <td className=""><input type="text" {...register("emp_certificate2")} defaultValue={data.emp_certificate2}
                                                        className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" name="emp_certificate2" size="18" maxLength="50" tabIndex="18" /> <label>Expires:</label>
                                                        <input type="text"
                                                            {...register("emp_certExpires2")}
                                                            defaultValue={data.emp_certExpires2}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            name="emp_certExpires2" size="10" maxLength="10" tabIndex="19" /><br /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" ><label>Work Phone, Ext:</label></td>
                                                    <td width="300">
                                                        <input   {...register("emp_phone_wk")}
                                                            defaultValue={data.emp_phone_wk}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text"
                                                            name="emp_phone_wk" size="15" maxLength="20" tabIndex="8" />

                                                        <input {...register("emp_phone_wk_ext")}
                                                            defaultValue={data.emp_phone_wk_ext}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            type="text" name="emp_phone_wk_ext" size="6" maxLength="6" tabIndex="9" /></td>
                                                    <td className="" >
                                                        {/* <label>Certificate 3:</label> */}
                                                    </td>
                                                    <td>
                                                        {/* <input type="text" className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" name="emp_certificate3" size="18" maxLength="50"  tabIndex="20" /> 
                                                    <label>Expires:</label>
                                                    <input type="text" className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" name="emp_certExpires3" size="10" maxLength="10"  tabIndex="21" /> */}
                                                        <br /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" ><label>Cell Phone:</label></td>
                                                    <td className="" width="300">
                                                        <input  {...register("emp_phone_cell")}
                                                            defaultValue={data.emp_phone_cell}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            type="text" name="emp_phone_cell" size="24" maxLength="20" tabIndex="10" />
                                                    </td>
                                                    <td className="" >
                                                        {/* <label>Certificate 4:</label> */}
                                                    </td>
                                                    <td className="">
                                                        {/* <input type="text" 
                                                className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                                name="emp_certificate4" size="18" maxLength="50"  tabIndex="22" /> */}

                                                        {/* <label>Expires:</label> 
                                                    <input type="text" className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                                    name="emp_certExpires4" size="10" maxLength="10"  tabIndex="23" /><br />
                                                     */}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>

                                    <br />
                                    <h2 className="h4">Account Information</h2>

                                    <div className="-mx-4 mt-2 bg-gray-200 p-1 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">


                                        <table className="dsR3" border="0" cellSpacing="0" cellPadding="4">
                                            <tbody><tr align="left">
                                                <td className="" width="110"><label>Supervisor:</label></td>
                                                <td width="300">
                                                    <select name="accountAdministratorId" tabIndex="35"
                                                        {...register("accountAdministratorId")}
                                                        defaultValue={data.accountAdministratorId + ""}
                                                        className="rounded-md border-0 px-1 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                        {supervisors && supervisors.map((s) => {
                                                            return (
                                                                <option selected={data.accountAdministratorId == s.id} value={s.id + ""}>{s.full_name} ({s.admin_title})</option>
                                                            )
                                                        })}
                                                        {/* <option value="4">Test Director (director)</option>
                                                <option value="20">Janice Williams</option> */}
                                                    </select>
                                                </td>
                                                <td className="" width="110"><label id="id_workday_start">Workday Start:</label></td>
                                                <td>
                                                    <select name="emp_workday_start" size="1" tabIndex="42"
                                                        {...register("emp_workday_start")}
                                                        defaultValue={data.emp_workday_start}
                                                        className="rounded-md border-0 py-1 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">

                                                        <option value="00:00">12:00 AM</option>
                                                        <option value="00:15">12:15 AM</option>
                                                        <option value="00:30">12:30 AM</option>
                                                        <option value="00:45">12:45 AM</option>
                                                        <option value="01:00">1:00 AM</option>
                                                        <option value="01:15">1:15 AM</option>
                                                        <option value="01:30">1:30 AM</option>
                                                        <option value="01:45">1:45 AM</option>
                                                        <option value="02:00">2:00 AM</option>
                                                        <option value="02:15">2:15 AM</option>
                                                        <option value="02:30">2:30 AM</option>
                                                        <option value="02:45">2:45 AM</option>
                                                        <option value="03:00">3:00 AM</option>
                                                        <option value="03:15">3:15 AM</option>
                                                        <option value="03:30">3:30 AM</option>
                                                        <option value="03:45">3:45 AM</option>
                                                        <option value="04:00">4:00 AM</option>
                                                        <option value="04:15">4:15 AM</option>
                                                        <option value="04:30">4:30 AM</option>
                                                        <option value="04:45">4:45 AM</option>
                                                        <option value="05:00">5:00 AM</option>
                                                        <option value="05:15">5:15 AM</option>
                                                        <option value="05:30">5:30 AM</option>
                                                        <option value="05:45">5:45 AM</option>
                                                        <option value="06:00">6:00 AM</option>
                                                        <option value="06:15">6:15 AM</option>
                                                        <option value="06:30">6:30 AM</option>
                                                        <option value="06:45">6:45 AM</option>
                                                        <option value="07:00">7:00 AM</option>
                                                        <option value="07:15">7:15 AM</option>
                                                        <option value="07:30">7:30 AM</option>
                                                        <option value="07:45">7:45 AM</option>
                                                        <option value="07:50">7:50 AM</option>
                                                        <option value="08:00">8:00 AM</option>
                                                        <option value="08:15">8:15 AM</option>
                                                        <option value="08:30">8:30 AM</option>
                                                        <option value="08:45">8:45 AM</option>
                                                        <option value="09:00">9:00 AM</option>
                                                        <option value="09:15">9:15 AM</option>
                                                        <option value="09:30">9:30 AM</option>
                                                        <option value="09:45">9:45 AM</option>
                                                        <option value="10:00">10:00 AM</option>
                                                        <option value="10:15">10:15 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="10:45">10:45 AM</option>
                                                        <option value="11:00">11:00 AM</option>
                                                        <option value="11:15">11:15 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="11:45">11:45 AM</option>
                                                        <option value="12:00">12:00 PM</option>
                                                        <option value="12:15">12:15 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="12:45">12:45 PM</option>
                                                        <option value="13:00">1:00 PM</option>
                                                        <option value="13:15">1:15 PM</option>
                                                        <option value="13:30">1:30 PM</option>
                                                        <option value="13:45">1:45 PM</option>
                                                        <option value="14:00">2:00 PM</option>
                                                        <option value="14:15">2:15 PM</option>
                                                        <option value="14:30">2:30 PM</option>
                                                        <option value="14:45">2:45 PM</option>
                                                        <option value="15:00">3:00 PM</option>
                                                        <option value="15:15">3:15 PM</option>
                                                        <option value="15:30">3:30 PM</option>
                                                        <option value="15:45">3:45 PM</option>
                                                        <option value="16:00">4:00 PM</option>
                                                        <option value="16:15">4:15 PM</option>
                                                        <option value="16:30">4:30 PM</option>
                                                        <option value="16:45">4:45 PM</option>
                                                        <option value="17:00">5:00 PM</option>
                                                        <option value="17:15">5:15 PM</option>
                                                        <option value="17:30">5:30 PM</option>
                                                        <option value="17:45">5:45 PM</option>
                                                        <option value="18:00">6:00 PM</option>
                                                        <option value="18:15">6:15 PM</option>
                                                        <option value="18:30">6:30 PM</option>
                                                        <option value="18:45">6:45 PM</option>
                                                        <option value="19:00">7:00 PM</option>
                                                        <option value="19:15">7:15 PM</option>
                                                        <option value="19:30">7:30 PM</option>
                                                        <option value="19:45">7:45 PM</option>
                                                        <option value="20:00">8:00 PM</option>
                                                        <option value="20:15">8:15 PM</option>
                                                        <option value="20:30">8:30 PM</option>
                                                        <option value="20:45">8:45 PM</option>
                                                        <option value="21:00">9:00 PM</option>
                                                        <option value="21:15">9:15 PM</option>
                                                        <option value="21:30">9:30 PM</option>
                                                        <option value="21:45">9:45 PM</option>
                                                        <option value="22:00">10:00 PM</option>
                                                        <option value="22:15">10:15 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="22:45">10:45 PM</option>
                                                        <option value="23:00">11:00 PM</option>
                                                        <option value="23:15">11:15 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                        <option value="23:45">11:45 PM</option>
                                                    </select> &nbsp;<input type="checkbox"
                                                        {...register("emp_workday_variable")}
                                                        defaultValue={data.emp_workday_variable}
                                                        name="emp_workday_variable" value="1" tabIndex="43" /><label >Variable*</label></td>
                                            </tr>
                                                <tr align="left">
                                                    <td className="" width="110"><label>Position:</label></td>
                                                    <td className="" width="300">
                                                        <input
                                                            {...register("emp_position")}
                                                            defaultValue={data.emp_position}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            type="text" name="emp_position" size="24" maxLength="50" tabIndex="36" /></td>
                                                    <td className="" width="110"><label>Workday Length:</label></td>
                                                    <td className=""><input type="text" name="emp_workday_hours" {...register("emp_workday_hours")} defaultValue={data.emp_workday_hours} className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" size="2" maxLength="2" tabIndex="44" value="8" /><label>:</label>
                                                        <input type="text" name="emp_workday_minutes" {...register("emp_workday_minutes")} defaultValue={data.emp_workday_minutes}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" size="2" maxLength="2" tabIndex="45" value="00" /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" width="110"><label>Location/Campus:</label></td>
                                                    <td width="300">
                                                        <select name="campus" size="1" tabIndex="22"
                                                            {...register("companyInfoId")}
                                                            defaultValue={data.companyInfoId + ""}
                                                            className="rounded-md border-0 px-1  py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                           
                                                              {campuses && campuses.map((c) => {
                                                                return (
                                                                    <option selected={data.companyInfoId == c.id} value={c.id + ""}>{c.company_name}</option>
                                                                )
                                                            })}
                                                        </select></td>
                                                    <td className="" width="110"><label id="id_lunch_start">Lunch Start:</label></td>
                                                    <td>
                                                        <select name="emp_lunch_start"
                                                        {...register("emp_lunch_start")} defaultValue={data.emp_lunch_start}
                                                        size="1" className="rounded-md border-0 px-1 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" tabIndex="46">

                                                        <option value="00:00">12:00 AM</option>
                                                        <option value="00:15">12:15 AM</option>
                                                        <option value="00:30">12:30 AM</option>
                                                        <option value="00:45">12:45 AM</option>
                                                        <option value="01:00">1:00 AM</option>
                                                        <option value="01:15">1:15 AM</option>
                                                        <option value="01:30">1:30 AM</option>
                                                        <option value="01:45">1:45 AM</option>
                                                        <option value="02:00">2:00 AM</option>
                                                        <option value="02:15">2:15 AM</option>
                                                        <option value="02:30">2:30 AM</option>
                                                        <option value="02:45">2:45 AM</option>
                                                        <option value="03:00">3:00 AM</option>
                                                        <option value="03:15">3:15 AM</option>
                                                        <option value="03:30">3:30 AM</option>
                                                        <option value="03:45">3:45 AM</option>
                                                        <option value="04:00">4:00 AM</option>
                                                        <option value="04:15">4:15 AM</option>
                                                        <option value="04:30">4:30 AM</option>
                                                        <option value="04:45">4:45 AM</option>
                                                        <option value="05:00">5:00 AM</option>
                                                        <option value="05:15">5:15 AM</option>
                                                        <option value="05:30">5:30 AM</option>
                                                        <option value="05:45">5:45 AM</option>
                                                        <option value="06:00">6:00 AM</option>
                                                        <option value="06:15">6:15 AM</option>
                                                        <option value="06:30">6:30 AM</option>
                                                        <option value="06:45">6:45 AM</option>
                                                        <option value="07:00">7:00 AM</option>
                                                        <option value="07:15">7:15 AM</option>
                                                        <option value="07:30">7:30 AM</option>
                                                        <option value="07:45">7:45 AM</option>
                                                        <option value="08:00">8:00 AM</option>
                                                        <option value="08:15">8:15 AM</option>
                                                        <option value="08:30">8:30 AM</option>
                                                        <option value="08:45">8:45 AM</option>
                                                        <option value="09:00">9:00 AM</option>
                                                        <option value="09:15">9:15 AM</option>
                                                        <option value="09:30">9:30 AM</option>
                                                        <option value="09:45">9:45 AM</option>
                                                        <option value="10:00">10:00 AM</option>
                                                        <option value="10:15">10:15 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="10:45">10:45 AM</option>
                                                        <option value="11:00">11:00 AM</option>
                                                        <option value="11:15">11:15 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="11:45">11:45 AM</option>
                                                        <option value="12:00">12:00 PM</option>
                                                        <option value="12:15">12:15 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="12:45">12:45 PM</option>
                                                        <option value="13:00">1:00 PM</option>
                                                        <option value="13:15">1:15 PM</option>
                                                        <option value="13:30">1:30 PM</option>
                                                        <option value="13:45">1:45 PM</option>
                                                        <option value="14:00">2:00 PM</option>
                                                        <option value="14:15">2:15 PM</option>
                                                        <option value="14:30">2:30 PM</option>
                                                        <option value="14:45">2:45 PM</option>
                                                        <option value="15:00">3:00 PM</option>
                                                        <option value="15:15">3:15 PM</option>
                                                        <option value="15:30">3:30 PM</option>
                                                        <option value="15:45">3:45 PM</option>
                                                        <option value="16:00">4:00 PM</option>
                                                        <option value="16:15">4:15 PM</option>
                                                        <option value="16:30">4:30 PM</option>
                                                        <option value="16:45">4:45 PM</option>
                                                        <option value="17:00">5:00 PM</option>
                                                        <option value="17:15">5:15 PM</option>
                                                        <option value="17:30">5:30 PM</option>
                                                        <option value="17:45">5:45 PM</option>
                                                        <option value="18:00">6:00 PM</option>
                                                        <option value="18:15">6:15 PM</option>
                                                        <option value="18:30">6:30 PM</option>
                                                        <option value="18:45">6:45 PM</option>
                                                        <option value="19:00">7:00 PM</option>
                                                        <option value="19:15">7:15 PM</option>
                                                        <option value="19:30">7:30 PM</option>
                                                        <option value="19:45">7:45 PM</option>
                                                        <option value="20:00">8:00 PM</option>
                                                        <option value="20:15">8:15 PM</option>
                                                        <option value="20:30">8:30 PM</option>
                                                        <option value="20:45">8:45 PM</option>
                                                        <option value="21:00">9:00 PM</option>
                                                        <option value="21:15">9:15 PM</option>
                                                        <option value="21:30">9:30 PM</option>
                                                        <option value="21:45">9:45 PM</option>
                                                        <option value="22:00">10:00 PM</option>
                                                        <option value="22:15">10:15 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="22:45">10:45 PM</option>
                                                        <option value="23:00">11:00 PM</option>
                                                        <option value="23:15">11:15 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                        <option value="23:45">11:45 PM</option>
                                                    </select></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" width="110"><label>Contract Type:</label></td>
                                                    <td className="" width="300">
                                                        <select name="contractType_id"
                                                            {...register("contractTypeId")}
                                                            defaultValue={data.contractTypeId + ""}
                                                            size="1" className="rounded-md border-0 py-1 px-1 py-1  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" tabIndex="37">
                                                            {contractTypes && contractTypes.map((ct) => {
                                                                return (
                                                                    <option selected={data.contractTypeId == ct.id} value={ct.id + ""}>{ct.contract_name}</option>
                                                                )
                                                            })}
                                                            {/* <option value="1">10 Month (exempt)</option>
                                                    <option value="8">10 Month (non-exempt)</option>
                                                    <option value="2">11 Month (exempt)</option> */}

                                                        </select> </td>
                                                    <td className="" width="110"><label>Lunch Length:</label></td>
                                                    <td className="">
                                                        <input type="text" name="emp_lunch_hours"
                                                            {...register("emp_lunch_hours")}
                                                            defaultValue={getLunchHours(data.emp_lunch)}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" size="2" maxLength="2" tabIndex="47" /><label>:</label>
                                                        <input type="text"
                                                            {...register("emp_lunch_minutes")}
                                                            defaultValue={getLunchMinutes(data.emp_lunch)}
                                                            name="emp_lunch_minutes" className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" size="2" maxLength="2" tabIndex="48" /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" width="110"><label>Employee No:</label></td>
                                                    <td width="300">
                                                        <input
                                                            {...register("emp_number")}
                                                            defaultValue={data.emp_number}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            type="text" name="emp_number" size="24" maxLength="50" tabIndex="38" /></td>
                                                    <td className="" width="110"><label>Date Hired:</label></td>
                                                    <td>

                                                    

                                                    <input  {...register("emp_dateHired")} defaultValue={moment(data.emp_dateHired).format("YYYY-MM-DD")}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                                            placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            name="emp_dateHired" tabIndex="49" />

                                                        {/* <input type="text"  {...register("hired_mo")} defaultValue={data.hired_mo}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            name="hired_mo" size="2" maxLength="2" tabIndex="49" />/

                                                        <input type="text"  {...register("hired_day")} defaultValue={data.hired_day}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" name="hired_day" tabIndex="50" size="2" maxLength="2" />/

                                                        <input type="text"
                                                            {...register("hired_yr")}
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            tabIndex="51" name="hired_yr" size="4" maxLength="4" /><br /> */}
                                                    </td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" width="110"><label>Login ID (unique):</label></td>
                                                    <td className="" width="300"><input
                                                        {...register("emp_login")} defaultValue={data.emp_login}
                                                        className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        type="text" name="emp_login" size="24" maxLength="20" tabIndex="39" /></td>
                                                    <td className="" width="110"><label>Termination Date:</label></td>
                                                    <td className=""><div className="bodycopy">N/A</div></td>
                                                </tr>
                                                <tr align="left">
                                                    <td className="" width="110"><label>Password:</label></td>
                                                    <td width="300"><input
                                                        {...register("emp_pw")}
                                                        className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="password"
                                                        name="emp_pw" size="10" maxLength="20" tabIndex="40" /> <label>Repeat PW:</label>
                                                        <input
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="password"
                                                            {...register("emp_pw2")}
                                                            name="emp_pw2" size="10" maxLength="20" tabIndex="41" /></td>
                                                    <td className="" width="110"><label>Status:</label></td>
                                                    <td>
                                                        <select name="emp_status"
                                                            {...register("emp_status")} defaultValue={data.emp_status}
                                                            size="1" tabIndex="52" className="rounded-md border-0 px-1 py-1  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="1">Actively Employed</option>
                                                            <option value="2">Employed but Inactive</option>

                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody></table>


                                    </div>
                                    {/* 
                                <div className="border-b border-gray-900/10 pb-12">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Notifications</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        We'll always let you know about important changes, but you pick what else you want to hear about.
                                    </p>

                                    <div className="mt-10 space-y-10">
                                        <fieldset>
                                            <legend className="text-sm font-semibold leading-6 text-gray-900">By Email</legend>
                                            <div className="mt-6 space-y-6">
                                                <div className="relative flex gap-x-3">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="comments"
                                                            name="comments"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="comments" className="font-medium text-gray-900">
                                                            Comments
                                                        </label>
                                                        <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                                                    </div>
                                                </div>
                                                <div className="relative flex gap-x-3">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="candidates"
                                                            name="candidates"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="candidates" className="font-medium text-gray-900">
                                                            Candidates
                                                        </label>
                                                        <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                                                    </div>
                                                </div>
                                                <div className="relative flex gap-x-3">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="offers"
                                                            name="offers"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="offers" className="font-medium text-gray-900">
                                                            Offers
                                                        </label>
                                                        <p className="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend className="text-sm font-semibold leading-6 text-gray-900">Push Notifications</legend>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">These are delivered via SMS to your mobile phone.</p>
                                            <div className="mt-6 space-y-6">
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="push-everything"
                                                        name="push-notifications"
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                    <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Everything
                                                    </label>
                                                </div>
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="push-email"
                                                        name="push-notifications"
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                    <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Same as email
                                                    </label>
                                                </div>
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="push-nothing"
                                                        name="push-notifications"
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                    <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-gray-900">
                                                        No push notifications
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div> */}
                                </div>

                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button type="button"
                                        onClick={reset}
                                        className="text-sm font-semibold leading-6 text-gray-900 bg-gray-200 rounded-md px-3 py-2">

                                        Reset
                                    </button>

                                    <button type="button"
                                        onClick={cancel}
                                        className="text-sm font-semibold leading-6 text-gray-900 bg-gray-200 rounded-md px-3 py-2">
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white 
                                    shadow-sm hover:bg-indigo-500 focus-visible:outline 
                                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>

                        </div>

                    )}
                </div>
            </main>
        </div>
    )
}