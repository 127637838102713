import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form"
import { get, post } from '../services/base-http';

export default function Profile() {

    const [data, setData] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        get("/api/my/user", {}).then((data) => {
            setData(data)
        })
    }, []);

    const cancel = () => {
        navigate(-1)
    }
    const reset = () => {
        window.location.reload();
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {

        post("/api/my/profile/update", {
            body: data
        }).then(() => {
            alert('Save successful')
        }).catch(e => {
            alert('Failed')
        })
    }

    return (
        <div className="min-h-full">
            <main>
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    {data && (
                        <div className="-mx-4 mt-6 sm:mx-0">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="">
                                    {/* <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}
                                    <input {...register("id")} defaultValue={data.id} type="hidden" name="id" />
                                    <h2 className="h2">Edit Account Information</h2>
                                    <br />
                                    <div className="-mx-4 w-1/3 mt-2 p-4 bg-gray-200 p-1 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                                        <table className="dsR3" border="0" cellSpacing="0" cellPadding="4">
                                            <tbody>
                                                <tr >
                                                    <td className="" ><label>E-mail</label></td>
                                                    <td className="" width="300"><input
                                                        {...register("email")} defaultValue={data.email}
                                                        className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        type="text" name="emp_email1" size="24" maxLength="20" tabIndex="39" />

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>  <p className="text-sm">
                                                        Note: please make sure your E-mail address is correct and up to date. All correspondence from this application will be sent to this address.</p>
                                                        <br /></td>
                                                </tr>
                                                <tr >
                                                    <td className="" ><label>Login ID</label></td>
                                                    <td className="" width="300"><input
                                                        {...register("username")} defaultValue={data.username}
                                                        className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        type="text" name="username" size="24" maxLength="20" tabIndex="39" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <br /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <b>Password Reset</b> (optional)</td>
                                                </tr>
                                                <tr >
                                                    <td className="pt-2" width="200"><label>Current Password:</label></td>
                                                    <td className="pt-2" width="300"><input
                                                        {...register("password")}
                                                        className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="password"
                                                        name="password" size="20" maxLength="50" tabIndex="40" />
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td className="" width="200"><label>New Password:</label></td>
                                                    <td width="300"><input
                                                        {...register("password")}
                                                        className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="password"
                                                        name="password" size="20" maxLength="50" tabIndex="40" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label>Repeat New PW:</label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="password"
                                                            {...register("password2")}
                                                            name="password2" size="20" maxLength="50" tabIndex="41" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>  <p className="mt-2 text-sm">
                                                        If you wish to choose a new password, provide an alphanumeric password from 6 to 20 characters, otherwise, leave this field blank.
                                                        <br /></p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <hr />
                                    {/* 
                                <div className="border-b border-gray-900/10 pb-12">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Notifications</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        We'll always let you know about important changes, but you pick what else you want to hear about.
                                    </p>

                                    <div className="mt-10 space-y-10">
                                        <fieldset>
                                            <legend className="text-sm font-semibold leading-6 text-gray-900">By Email</legend>
                                            <div className="mt-6 space-y-6">
                                                <div className="relative flex gap-x-3">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="comments"
                                                            name="comments"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="comments" className="font-medium text-gray-900">
                                                            Comments
                                                        </label>
                                                        <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                                                    </div>
                                                </div>
                                                <div className="relative flex gap-x-3">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="candidates"
                                                            name="candidates"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="candidates" className="font-medium text-gray-900">
                                                            Candidates
                                                        </label>
                                                        <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                                                    </div>
                                                </div>
                                                <div className="relative flex gap-x-3">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="offers"
                                                            name="offers"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="offers" className="font-medium text-gray-900">
                                                            Offers
                                                        </label>
                                                        <p className="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend className="text-sm font-semibold leading-6 text-gray-900">Push Notifications</legend>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">These are delivered via SMS to your mobile phone.</p>
                                            <div className="mt-6 space-y-6">
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="push-everything"
                                                        name="push-notifications"
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                    <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Everything
                                                    </label>
                                                </div>
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="push-email"
                                                        name="push-notifications"
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                    <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Same as email
                                                    </label>
                                                </div>
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="push-nothing"
                                                        name="push-notifications"
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                    <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-gray-900">
                                                        No push notifications
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div> */}

                                </div>

                                <div className="mt-6 flex items-center gap-x-6">
                                    <button type="button"
                                        onClick={reset}
                                        className="text-sm font-semibold leading-6 text-gray-900 bg-gray-200 rounded-md px-3 py-2">

                                        Reset
                                    </button>

                                    <button type="button"
                                        onClick={cancel}
                                        className="text-sm font-semibold leading-6 text-gray-900 bg-gray-200 rounded-md px-3 py-2">
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white 
                                    shadow-sm hover:bg-indigo-500 focus-visible:outline 
                                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>

                        </div>

                    )}
                </div>
            </main>
        </div>
    )
}