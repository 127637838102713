import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'
import historyIcon from '../images/icons/icon_history.gif'
import { get } from '../services/base-http';
import Moment from 'react-moment';
import useHelpers from '../util'
import LeaveRequestHistory from '../app_leave/request-history'

export default function Requests() {

    const { id } = useParams();

    const [data, setData] = useState();

    useEffect(() => {


        get(`/api/my/leave/request/history`, null).then((response) => {
            if (response !== null) {
                setData(response);
            }
        });


    }, []);


    return (
        <div className="min-h-full">

            <br />
            <main>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
                    <div className="flex">
                        <div className="flex-grow">
                            <Tabs tabs={[
                                { name: 'Leave History', href: `/my/leave/history`, current: false },
                                { name: 'Leave Request History', href: `/my/leave/request/history`, current: true },
                            ]} />
                        </div>
                    </div>
                    <div className="-mx-4">
                        {data && (
                            <LeaveRequestHistory data={data} allowClick={false} />
                        )}
                    </div>

                </div>
            </main>
        </div>
    );
}