
import Nav from '../components/nav'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Requests from '../app_leave/requests';
import ViewAdmin from '../app_accounts/view-admin';
import ViewEmployee from '../app_accounts/view-employee';
import Employees from '../app_accounts/employees';
import Administrators from '../app_accounts/administrators';
import LeaveHistory from '../app_leave/leave-history';
import LeaveRequestHistory from '../app_leave/request-history';

export default function Accounts() {

    return (
        <div className="mx-auto max-w-7xl bg-white">
            <Nav />
            {/* <Tabs tabs={[{ name: 'List Employee', href: '#', current: true }]} /> */}
            <Routes>
                <Route path="" element={<Employees />} />
                <Route path="employees" element={<Employees />} />
                <Route path="employee/:id/view" element={<ViewEmployee />}></Route>
                <Route path="employee/:id/leave/history" element={<LeaveHistory />}></Route>
                <Route path="employee/:id/leave-request/history" element={<LeaveRequestHistory />}></Route>
                <Route path="employee/create" element={<ViewEmployee />}></Route>
                <Route path="admins" element={<Administrators />}></Route>
                <Route path="admin/:id/view" element={<ViewAdmin />}></Route>
                <Route path="admin/create" element={<ViewAdmin />}></Route>
                
            </Routes>
            {/* <Route exact path="/equipment/:id/view" component={View} />
            <Route exact path="/equipment/:id/readonly" component={ReadOnly} />
            <Route exact path="/equipment/:id/images" component={Images} />
            <Route exact path="/equipment/qrscan" component={QrScan} />
            <Route exact path="/equipment/:id/qrcode" component={QrCode} /> */}
        </div>
    )
}