import { Link, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Moment from 'react-moment';
import moment from 'moment';
import redFlag from '../images/icons/flag_red.gif'
import greenFlag from '../images/icons/flag_green.gif'
import blueFlag from '../images/icons/flag_blue.gif'
import orangeFlag from '../images/icons/flag_orange.gif'
import pencilIcon from '../images/icons/page_edit.gif'

import useHelpers from '../util'



export default function Balance({ id, leaveTypeId }) {

    const { convertMinutesToTime, formatPriority, formatStatus } = useHelpers();
    
    const { response, loading, error } = useAxios({
        method: 'post',
        url: `/api/employee/leave/balance`,
        headers: JSON.stringify({ accept: 'application/json' }),
        body: JSON.stringify({
            id_employee : id,
            leaveType_id: leaveTypeId,
            //date_end 
            // date_start { get; set; }
            used_or_accrued: "used"
        })
    });

    const [data, setData] = useState();

    
    useEffect(() => {
        if (response !== null) {
            console.log(response)
            setData(response);
        }
    }, [response]);

    return (
        <>
            {/* if(accrual_method != "NONE") {
                    var current_balance = getLeaveTotal(rs.Fields("id_employee").Value, leaveType_id, now.toSQLstring());
                    var total_balance = getLeaveTotal(rs.Fields("id_employee").Value, leaveType_id);
            %><span class="h5">
            Current Balance: <%= convertMinutesToTime(current_balance) %><br />
            Total Balance: <%= convertMinutesToTime(total_balance) %></span>
            <br /><%	} %>

            <span class="h4">Total Hours Requested: <%= convertMinutesToTime(total_minutes) %></span> */}
            {data &&  (
                <div className="p-2">
            <h5 className="h5" >Current Balance: { convertMinutesToTime(data.currentBalance) } </h5>
            <h5 className="h5" >Total Balance: { convertMinutesToTime(data.totalBalance) } </h5>
            {/* Total Hours Requested: { convertMinutesToTime(totalMinutesRequested) }  <br /> */}
            </div>
            )}
        </>
    )
}
