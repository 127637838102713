import { Link, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
//import Tabs from '../components/tabs'
import historyIcon from '../images/icons/icon_history.gif'
import {get} from '../services/base-http';

export default function Requests() {
    const [pageParam, setPageParam] = useState(1);
    const [sortParam, setSortParam] = useState('lastname');
    const [nameParam, setNameParam] = useState('');
    const [showInactive, setShowInactive] = useState(false);

    const [searchFilter, setSearchFilter] = useState('');
    const [data, setData] = useState([]);
    const [dataFiltered, setDataFiltered] = useState([]);
    const [pageNumber, setPageNumber] = useState(pageParam);

    const pageSize = 20;
    const [totalPages, setTotalPages] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {

        get(`/api/employee/list?page=${pageNumber}&sort=${sortParam}&showInactive=${showInactive}`, null).then((response) => {

            if (response !== null) {

                setData(response);
            }
        });

    }, []);

    
    useEffect(() => {
        loadData(pageNumber)
    }, [pageNumber])

    const paging = (e) => {
        setPageNumber(e.target.value)

        loadData(e.target.value);
    }

    const loadData = (pageNumber) => {

        var tp = Math.round((data.length / pageSize));

        var tpv = (tp > 0 ? tp : 1);

        console.log(tpv);

        setTotalPages(tpv);

        var df = data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

        setDataFiltered(Object.assign([], df));
    }

    const returnPages = () => {
        var dayray = [];

        for(let i = 1; i <= totalPages; i++){
            dayray.push(i)
        }
        return dayray;
    }

    return (
        <div className="min-h-full">

           <br />
        <main>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            {/* <Tabs tabs={[
                { name: 'List Employee', href: '/accounts/employees', current: true },
                { name: 'List Admin', href: '/accounts/admins', current: false },
            ]} /> */}

            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">


                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col" className="pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name</th>
                            <th scope="col" className="hidden px-3 py-1.5 text-left text-sm font-semibold lg:table-cell">Position</th>
                            <th scope="col" className="hidden px-3 py-1.5 text-left text-sm font-semibold lg:table-cell">Location</th>
                            <th scope="col" className="hidden px-3 py-1.5 text-left text-sm font-semibold lg:table-cell">Supervisor</th>
                            <th scope="col" className="px-3 py-1.5 text-left text-sm font-semibold ">History</th>
                            {/* <th scope="col" className="relative py-1.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {dataFiltered && dataFiltered.map((e) => (
                            <tr className='hover:bg-sky-100' >
                            <td className="relative py-2 pl-4 pr-3 text-sm sm:pl-6">
                                <Link to={`/accounts/employee/${e.employeeId}/view`} >{e.employeeName}</Link>
                            </td>
                            <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{e.employeePosition}</td>
                            <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{e.campus}</td>
                            <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{e.supervisorName}</td>
                            <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">
                            <div className=''>
                                <img src={historyIcon} onClick={() => navigate(`/accounts/employee/${e.employeeId}/leave/history`)} className='inline' />
                                <a href="" className='ml-1' onClick={() => navigate(`/accounts/employee/${e.employeeId}/leave/history`)}>View History</a>
                                </div>
                                
                         
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                <br />
                <p className='text-xs text-gray-600 p-3'>No of records: {data.length}  |  Page  
                        <select name="status" value={pageNumber} 
                        onChange={paging}
                        className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        {returnPages().map((d) => {
                            return (<option selected={pageNumber==d} value={d}>{d}</option>)
                        })}
                    </select>{pageNumber} of {totalPages}</p>
            </div>
        </div>
        </main>
        </div>
    );
}