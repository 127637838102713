
// <!--
//   This example requires updating your template:

//   ```
//   <html className="h-full bg-gray-100">
//   <body className="h-full">
//   ```
// -->
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

import { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import Moment from 'react-moment';
import moment from 'moment';
import useAxios from '../hooks/useAxios'

import {get, post} from '../services/base-http';

import { useForm, SubmitHandler } from "react-hook-form"

import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'

import Breadcrumbs from '../components/breadcrumbs';
import Tabs from '../components/tabs';

import useHelpers from '../util'
export default function Calendar() {

    const [open, setOpen] = useState(false)

    const { convertMinutesToTime, formatStatus } = useHelpers();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();


    const onSubmit = (data) => {

        console.log(data)

    };

    const [years, setYears] = useState([]);

    const [currentDate, setCurrentDate] = useState(moment().format('DD/MM/YYYY'));
    const [selectedDate, setSelectedDate] = useState(moment());
    const [weeks, setWeeks] = useState([]);

    const [days, setDays] = useState([]);

    const [calendar, setCalendar] = useState([]);

    const [selectedStartWeek, setSelectedStartWeek] = useState(moment());
    const [selectedEndWeek, setSelectedEndWeek] = useState(moment().add(7, 'days'));

    const [currentYear, setCurrentYear] = useState(moment().year());
    const [currentMonth, setCurrentMonth] = useState(moment().month());

    const [previousMonth, setPreviousMonth] = useState();
    const [previousYear, setPreviousYear] = useState();

    const selectedDay = days.find((day) => day.isSelected)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    
    const [data, setData] = useState([]);

    const [event, setEvent] = useState();

    //const navigate = useNavigate();

    useEffect(() => {

        get(`/api/leave/calendar/${selectedDate.year()}/${selectedDate.month()}`, {}).then((response) => {

            console.log(response)
            setData(response);

            //buildCalendar(response);

          }).catch((r) => {

          });

    
    }, [selectedDate]);

    useEffect(() => {
        var years = [];
        for(var i = 2010; i <= moment().year(); i++) {
            years.push(i);
        }
        setYears(years);
    }, []);



    useEffect(() => {

        buildCalendar(data);

    }, [data, currentMonth])

    const buildCalendar = (data) => {

        // get start of current month

        const firstOfMonth = moment(selectedDate).startOf('month').day();
        const lastOfMonth = moment(selectedDate).endOf('month').day();
        const firstDayOfGrid = moment(selectedDate).startOf('month').subtract(firstOfMonth, 'days');
        const lastDayOfGrid = moment(selectedDate).endOf('month').subtract(lastOfMonth, 'days').add(6, 'days');

        // console.log(firstOfMonth)
        // console.log(lastOfMonth)
        // console.log(firstDayOfGrid)
        // console.log(lastDayOfGrid)

        var dateIndex = firstDayOfGrid;
        var cDates = [];
        while (dateIndex <= lastDayOfGrid) {

            var events = data.filter((d) => {
                //console.log(d.dateStart)
                //console.log(dateIndex.format("MM/DD/YYYY"))
                if (d.dateStart == dateIndex.format("MM/DD/YYYY")) {
                    return d;
                }
            });

            //console.log(events)

            cDates.push(
                {
                    date: dateIndex.format('YYYY-MM-DD'),
                    isCurrentMonth: isSelectedMonth(dateIndex),
                    events: events
                });

            dateIndex = dateIndex.add(1, 'days');
            // { date: '2022-01-02', isCurrentMonth: true, events: [] },
            // {
            //     date: '2022-01-03',
            //     isCurrentMonth: true,
            //     events: [
            //         { id: 1, name: 'Design review', time: '10AM', datetime: '2022-01-03T10:00', href: '#' },
            //         { id: 2, name: 'Sales meeting', time: '2PM', datetime: '2022-01-03T14:00', href: '#' },
            //     ],
            // }
        }

        setDays(cDates)
    }

    useEffect(() => {
        buildCalendar(data);
    }, [currentMonth])

    const isSelectedMonth = (date) => {
        const today = moment();
        return moment(date).isSame(today, 'month');// && moment(date).isSameOrBefore(today);
    }

    const updateCalendarMonth = (date) => {
        console.log(selectedDate)
     
        setSelectedDate(moment(Object.assign({}, selectedDate.month(date.target.value))));

        setCurrentMonth(date.target.value);
        console.log(selectedDate)

    }
    const updateCalendarYear = (date) => {
        console.log(date)

        setSelectedDate(moment(Object.assign({}, selectedDate.year(date.target.value))));


        setCurrentYear(date.target.value);
        //buildCalendar();
    }

    const loadEvent = (e) => {

        get(`/api/leave/request/${e.leaveRequestId}`, {}).then((response) => {
            console.log(response)
            setEvent(response)
            setOpen(true) 

          }).catch((r) => {

          });
        
    }

    const pageMonthPrev = () => {
        if(currentMonth == 0) {
            setCurrentMonth(11);
            var prevYear = currentYear - 1;
            setCurrentYear(prevYear);
            setSelectedDate(moment(selectedDate).add(11, 'month').add(1, 'year'))
        }
        else{
            var prevMonth = (currentMonth - 1);
            setCurrentMonth(prevMonth)
            setSelectedDate(moment(selectedDate).add(-1, 'month'))
        }

        
    }

    const pageMonthNext = () => {

        if(currentMonth == 11) {
            setCurrentMonth(0);
            var nextYear = currentYear + 1;
            setCurrentYear(nextYear);
            setSelectedDate(moment(selectedDate).add(-11, 'month').add(-1, 'year'))
        }
        else{
            var nextMonth = (currentMonth + 1);
            setCurrentMonth(nextMonth)
            setSelectedDate(moment(selectedDate).add(1, 'month'))
        }
    }

    return (
        <div className="min-h-full">

            <main>
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    {/* <Breadcrumbs /> */}

                    <Tabs tabs={[
                        { name: 'Event Calendar', href: '/calendars/event', current: false },
                        { name: 'Leave Calendar', href: '/calendars/leave', current: true },
                        // { name: 'Scheduler Calendar', href: '/calendars/scheduler', current: false }
                    ]} />


                    <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
                        <DialogBackdrop
                            transition
                            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        />

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full min-w-full  items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <DialogPanel
                                    transition
                                    className="relative transform overflow-hidden rounded-lg 
                                    bg-gray-200 px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 
                                    data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                                >
                                    <div>
                                        {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                </div> */}
                                        <div >
                                            <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Leave Details
                                            </DialogTitle>
                                            <div className="mt-2">
                                                {/* <p className="text-sm text-gray-500">
                                    
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                                    </p> */}
                                                {event && (
                                                <form name="form1" method="post" onSubmit={handleSubmit(onSubmit)}>

                                                    <table className='w-full text-sm'>
                                                        <thead>
                                                            <th>Requested</th>
                                                            <th>Processed</th>
                                                            <th>Leave Type</th>
                                                            <th>Dates of Leave</th>
                                                            <th>Hours</th>
                                                            <th>Status</th>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td className='align-top p-1'>
                                                                {event.dateOfRequest}</td>
                                                            <td className='align-top p-1'>
                                                                {event.dateProcessed} by {event.processedBy}
                                                                </td>
                                                                <td className='align-top p-1'>
                                                                {event.leaveType}</td>
                                                            <td>
                                                                {event.dates.map((d) => {
                                                                    return (
                                                                        <div ><Moment format='MM/DD/YYYY'>{d.date}</Moment> {d.startTime} - {d.endTime}</div>
                                                                    )
                                                                })}
                                                            </td>
                                                            <td className='align-top p-1'>
                                                                {convertMinutesToTime(event.totalMinutes)}

                                                            </td>
                                                            <td className='align-top p-1'>
                                                            {formatStatus(event.status)}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                    {/* errors will return when field validation fails  */}
                                                    {errors.exampleRequired && <span>This field is required</span>}

                                                  
                                                </form>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            onClick={() => setOpen(false)}
                                            className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </DialogPanel>
                            </div>
                        </div>
                    </Dialog>



                    <div className="lg:flex lg:h-full lg:flex-col">

                        <div className='flex flex-row flex-grow-1 justify-center'>
                            
                    <div className='flex p-4'>
                        <a
                            onClick={pageMonthPrev}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon aria-hidden="true" className="h-5 w-5"  />
                        </a>
                        </div>
                            <header className="flex-grow px-6 py-4 text-center">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">
                                <time dateTime="2022-01">{selectedDate.format("MMMM")} {currentYear}</time>
                            </h1>
                       
                        </header>
                        <div className='flex  p-4'>
                            <a
                                onClick={pageMonthNext}
                                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon aria-hidden="true" className="h-5 w-5"  />
                            </a>
                        </div>
                        </div>
                      
                        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                                <div className="bg-white py-2">
                                    M<span className="sr-only sm:not-sr-only">on</span>
                                </div>
                                <div className="bg-white py-2">
                                    T<span className="sr-only sm:not-sr-only">ue</span>
                                </div>
                                <div className="bg-white py-2">
                                    W<span className="sr-only sm:not-sr-only">ed</span>
                                </div>
                                <div className="bg-white py-2">
                                    T<span className="sr-only sm:not-sr-only">hu</span>
                                </div>
                                <div className="bg-white py-2">
                                    F<span className="sr-only sm:not-sr-only">ri</span>
                                </div>
                                <div className="bg-white py-2">
                                    S<span className="sr-only sm:not-sr-only">at</span>
                                </div>
                                <div className="bg-white py-2">
                                    S<span className="sr-only sm:not-sr-only">un</span>
                                </div>
                            </div>
                            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                                <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                                    {days.map((day) => (
                                        <div
                                            key={day.date}
                                            className={classNames(
                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                                'relative px-3 py-2',
                                            )}
                                        >
                                            <time
                                                dateTime={day.date}
                                                className={
                                                    day.isToday
                                                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                                                        : undefined
                                                }
                                            >
                                                {day.date.split('-').pop().replace(/^0/, '')}
                                            </time>
                                            {day.events.length > 0 && (
                                                <ol className="mt-2">
                                                    {/* {day.events.slice(0, 2).map((event) => ( */}
                                                    {day.events.map((event) => (
                                                        <li key={event.leaveRequestId}>
                                                            <a className="group flex cursor-pointer" onClick={() => loadEvent(event)}>
                                                                {/* href={event.href} */}
                                                                <p className="flex-auto truncate font-medium text-blue-600 group-hover:text-blue-900 ">
                                                                   [{event.leaveType}] {event.employeeName}
                                                                </p>
                                                                {/* <time
                                                                    dateTime={event.datetime}
                                                                    className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                                                >
                                                                    {event.time}
                                                                </time> */}
                                                            </a>
                                                        </li>
                                                    ))}
                                                    {/* {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>} */}
                                                </ol>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                                    {days.map((day) => (
                                        <button
                                            key={day.date}
                                            type="button"
                                            className={classNames(
                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                                (day.isSelected || day.isToday) && 'font-semibold',
                                                day.isSelected && 'text-white',
                                                !day.isSelected && day.isToday && 'text-indigo-600',
                                                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                                'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10',
                                            )}
                                        >
                                            <time
                                                dateTime={day.date}
                                                className={classNames(
                                                    day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                                    day.isSelected && day.isToday && 'bg-indigo-600',
                                                    day.isSelected && !day.isToday && 'bg-gray-900',
                                                    'ml-auto',
                                                )}
                                            >
                                                {day.date.split('-').pop().replace(/^0/, '')}
                                            </time>
                                            <span className="sr-only">{day.events.length} events</span>
                                            {day.events.length > 0 && (
                                                <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                    {day.events.map((event) => (
                                                        <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                                                    ))}
                                                </span>
                                            )}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {selectedDay?.events.length > 0 && (
                            <div className="px-4 py-10 sm:px-6 lg:hidden">
                                <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                                    {selectedDay.events.map((event) => (
                                        <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                                            <div className="flex-auto">
                                                <p className="font-semibold text-gray-900">{event.name}</p>
                                                <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                                                    <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    {event.time}
                                                </time>
                                            </div>
                                            <a
                                                href={event.href}
                                                className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                                            >
                                                Edit<span className="sr-only">, {event.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        )}

<div className="flex">
                                <div>
                                    <span>Jump to: </span>
                                    <select
                                        id="location"
                                        name="location"
                                        onChange={updateCalendarMonth}
                                        defaultValue={currentMonth}
                                        value={currentMonth}
                                        className="mt-2 inline rounded-md border-0 mr-4 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value="0">January</option>
                                        <option value="1">February</option>
                                        <option value="2">March</option>
                                        <option value="3">April</option>
                                        <option value="4">May</option>
                                        <option value="5">June</option>
                                        <option value="6">July</option>
                                        <option value="7">August</option>
                                        <option value="8">Septmember</option>
                                        <option value="9">October</option>
                                        <option value="10">November</option>
                                        <option value="11">December</option>
                                    </select>
                                    <select
                                        id="location"
                                        name="location"
                                        onChange={updateCalendarYear}
                                        defaultValue={currentYear}
                                        value={currentYear}
                                        className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        {years.map((t) => (
                                              <option value={t}>{t}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                    </div>
                </div>
            </main>
        </div>
    )
}

