
// <!--
//   This example requires updating your template:

//   ```
//   <html className="h-full bg-gray-100">
//   <body className="h-full">
//   ```
// -->
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

import { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import Moment from 'react-moment';
import moment from 'moment';
import useAxios from '../hooks/useAxios'

import { useForm, SubmitHandler } from "react-hook-form"

import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'

import Breadcrumbs from '../components/breadcrumbs';
import Tabs from '../components/tabs';


import { get, post } from '../services/base-http';
export default function Calendar() {

    const [open, setOpen] = useState(false)

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();


    const onSubmit = (data) => {

        console.log(data)

    };


    const [currentDate, setCurrentDate] = useState(moment().format('DD/MM/YYYY'));
    const [selectedDate, setSelectedDate] = useState(moment());
    const [weeks, setWeeks] = useState([]);

    const [days, setDays] = useState([]);

    const [calendar, setCalendar] = useState([]);

    const [selectedStartWeek, setSelectedStartWeek] = useState(moment());
    const [selectedEndWeek, setSelectedEndWeek] = useState(moment().add(7, 'days'));

    const [currentYear, setCurrentYear] = useState(moment().year());
    const [currentMonth, setCurrentMonth] = useState(moment().month());

    const [previousMonth, setPreviousMonth] = useState();
    const [previousYear, setPreviousYear] = useState();

    const selectedDay = days.find((day) => day.isSelected)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // const { response, loading, error } = useAxios({
    //     method: 'get',
    //     url: `/api/leave/calendar/${currentYear}/${currentMonth}`,
    //     headers: JSON.stringify({ accept: 'application/json' })
    // });

    //const [data, setData] = useState([]);

    //const navigate = useNavigate();

    // useEffect(() => {

    //     console.log(currentYear)
    //     console.log(currentMonth)

    //     get(`/api/event/list/${selectedDate.year()}/${selectedDate.month()}`, {}).then((data) => {
    //         setData(data);
    //     });


    // }, [selectedDate]);


    useEffect(() => {

        get(`/api/event/list/${selectedDate.year()}/${selectedDate.month()}`, {}).then((data) => {
            //setData(data);

            buildCalendar(data);

        });

      
    }, [selectedDate])

    const buildCalendar = (data) => {

        // get start of current month
        const firstOfMonth = moment(selectedDate).startOf('month').day();
        const lastOfMonth = moment(selectedDate).endOf('month').day();
        const firstDayOfGrid = moment(selectedDate).startOf('month').subtract(firstOfMonth, 'days');
        const lastDayOfGrid = moment(selectedDate).endOf('month').subtract(lastOfMonth, 'days').add(6, 'days');


        // console.log(firstOfMonth)
        // console.log(lastOfMonth)
        // console.log(firstDayOfGrid)
        // console.log(lastDayOfGrid)

        var dateIndex = firstDayOfGrid;
        var cDates = [];
        while (dateIndex <= lastDayOfGrid) {

            var events = data.filter((d) => {
                //console.log(d.dateStart)
                //console.log(dateIndex.format("MM/DD/YYYY"))
                if (moment(d.event_date).format("MM/DD/YYYY") == dateIndex.format("MM/DD/YYYY")) {
                    return d;
                }
            });

            console.log(events)

            cDates.push(
                {
                    date: dateIndex.format('YYYY-MM-DD'),
                    isCurrentMonth: isSelectedMonth(dateIndex),
                    isSelected: false,
                    events: events
                });

            dateIndex = dateIndex.add(1, 'days');
            // { date: '2022-01-02', isCurrentMonth: true, events: [] },
            // {
            //     date: '2022-01-03',
            //     isCurrentMonth: true,
            //     events: [
            //         { id: 1, name: 'Design review', time: '10AM', datetime: '2022-01-03T10:00', href: '#' },
            //         { id: 2, name: 'Sales meeting', time: '2PM', datetime: '2022-01-03T14:00', href: '#' },
            //     ],
            // }
        }

        setDays(cDates)
    }

    const isSelectedMonth = (date) => {
        const today = moment();
        return moment(date).isSame(today, 'month');// && moment(date).isSameOrBefore(today);
    }


    const updateCalendarMonth = (date) => {
        console.log(selectedDate)
     
        setSelectedDate(moment(Object.assign({}, selectedDate.month(date.target.value))));

        setCurrentMonth(date.target.value);
        console.log(selectedDate)

    }
    const updateCalendarYear = (date) => {
        console.log(date)

        setSelectedDate(moment(Object.assign({}, selectedDate.year(date.target.value))));


        setCurrentYear(date.target.value);
        //buildCalendar();
    }


    const pageMonthPrev = () => {
        if(currentMonth == 0) {
            setCurrentMonth(11);
            var prevYear = currentYear - 1;
            setCurrentYear(prevYear);
            setSelectedDate(moment(selectedDate).add(11, 'month').add(1, 'year'))
        }
        else{
            var prevMonth = (currentMonth - 1);
            setCurrentMonth(prevMonth)
            setSelectedDate(moment(selectedDate).add(-1, 'month'))
        }

        
    }

    const pageMonthNext = () => {

        if(currentMonth == 11) {
            setCurrentMonth(0);
            var nextYear = currentYear + 1;
            setCurrentYear(nextYear);
            setSelectedDate(moment(selectedDate).add(-11, 'month').add(-1, 'year'))
        }
        else{
            var nextMonth = (currentMonth + 1);
            setCurrentMonth(nextMonth)
            setSelectedDate(moment(selectedDate).add(1, 'month'))
        }
    }
    return (
        <div className="min-h-full">

            <main>
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">

                    <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
                        <DialogBackdrop
                            transition
                            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        />

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <DialogPanel
                                    transition
                                    className="relative transform overflow-hidden rounded-lg bg-gray-200 px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                                >
                                    <div>
                                        {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                </div> */}
                                        <div >
                                            <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Event Details
                                            </DialogTitle>
                                            <div className="mt-2">
                                                {/* <p className="text-sm text-gray-500">
                                    
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                                    </p> */}

                                                <form name="form1" method="post" onSubmit={handleSubmit(onSubmit)}>
                                                    <input type="hidden" name="sub" value="save" />
                                                    <input type="hidden" name="id_calendar" value="0" />

                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="5" class="border_inset" >
                                                        <tr>
                                                            <td><label>Event Name</label><br />
                                                                <input class="textbox" type="text" name="event_name" size="29" maxLength="100"  {...register("event_name")} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label>Start Date</label> <select name="from_mo"  {...register("from_mo")} size="1" onchange="repopulateDaysOfMonth();">
                                                                <option value="0">Jan</option>
                                                                <option value="1">Feb</option>
                                                                <option value="2">Mar</option>
                                                                <option value="3">Apr</option>
                                                                <option value="4">May</option>
                                                                <option value="5">Jun</option>
                                                                <option value="6">Jul</option>
                                                                <option value="7">Aug</option>
                                                                <option value="8">Sep</option>
                                                                <option value="9">Oct</option>
                                                                <option value="10">Nov</option>
                                                                <option value="11">Dec</option>
                                                            </select> <select name="from_day" {...register("from_day")} size="1"></select>,&nbsp;<select name="from_yr" {...register("from_yr")} size="1" onchange="repopulateDaysOfMonth();">
                                                                    {([2022, 2023, 2024, 2025]).map((d) => {
                                                                        return (<option value={d}>{d}</option>)
                                                                    })}
                                                                </select></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label>Number of Days</label> <select name="num_days"  {...register("num_days")} size="1">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                                <option value="13">13</option>
                                                                <option value="14">14</option>
                                                            </select></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label><input type="checkbox" name="is_holiday"  {...register("is_holiday")} value="1" />&nbsp;&nbsp;Is this event a holiday?</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label><input type="checkbox" name="repeats_yearly" {...register("repeats_yearly")} value="1" />&nbsp;&nbsp;Does this event repeat yearly?</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="border_bot"><label>Event Description <span class="notes">(max 500 char)</span></label><br />
                                                                <textarea name="event_description" {...register("event_description")} rows="3" cols="40" ></textarea></td>
                                                        </tr>
                                                        {/* <tr>
<td align="center">
    <input class="button" type="button" name="btnReset" value="Reset" /> 
    <input class="button_submit" type="submit" name="btnSubmit" value="Save Event" /></td>
</tr> */}
                                                    </table>

                                                    {/* errors will return when field validation fails  */}
                                                    {errors.exampleRequired && <span>This field is required</span>}

                                                    <button
                                                        type="submit"
                                                        onClick={() => setOpen(false)}
                                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Save
                                                    </button>
                                                </form>





                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            onClick={() => setOpen(false)}
                                            className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </DialogPanel>
                            </div>
                        </div>
                    </Dialog>



                    <div className="lg:flex lg:h-full lg:flex-col">
                      
                    <div className='flex flex-row flex-grow-1 justify-center'>
                    <div className='flex p-4'>
                        <a
                            onClick={pageMonthPrev}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon aria-hidden="true" className="h-5 w-5"  />
                        </a>
                        </div>
                            <header className="flex-grow px-6 py-4 text-center">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">
                                <time dateTime="2022-01">{selectedDate.format("MMMM")} {currentYear}</time>
                            </h1>
                       
                        </header>
                        <div className='flex  p-4'>
                            <a
                                onClick={pageMonthNext}
                                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon aria-hidden="true" className="h-5 w-5"  />
                            </a>
                        </div>
                        </div>
                      

                        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                                <div className="bg-white py-2">
                                    M<span className="sr-only sm:not-sr-only">on</span>
                                </div>
                                <div className="bg-white py-2">
                                    T<span className="sr-only sm:not-sr-only">ue</span>
                                </div>
                                <div className="bg-white py-2">
                                    W<span className="sr-only sm:not-sr-only">ed</span>
                                </div>
                                <div className="bg-white py-2">
                                    T<span className="sr-only sm:not-sr-only">hu</span>
                                </div>
                                <div className="bg-white py-2">
                                    F<span className="sr-only sm:not-sr-only">ri</span>
                                </div>
                                <div className="bg-white py-2">
                                    S<span className="sr-only sm:not-sr-only">at</span>
                                </div>
                                <div className="bg-white py-2">
                                    S<span className="sr-only sm:not-sr-only">un</span>
                                </div>
                            </div>
                            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                                <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                                    {days.map((day) => (
                                        <div
                                            key={day.date}
                                            className={classNames(
                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                                'relative px-3 py-2',
                                            )}
                                        >
                                            <time
                                                dateTime={day.date}
                                                className={
                                                    day.isToday
                                                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                                                        : undefined
                                                }
                                            >
                                                {day.date.split('-').pop().replace(/^0/, '')}
                                            </time>
                                            {day.events.length > 0 && (
                                                <ol className="mt-2">
                                                    {/* {day.events.slice(0, 2).map((event) => ( */}
                                                    {day.events.map((event) => (
                                                        <li key={event.id}>
                                                            <a className="group flex">
                                                                {/* href={event.href} */}
                                                                <p className="bg-yellow-200 flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                                                    {event.event_name} 
                                                                </p>
                                                                {/* <time
                                                                    dateTime={event.datetime}
                                                                    className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                                                >
                                                                    {event.time}
                                                                </time> */}
                                                            </a>
                                                        </li>
                                                    ))}
                                                    {/* {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>} */}
                                                </ol>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                                    {days.map((day) => (
                                        <button
                                            key={day.date}
                                            type="button"
                                            className={classNames(
                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                                (day.isSelected || day.isToday) && 'font-semibold',
                                                day.isSelected && 'text-white',
                                                !day.isSelected && day.isToday && 'text-indigo-600',
                                                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                                'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10',
                                            )}
                                        >
                                            <time
                                                dateTime={day.date}
                                                className={classNames(
                                                    day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                                    day.isSelected && day.isToday && 'bg-indigo-600',
                                                    day.isSelected && !day.isToday && 'bg-gray-900',
                                                    'ml-auto',
                                                )}
                                            >
                                                {day.date.split('-').pop().replace(/^0/, '')}
                                            </time>
                                            <span className="sr-only">{day.events.length} events</span>
                                            {day.events.length > 0 && (
                                                <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                    {day.events.map((event) => (
                                                        <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                                                    ))}
                                                </span>
                                            )}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {selectedDay?.events.length > 0 && (
                            <div className="px-4 py-10 sm:px-6 lg:hidden">
                                <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                                    {selectedDay.events.map((event) => (
                                        <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                                            <div className="flex-auto">
                                                <p className="font-semibold text-gray-900">{event.name}</p>
                                                <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                                                    <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    {event.time}
                                                </time>
                                            </div>
                                            <a
                                                href={event.href}
                                                className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                                            >
                                                Edit<span className="sr-only">, {event.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        )}
                    </div>

                    <p className="m-2"><span className="bg-yellow-200" >Highlighted</span> events are Holidays.</p>

                </div>
            </main>
        </div>
    )
}

