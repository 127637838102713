import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Requests from './pages/requests';
import Calendar from './pages/calendar';
import Accounts from './pages/accounts';
import Reports from './pages/reports';
import ViewLeaveRequest from './app_leave/view';
import Employees from './app_accounts/employees';
import Login from './app_auth/signin';
import Register from './app_auth/signup';
import Config from './pages/config';
import My from './pages/my';
// import Products from './pages/products';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import Leave from './pages/leave';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ToastContainer />
       <BrowserRouter > 
       {/* basename='KiamichiNext' */}
       {/* <HashRouter >  */}
       <ScrollToTop />
       <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
            <Route path="/calendars" element={<Calendar />}></Route>
            <Route path="/calendars/*" element={<Calendar />}></Route>
            <Route path="/leave/*" element={<Leave />}></Route>
            <Route path="/accounts/*" element={<Accounts />} />
            {/* <Route path="/employee/:id/history/leave" element={<EmployeeLeaveHistory />}></Route> */}
            <Route path="/reports/*" element={<Reports />}></Route>
            <Route path="/config/*" element={<Config />}></Route>
            
            <Route path="/my/*" element={<My />}></Route>
            {/* <Route path="/contact" element={<Contact />}></Route>
            <Route path="/services" element={<Solutions />}></Route>
            <Route path="/products" element={<Products />}></Route> */}
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
