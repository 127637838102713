
// <!--
//   This example requires updating your template:

//   ```
//   <html className="h-full bg-gray-100">
//   <body className="h-full">
//   ```
// -->
import Nav from '../components/nav'
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { useForm, SubmitHandler, useFormState } from "react-hook-form"
import Moment from 'react-moment';
import { get, post } from '../services/base-http';


export default function Admin() {

    const { id } = useParams();

    const [data, setData] = useState();
    const [supervisors, setSupervisors] = useState([]);
    const [contractTypes, setContractTypes] = useState([]);
    const [campuses, setCampuses] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        get("/api/leave/contract-types", {}).then((data) => {
            setContractTypes(data)

            get("/api/admin/list", {}).then((data) => {
                setSupervisors(data)
    
                get("/api/admin/campuses", {}).then((data) => {
                    setCampuses(data)
        
                    //if(id) {
                        get(`/api/admin/${id || 0}`, {}).then((data) => {
                            setData(data)
                        })
                    //}
                    
                })
        
            })
    
        })
    }, []);


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        f
    } = useForm();

    
    const onSubmit = (data) => {

        console.log(data);

        post("/api/admin/save", {
            body: data
        }).then((data) => {
            
            navigate(`/accounts/admins`)

        }).catch(e => {
            alert('Failed')
        })
    }

    const cancel = () => {
        navigate(`/accounts/admin`)
    }
    const reset = () => {
        window.location.reload();
    }
    const deleteFunc = () => {
        window.location.reload();
    }



    return (
        <div className="min-h-full">

            <main>
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">

                    <h2 className="h4">Create Administrator</h2>
                    <div className="ring-1 ring-gray-300 mx-0 sm:rounded-lg">
                        {data && (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="">

                                <input {...register("id")} defaultValue={data.id} type="hidden" name="id" />

                                    <div className="border-b border-gray-900/10 p-2">
                                        {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2> */}
                                        <input type="hidden" name="id_admin" value="106" />
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="4"  bgcolor="#ebebeb">
                                            <tbody>
                                                <tr align="center">
                                                    <th colspan="2">Contact Information</th>
                                                    <th colspan="2">Account Information</th>
                                                </tr>
                                                <tr align="left">
                                                    <td><label id="l1">*First Name</label></td>
                                                    <td><input  defaultValue={data.admin_fname} {...register("admin_fname")} type="text" name="admin_fname" size="24" maxLength="30" tabIndex="1" /></td>
                                                    <td><label>Title</label></td>
                                                    <td><input  defaultValue={data.admin_title} {...register("admin_title")} type="text" name="admin_title" size="24" maxLength="50" tabIndex="8" /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td><label id="l2">*Last Name</label></td>
                                                    <td><input  defaultValue={data.admin_lname} {...register("admin_lname")} type="text" name="admin_lname" size="24" maxLength="35" tabIndex="2" /></td>
                                                    <td><label>Privileges</label></td>
                                                    <td><select {...register("admin_level")} defaultValue={data.admin_level} name="admin_level" size="1" tabIndex="9">
                                                        <option value="3" selected="">Site Administrator</option>
                                                        <option value="2">Director</option>
                                                        <option value="1">Supervisor</option>
                                                        <option value="0">Coordinator**</option>
                                                    </select></td>
                                                </tr>
                                                <tr align="left">
                                                    <td><label id="l4">*E-mail Address</label></td>
                                                    <td><input  type="text" {...register("admin_email")} defaultValue={data.admin_email} name="admin_email" size="24" maxLength="80" tabIndex="3" /></td>
                                                    <td><label>*Login ID</label></td>
                                                    <td><input  type="text" {...register("admin_login")} defaultValue={data.admin_login} name="admin_login" size="24" maxLength="20" tabIndex="10" /></td>
                                                </tr>
                                                <tr align="left">
                                                    <td><label>*Phone 1, Ext</label></td>
                                                    <td><input  type="text" {...register("admin_phone1")} defaultValue={data.admin_phone1}  name="admin_phone1" size="15" maxLength="20" tabIndex="4" />
                                                        <input  type="text" name="admin_phone1_ext" defaultValue={data.admin_phone1_ext} {...register("admin_phone1_ext")} size="6" maxLength="6" value="" tabIndex="5" /></td>
                                                    <td><label>Password</label></td>
                                                    <td>
                                                        <input  type="password" {...register("admin_pw")} name="admin_pw" size="12" maxLength="20" tabIndex="11" /> 
                                                        
                                                        <label>(repeat)</label>
                                                        <input  type="password" {...register("admin_pw2")} name="admin_pw2" size="12" maxLength="20" tabIndex="12" />
                                                    </td>
                                                </tr>
                                                <tr align="left">
                                                    <td ><label>Phone 2, Ext</label></td>
                                                    <td ><input  type="text" {...register("admin_phone2")} defaultValue={data.admin_phone2} name="admin_phone2" size="15" maxLength="20" value="" tabIndex="6" />
                                                        <input  type="text" name="admin_phone2_ext" {...register("admin_phone2_ext")} defaultValue={data.admin_phone2_ext}  size="6" maxLength="6" value="" tabIndex="7" /></td>
                                                    <td ><label>Status</label></td>
                                                    <td ><select name="admin_status" {...register("admin_status", { setValueAs: forceTypeBoolean, })} defaultValue={data.admin_status}  size="1" tabIndex="13">
                                                        <option selected={data.admin_status} value={true} >Account Active</option>
                                                        <option selected={!data.admin_status} value={false}>Account Disabled</option>
                                                    </select></td>
                                                </tr>
                                                <tr align="left">

                                                </tr>
                                            </tbody>
                                        </table>
                                        <div align="right">
                                            <span >**Note: Coordinators currently have no access to the administrative area. These accounts are for E-mail alerts only.
                                                <br />
                                                Date Created: <Moment format="MM/DD/YYYY">{data.admin_dateCreated}</Moment>&nbsp;&nbsp;&nbsp;Date Modified: <Moment format="MM/DD/YYYY">{data.admin_dateModified}</Moment></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end gap-x-6 p-6">
                                    <button type="button"
                                        onClick={reset}
                                        className="text-sm font-semibold leading-6 text-gray-900 bg-gray-200 rounded-md px-3 py-2">

                                        Reset
                                    </button>

                                    <button type="button"
                                        onClick={cancel}
                                        className="text-sm font-semibold leading-6 text-gray-900 bg-gray-200 rounded-md px-3 py-2">
                                        Cancel
                                    </button>

                                    <button type="button"
                                        onClick={deleteFunc}
                                        className="text-sm font-semibold leading-6 text-gray-900 bg-red-200 rounded-md px-3 py-2">
                                        Delete
                                    </button>

                                    <button
                                        type="submit"
                                        className="rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white 
                                    hover:bg-blue-500 focus-visible:outline 
                                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}

export function forceTypeBoolean(value) {
    return typeof value === 'boolean' ? value : value === 'true' ? true : value === 'false' ? false : null;
 }
