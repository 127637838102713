
import Nav from '../components/nav'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Requests from '../app_leave/requests';
import View from '../app_leave/view';
import Create from '../app_leave/create';
import History from '../app_leave/history';

export default function Leave() {

    return (

        <div className="mx-auto max-w-7xl bg-white">    
               <Nav />
         {/* <Tabs tabs={[{ name: 'List Employee', href: '#', current: true }]} /> */}
        <Routes>
            <Route exact path="" element={<Requests />} />
            <Route exact path="pending" element={<Requests />} />
            <Route exact path="request/:id/view" element={<View />} />
            <Route path="/create" element={<Create />}></Route>
            <Route path="/history" element={<History />}></Route>
            </Routes>
            {/* <Route exact path="/equipment/:id/view" component={View} />
            <Route exact path="/equipment/:id/readonly" component={ReadOnly} />
            <Route exact path="/equipment/:id/images" component={Images} />
            <Route exact path="/equipment/qrscan" component={QrScan} />
            <Route exact path="/equipment/:id/qrcode" component={QrCode} /> */}
        </div>
    )
}