import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import useSave from '../hooks/useSave'

import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'

import { useForm } from "react-hook-form"

import {get, post} from '../services/base-http';

export default function LeaveTypeEdit() {

    const { id } = useParams();

    const { response, loading, error } = useAxios({
        method: 'get',
        url: `/api/leave/contract-type/${id}`,
        headers: JSON.stringify({ accept: 'application/json' }),
    });

    const [data, setData] = useState([]);

    const navigate = useNavigate();


    useEffect(() => {
        if (response !== null) {
            setData(response);
            
            
        }
    }, [response]);

    const useT = useSave();

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm()

    const onSubmit = (data) => {
        console.log(data)

        const response = post("/api/leave/contract-type", { body: data} );
        
        console.log(response);

        navigate(`/config/contract-types`)
    };



    // const accrualMethod = (params) => {
    //     var p = params.filter((pa) => {
    //         return pa.param_name === "ACCRUAL_METHOD"
    //     });
    //     return p[0].param_value;
    // }

    return (
        <div className="min-h-full">


            <main>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

                    <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg bg-gray-200">

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex'>
                                <div className="flex-grow pl-8 pt-4">
                                <label>Contract Type Name</label><br />
                                <input class="textbox" type="text" name="contract_name" size="30" maxLength="30" value={data.contract_name}  {...register("contract_name", { required: true, maxLength: 30 })} /><br />
                                <br />
                                <label>Contract Length (months)</label> 
                                <select name="contract_length_months" size="1">
                                    <option value="12">12</option>
                                    <option value="11">11</option>
                                    <option value="10">10</option>
                                    <option value="9">9</option>
                                    <option value="8">8</option>
                                    <option value="7">7</option>
                                    <option value="6">6</option>
                                    <option value="5">5</option>
                                    <option value="4">4</option>
                                    <option value="3">3</option>
                                    <option value="2">2</option>
                                    <option value="1">1</option>
                                    <option value="0">NA</option>
                                </select><br />
                                <br />
                                <br />
                                <label htmlFor="uses_time">
                                    <input type="checkbox" id="uses_time" name="uses_time"  />Uses Time Logging</label>
                                <fieldset>
                                    <label htmlFor="uses_timeinput"><input type="radio" id="uses_timeinput" name="time_selection" value="input" disabled="" />Time Input (Timesheet)</label><br />
                                    <label htmlFor="uses_timecapture"><input type="radio" id="uses_timecapture" name="time_selection" value="capture" disabled="" />Time Capture (Clock-In/Clock-Out)</label><br />
                                </fieldset>
                                <br />
                                </div>
                                <div className="flex-grow  pl-8 pt-4">

                                <label>Applicable Leave Types</label><br />
                                    <select name="contract_leave_types" size="10" multiple="">
                                        <option value="3">Personal Leave 1</option>
                                        <option value="5">Personal Leave 2</option>
                                        <option value="2">Sick Leave 12 MO</option>
                                        <option value="6">Sick Leave 9/10 MO</option>
                                        <option value="4">Sick Leave Faculty</option>
                                        <option value="1">Vacation</option>
                                    </select>

                                    <br />
                                    <br />
                                    <br />
                                    <button className="rounded bg-blue-400 px-6 py-1 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  name="btnSubmit"  >Save Contract Type</button>

                                    <input className="rounded bg-gray-400 px-6 py-1 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  value="Cancel" onClick={() => navigate("/config/contract-types")} />

                                    <br /><br />
                                    </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main >
        </div >
    );
}