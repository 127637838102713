// <!--
//   This example requires updating your template:

//   ```
//   <html className="h-full bg-gray-100">
//   <body className="h-full">
//   ```
// -->
import LeaveSummaryReport from '../app_leave/report_summary';
import Dashboard from '../app_reports/';
import { Route, Routes, useLocation } from 'react-router-dom';
import Nav from '../components/nav'

export default function Reports() {

    return (
       
        <div className="mx-auto max-w-7xl bg-white">    
               <Nav />
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/leave-summary" element={<LeaveSummaryReport />} />
                {/* <Route exact path="/leave/" element={<LeaveCalendar />} />
                <Route exact path="/event/" element={<EventCalendar />} />
                <Route exact path="/scheduler/" element={<SchedulerCalendar />} /> */}
            </Routes>
        </div>

    )
}