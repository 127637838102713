import { Link, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios'
import { useEffect, useState } from 'react'
import Tabs from '../components/tabs'
import pencilIcon from '../images/icons/page_edit.gif'
import emailIcon from '../images/icons/icon_email.gif'
import historyIcon from '../images/icons/icon_history.gif'
import clockAddIcon from '../images/icons/icon_clock_add.gif'

export default function Employees() {

    const { response, loading, error } = useAxios({
        method: 'get',
        url: '/api/admin/list',
        headers: JSON.stringify({ accept: 'application/json' }),
        // body: JSON.stringify({
        //     userId: 1,
        //     id: 19392,
        //     title: 'title',
        //     body: 'Sample text',
        // }),
    });

    const [pageParam, setPageParam] = useState(1);
    // const [sortParam, setSortParam] = useState('lastname');
    // const [nameParam, setNameParam] = useState('');
    // const [showInactive, setShowInactive] = useState(false);
    
    const [searchFilter, setSearchFilter] = useState('');
    const [data, setData] = useState([]);
    const [dataFiltered, setDataFiltered] = useState([]);
    const [pageNumber, setPageNumber] = useState(pageParam);

    const pageSize = 20;
    const [totalPages, setTotalPages] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        if (response !== null) {
            setData(response);
        }
    }, [response]);

    const viewEmployee = (id) => {
        navigate(`/accounts/admin/${id}/view`)
    }

    const loadData = (pageNumber) => {

        var tp = Math.round((data.length / pageSize));

        var tpv = (tp > 0 ? tp : 1);

        console.log(tpv);

        setTotalPages(tpv);

        var df = data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

        setDataFiltered(Object.assign([], df));
    }

    const paging = (e) => {
        setPageNumber(e.target.value)

        loadData(e.target.value);
    }


    useEffect(() => {
        loadData(pageNumber)
    }, [pageNumber, dataFiltered])


    const formatAdminLevel = (admin_level) => {
        switch(admin_level) {
            case 1:
                return "Admin"
                  case 2:
                return "Director"
                  case 3:
                return "Supervisor"
                  case 4:
                return "Coordinator"
        }
    }

    const sendEmail = () => {

    }

    const returnPages = () => {
        var dayray = [];

        for(let i = 1; i <= totalPages; i++){
            dayray.push(i)
        }
        return dayray;
    }

    
    const search = () => {
        var results = data.filter((a) => {
            if(a.full_name.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0){
                return a;
            }
        });

        setTotalPages(Math.round(data.length / pageSize))

        setDataFiltered(results);
    }

    const handleFilterChange = (e) => {
        setSearchFilter(e.target.value)
    }

    const showAll = (e) => {
        setSearchFilter('')
        setDataFiltered(data);
    }

    
    return (
        <div className="min-h-full">

           <br />
            <main>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className='flex border-b border-gray-200'>
                        <div className="flex-grow">
                            <Tabs tabs={[
                                { name: 'List Employee', href: '/accounts/employees', current: false },
                                { name: 'List Admin', href: '/accounts/admins', current: true },
                            ]} />
                        </div>
                        <div className='m-2'>
                        <input value={searchFilter} onChange={handleFilterChange}
                            className="rounded-md border-0 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                            type="text" name="emp_phone_cell" size="24" maxLength="20"  tabIndex="10" />

                            <button onClick={search}
                                type="button"
                                className="ml-2 rounded bg-blue-400 px-6 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Search
                            </button>
                            <button onClick={showAll}
                                type="button"
                                className="ml-2 rounded bg-blue-400 px-6 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Show All
                            </button>
                        </div>

                      
                        <div className='m-2'>
                        <button onClick={() => navigate("/accounts/admin/create")}
                                type="button"
                                className="rounded bg-blue-400 px-6 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                + Add 
                            </button>
                        </div>
                    </div>
                 <br />
                 <div className="-mx-4 mt-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300 striped">
                            <thead>
                                <tr>
                                    <th scope="col" className="pl-4 pr-3 text-left text-sm font-semibold  sm:pl-6">Name</th>
                                    <th scope="col" className="hidden px-3 py-1 text-left text-sm font-semibold  lg:table-cell">Title</th>
                                    <th scope="col" className="hidden px-3 py-1 text-left text-sm font-semibold  lg:table-cell">Phone</th>
                                    <th scope="col" className="hidden px-3 py-1 text-left text-sm font-semibold  lg:table-cell">Priviledges</th>
                                    <th scope="col" className="px-3 py-1 text-left text-sm font-semibold ">Status</th>
                                    {/* <th scope="col" className="relative py-1 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {dataFiltered && dataFiltered.map((e) => (
                                    <tr className='hover:bg-sky-100' onClick={() => viewEmployee(e.id)}>
                                        <td className="relative py-2 pl-4 pr-3 text-sm sm:pl-6">
                                            <Link to="#">{e.full_name}</Link>
                                        </td>
                                        <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">
                                            <img src={emailIcon} className='inline pr-2' onClick={() => sendEmail(e.admin_email)} />
                                            {e.admin_title}</td>
                                        <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{e.admin_phone1}</td>
                                        <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{formatAdminLevel(e.admin_level)}</td>
                                        <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{(e.admin_status ? "Active" : "Disabled")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <br />
                        <p className='text-xs text-gray-600 p-3'>No of records: {data.length}  |  Page  
                             <select name="status"
                               onChange={paging}
                             value={pageNumber}  className="mt-2 inline rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    {returnPages().map((d) => {
                                        return (<option value={d}>{d}</option>)
                                    })}
                              
                            </select>{pageNumber} of {totalPages}</p>
                    </div>
                </div>
            </main>
        </div>
    );
}